import React from 'react';
import { Email } from '../Elements';

import GenericTextArea from './parts/GenericTextArea';
import GenericHeader from './parts/GenericHeader';
import StepHeader from './parts/StepHeader';
import TextInput from './parts/TextInput';
import ZipcodeInput from './parts/ZipcodeInput';
import TextAreaInput from './parts/TextAreaInput';
import MultipleCheckboxesInput from './parts/MultipleCheckboxesInput';
import SimpleCheckbox from './parts/SimpleCheckbox';
import MapPolygonInput from './parts/MapPolygonInput';
import RadioButtonInput from './parts/RadioButtonInput';
import SelectDropdownInput from './parts/SelectDropdownInput';
import StateSelectInput from './parts/StateSelectInput';
import PasswordAndConfirmationInput from './parts/PasswordAndConfirmationInput';
import EmailInput from './parts/EmailInput';
import DomainInput from './parts/DomainInput';
import LatLongInput from './parts/LatLongInput';
import PhoneInput from './parts/PhoneInput';
import ImageFileUploader from './parts/ImageFileUploader';
import NumericInput, { MaxPointFourNineNumericInput } from './parts/NumericInput';
import StateRoleSelectDropdownInput from './parts/StateRoleSelectDropdownInput';
import SitesInput from './parts/SitesInput';
import Attestations from './parts/Attestations';
import VermontPaymentWidget from './parts/VermontPaymentWidget';
import VermontSidebar from './sidebars/VermontSidebar';
import SampleSidebar from './sidebars/SampleSidebar';

import SitesInputReview from './review/SitesInputReview';
import PaymentWidgetReview from './review/PaymentWidgetReview';
import AttestationsReview from './review/AttestationsReview';

export const PartRegistery = {
  text: {
    type: 'display',
    component: GenericTextArea,
    displayValue: () => false,
  },
  header: {
    type: 'display',
    component: GenericHeader,
    displayValue: () => false,
  },
  step_header: {
    type: 'display',
    component: StepHeader,
    displayValue: () => false,
  },
  default_input: {
    type: 'input',
    component: TextInput,
    displayValue: value => value,
  },
  textarea_input: {
    type: 'input',
    component: TextAreaInput,
    displayValue: value => value,
  },
  text_input: {
    type: 'input',
    component: TextInput,
    displayValue: value => value,
  },
  email_input: {
    type: 'input',
    component: EmailInput,
    displayValue: value => <Email email={value} />,
  },
  zipcode: {
    type: 'input',
    component: ZipcodeInput,
    displayValue: value => value,
  },
  select_dropdown_input: {
    type: 'input',
    component: SelectDropdownInput,
    displayValue: (value, field) => {
      const fieldOptions = field.options.filter(
        option => option.value === value,
      );
      return fieldOptions[0].label;
    },
  },
  latlong_input: {
    type: 'input',
    component: LatLongInput,
    // eslint-disable-next-line no-unused-vars
    displayValue: (value) => {},
  },
  map_polygon_input: {
    type: 'input',
    component: MapPolygonInput,
    // eslint-disable-next-line no-unused-vars
    displayValue: (value, field) => value,
  },
  state_input: {
    type: 'input',
    component: StateSelectInput,
    // eslint-disable-next-line no-unused-vars
    displayValue: (value, field) => value,
  },
  attestations: {
    type: 'input',
    component: Attestations,
    displayValue: AttestationsReview,
  },
  phone_input: {
    type: 'input',
    component: PhoneInput,
    displayValue: value => value,
  },
  domain_input: {
    type: 'input',
    component: DomainInput,
    displayValue: value => value,
  },
  radiobutton_input: {
    type: 'input',
    component: RadioButtonInput,
    displayValue: value => value,
  },
  multicheckbox_input: {
    type: 'input',
    component: MultipleCheckboxesInput,
    displayValue: () => {},
  },
  simplecheckbox_input: {
    type: 'input',
    component: SimpleCheckbox,
    displayValue: () => {},
  },
  password_input: {
    type: 'input',
    component: PasswordAndConfirmationInput,
    displayValue: (value) => {
      if (value) {
        return '•'.repeat(value.length);
      }
      return value;
    },
  },
  image_uploader: {
    type: 'input',
    component: ImageFileUploader,
    displayValue: () => {},
  },
  numeric_input: {
    type: 'input',
    component: NumericInput,
    displayValue: value => value,
  },
  max_point_four_nine_numeric_input: {
    type: 'input',
    component: MaxPointFourNineNumericInput,
    displayValue: value => value,
  },
  state_role: {
    type: 'input',
    component: StateRoleSelectDropdownInput,
    // eslint-disable-next-line no-unused-vars
    displayValue: (value, field) => value,
  },
  sites: {
    type: 'input',
    component: SitesInput,
    displayValue: SitesInputReview,
  },
  vt_payment: {
    type: 'input',
    component: VermontPaymentWidget,
    displayValue: PaymentWidgetReview,
  },
};

export function getFormPart(type) {
  return PartRegistery[type]
    ? PartRegistery[type]
    : PartRegistery.default_input;
}

export const SideBars = {
  vermont: VermontSidebar,
  sample: SampleSidebar,
};

export function getSidebar(type) {
  return SideBars[type];
}

export default PartRegistery;
