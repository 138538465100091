import React from 'react';
import styled from 'styled-components';

import InputBase from './InputBase';
import InputWrapper from './InputWrapper';

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

class RadioButtonInput extends InputBase {
  render() {
    const {
      error,
      label,
      value,
      name,
      onChange,
      inlineLabel = false,
      required,
    } = this.props;

    return (
      <InputWrapper name={name} error={error} label={label} required={required}>
        <RadioGroupContainer>
          {this.props.options.map(opt => (
            <label
              key={opt}
              className={`radio ${inlineLabel ? 'flex flex-row mr-6' : 'text-center'}`}
              htmlFor={`mf_input_${name}`}
            >
              <input
                type="radio"
                name={name}
                className="mr-3"
                defaultValue={value}
                checked={value === opt}
                onChange={() => { onChange(name, opt); }}
              />
              {opt}
            </label>
          ))}
        </RadioGroupContainer>
      </InputWrapper>
    );
  }
}

export default RadioButtonInput;
