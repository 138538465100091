import React from 'react';
import Icon from '../Icon';

import { useSnackbar } from '../../../services/stores/useSnackbar';

import { NOTIFICATION_SHOW_NOTICE } from '../../../services/NotificationService';

const SnackBar = () => {
  const [managerState, managerDispatch] = useSnackbar();
  if (managerState.notifications.length === 0) {
    managerDispatch({
      type: 'add_listeners',
      data: [
        {
          event: NOTIFICATION_SHOW_NOTICE,
          callback: (msg) => {
            managerDispatch({
              type: 'show',
              message: msg,
            });
            setTimeout(() => {
              managerDispatch({
                type: 'hide',
              });
            }, 3000);
          },
        },
      ],
    });
  }

  const wrapperClass = `${
    managerState.hidden ? 'invisible' : 'visible'
  } z-50 w-1/3 fixed bottom-0 rounded-lg bg-black mb-6 p-3 text-white flex-row items-center flex`;

  return (
    <div className={wrapperClass}>
      <Icon className="text-lg" type="font" icon="exclamation" />
      {' '}
      {managerState.message}
    </div>
  );
};

export default SnackBar;
