import React from 'react';
import PropTypes from 'prop-types';

import SimpleCheckbox from '../parts/SimpleCheckbox';
import { Title, Copy } from '../../Elements';

const PaymentWidgetReview = (value) => {
  const { agreedToTerms, paymentMethod } = value;

  return (
    <>
      <SimpleCheckbox
        key="agreed"
        name="agreedToTerms"
        value="Agreed to Payment Terms & Conditions"
        checked={agreedToTerms}
        updateValueAndError={() => {}}
        onChange={() => {}}
      />
      {(paymentMethod === 'check' || paymentMethod === 'cash') && (
        <Copy className="w-full text-center pr-3 pl-3 pt-12 pb-12  bg-white rounded-lg">
          <p className="font-bold">
            Please mail your
            {paymentMethod === 'check' ? 'Check Payment' : 'Cash Payment'}
            {' '}
            to:
          </p>
          <p>Recipient at VT Department of Agriculture</p>
          <p>Address Line 1, Office 123</p>
          <p>Address Line 2, Montpelier, VT, 05602</p>
          <p>
            Your permit can not be approved until we receive your payment.
          </p>
        </Copy>
      )}
      {(paymentMethod === 'ach' || paymentMethod === 'credit') && (
        <Title as="h1" className="text-red-600">
          {paymentMethod === 'ach' ? 'ACH Payment' : 'Credit Card Payment'}
          is not implemented yet...
        </Title>
      )}
    </>
  );
};

PaymentWidgetReview.propTypes = {
  value: PropTypes.shape({}).isRequired,
  field: PropTypes.shape({}).isRequired,
};

export default PaymentWidgetReview;
