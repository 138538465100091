import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Copy from '../../Elements/Copy';
import Title from '../../Elements/Title';
import SimpleCheckbox from './SimpleCheckbox';

import SelectDropdownInput from './SelectDropdownInput';
import { calculateVermontPrice } from '../util/vermont';


const MailInCashOrCheckInfo = ({ cost = 100, mode }) => (
  <Copy className="w-full text-center pr-3 pl-3 pt-12 pb-12  bg-white rounded-lg">
    <p className="font-bold">
      Please mail your
      {mode}
      {' '}
      to:
    </p>
    <p>Recipient at VT Department of Agriculture</p>
    <p>Address Line 1, Office 123</p>
    <p>Address Line 2, Montpelier, VT, 05602</p>
    <Title as="h3" className="text-gold-600">
      In the Amount of
      <span className="font-bold text-xl">
        $
        {cost}
      </span>
      .
    </Title>
    <p>
      Your permit can not be approved until we receive your
      {mode}
      .
    </p>
  </Copy>
);

MailInCashOrCheckInfo.propTypes = {
  cost: PropTypes.number,
  mode: PropTypes.string.isRequired,
};

MailInCashOrCheckInfo.defaultProps = {
  cost: 100,
};

class VermontPaymentWidget extends Component {
  constructor(props) {
    super(props);
    // console.log("VermontPaymentWidget props", props)
    const propValue = props.value || {};
    const { paymentMethod, agreedToTerms } = propValue;

    this.state = {
      paymentMethod: paymentMethod || 'Mail In: Cash',
      agreedToTerms: agreedToTerms || false,
      formData: props.formData,
      error: props.error,
    };
    this.receivePaymentMethod = this.receivePaymentMethod.bind(this);
    this.toggleTermAgreement = this.toggleTermAgreement.bind(this);
    this.doUpdateValueAndError = this.doUpdateValueAndError.bind(this);
  }

  componentWillUnmount() {
    this.doUpdateValueAndError();
  }

  receivePaymentMethod(k, paymentMethod) {
    this.setState({ paymentMethod }, this.doUpdateValueAndError);
  }

  toggleTermAgreement() {
    const { agreedToTerms } = this.state;
    this.setState(
      { agreedToTerms: !agreedToTerms },
      this.doUpdateValueAndError,
    );
  }

  doUpdateValueAndError() {
    const { name, updateValueAndError } = this.props;
    const { paymentMethod, agreedToTerms } = this.state;

    updateValueAndError(
      name,
      {
        paymentMethod,
        agreedToTerms,
      },
      agreedToTerms ? null : 'Must agree to terms before submitting',
    );
  }

  render() {
    const {
      formData,
      error,
      paymentMethod,
      agreedToTerms,
    } = this.state;
    const cost = calculateVermontPrice(formData);

    return (
      <React.Fragment>
        <Copy>
          <Title as="h3">Make your Payment</Title>
          <p>
            Your payment tier corresponds to your acreage or lbs of Hemp
            processed.&nbsp; If you&apos;d like to know more visit our payment
            information here.
          </p>
        </Copy>

        <SelectDropdownInput
          name="Payment_Method"
          label="What is your preferred method of payment?"
          options={[
            {
              value: 'cash',
              label: 'Mail In: Cash',
            },
            {
              value: 'check',
              label: 'Mail In: Check',
            },
            {
              value: 'credit',
              label: 'Credit Card',
            },
            {
              value: 'ach',
              label: 'ACH',
            },
          ]}
          value={paymentMethod}
          onChange={this.receivePaymentMethod}
          updateValueAndError={(name, val) => {
            this.setState({ paymentMethod: val }, this.doUpdateValueAndError);
          }}
        />

        <SimpleCheckbox
          name="Payment_Agreement"
          type="checkbox"
          value="By making this payment I accept the VT Agency of Ag terms and conditions."
          checked={agreedToTerms}
          onChange={this.toggleTermAgreement}
        />

        {error && <span className="text-red-400">{error}</span>}

        {paymentMethod === 'cash' && (
          <MailInCashOrCheckInfo cost={cost} mode="cash" />
        )}
        {paymentMethod === 'check' && (
          <MailInCashOrCheckInfo cost={cost} mode="check" />
        )}
        {paymentMethod === 'ach' && (
          <Title as="h1" className="text-red-600">
            OOPS! ACH is not implemented yet
          </Title>
        )}
        {paymentMethod === 'credit' && (
          <Title as="h1" className="text-red-600">
            OOPS! Credit Card is not implemented yet
          </Title>
        )}
      </React.Fragment>
    );
  }
}
VermontPaymentWidget.propTypes = {
  value: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  updateValueAndError: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
  error: PropTypes.string,
};

VermontPaymentWidget.defaultProps = {
  formData: {},
  value: {},
  error: null,
};

export default VermontPaymentWidget;
