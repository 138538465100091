import colors from '../../../configs/tailwind/colors';

export const colorClasses = {
  custom: {
    solid: '',
    outline: '',
  },
  black: {
    solid: {
      button: 'bg-black hover:bg-gray-800 disabled:bg-gray-500 text-white',
      icon: '',
    },
    outline: {
      button: 'hover:border-gray-700 hover:text-gray-700 border-traceblack text-traceblack',
      icon: '',
    },
  },
  dgray: {
    solid: {
      button: 'bg-gray-700 hover:bg-gray-600 disabled:bg-gray-500 text-white disabled:text-gray-600',
      icon: '',
    },
    outline: {
      button: 'border-gray-600 hover:border-gray-500 disabled:border-gray-300 text-gray-600 hover:text-gray-500 disabled:text-gray-200',
      icon: '',
    },
  },
  gray: {
    solid: {
      button: 'bg-gray-300 hover:bg-gray-400 disabled:bg-gray-200 text-traceblack',
      icon: '',
    },
    outline: {
      button: 'border-gray-300 hover:border-gray-400 text-gray-400 hover:text-gray-500',
      icon: '',
    },
  },
  lgray: {
    solid: {
      button: 'bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 text-gray-500 hover:text-gray-600',
      icon: '',
    },
    outline: {
      button: 'border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600',
      icon: '',
    },
  },
  graygold: {
    solid: {
      button: 'bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 text-gold-800',
      icon: '',
    },
    outline: {
      button: 'border-gray-200 hover:border-gold-500 text-gold-700 hover:text-gold-600',
      icon: '',
    },
  },
  white: {
    solid: {
      button: 'bg-white border border-gray-200 hover:bg-gray-100 text-gray-500 hover:text-gray-600',
      icon: '',
    },
    outline: {
      button: 'border-white hover:border-gray-100 text-white hover:text-gray-100',
      icon: '',
    },
  },
  gold: {
    solid: {
      button: 'bg-gold-500 hover:bg-gold-400 text-white',
      icon: colors.white,
    },
    outline: {
      button: 'border-gold-500 hover:border-gold-800 text-gold-500 hover:text-gold-800',
      icon: colors.gold['500'],
    },
  },
  red: {
    solid: {
      button: 'bg-red-500 hover:bg-red-400 disabled:bg-red-300 text-white disabled:text-gray-600',
      icon: '',
    },
    outline: {
      button: 'border-red-500 hover:border-red-300 disabled:border-red-300 text-red-500 hover:text-red-700 disabled:text-gray-600',
      icon: '',
    },
  },
  purple: {
    solid: {
      button: 'bg-purple-600 hover:bg-purple-400 text-white',
      icon: '',
    },
    outline: {
      button: 'border-purple-400 hover:border-purple-500 text-purple-400 hover:text-purple-500',
      icon: '',
    },
  },
  peppercorn: {
    solid: {
      button: 'bg-peppercorn hover:bg-gray-700 text-white',
      icon: '',
    },
    outline: {
      button: 'border-peppercorn hover:border-gray-700 text-peppercorn hover:text-gray-700',
      icon: '',
    },
  },
  green: {
    solid: {
      button: 'bg-green-500 hover:bg-green-400 disabled:bg-gray-200 text-white',
      icon: colors.white,
    },
    outline: {
      button: 'border-green-400 text-green-400 hover:border-green-200 hover:text-green-200',
      icon: colors.green['400'],
    },
  },
  transparent: {
    solid: {
      button: 'bg-transparent text-traceblack hover:text-gold-500',
      icon: '',
    },
    outline: {
      button: 'border-transparent hover:border-gold-500 text-peppercorn hover:text-gold-500',
      icon: '',
    },
  },
};

export function getButtonColorConfig(color, variant = 'solid') {
  if (!color || !(color in colorClasses)) {
    return '';
  }

  const returnConfig = colorClasses[color][variant] ? colorClasses[color][variant]
    : colorClasses.gold.solid;

  if (variant === 'outline') {
    returnConfig.button += ' border focus:border focus:border-gold-500';
  }

  return returnConfig;
}

export const sizeClasses = {
  icon: 'justify-center items-center w-8 h-8 text-xs',
  xs: 'py-1 px-2 text-xs',
  sm: 'py-1 px-2 text-sm',
  base: 'px-3 py-2',
  md: 'px-5 py-3',
  lg: 'px-6 py-4',
  xl: 'px-12 py-6 uppercase',
};
