import React from 'react';
import PropTypes from 'prop-types';

import Copy from '../../Elements/Copy';

const GenericTextArea = ({ value }) => (
  <Copy>
    {value}
  </Copy>
);

GenericTextArea.propTypes = {
  value: PropTypes.string,
};

GenericTextArea.defaultProps = {
  value: '',
};

export default GenericTextArea;
