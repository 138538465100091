import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Step from './step/Step';
import ReviewStep from './step/ReviewStep';
import { getSidebar } from './PartRegistery';

import { validateMultiform } from './util/validators';
import {
  extractFieldsFromConfig,
  combineFormDataStructures,
  convertExtractedFormatToSchema,
} from './util/field-helpers';

import { typeOf } from '../../utils/helpers';
import DebugPanel from '../Debug/DebugPanel';

class MultiForm extends Component {
  constructor(props) {
    super(props);

    const {
      steps,
      formData = {},
      enableDebug,
      activeStep,
      review,
      saveOnStepChange,
    } = this.props;

    const extractedFormDataStructure = extractFieldsFromConfig({ steps });

    if (enableDebug) {
      console.log('MF: Extracted Fields: ', extractedFormDataStructure);
      console.log('MF: Combined fields: ', combineFormDataStructures(formData, extractedFormDataStructure));
    }

    // TODO: Remove `combineFormDataStructures` when API endpoint has been updated.
    this.state = {
      activeStep: activeStep || 0,
      review: review || false,
      formData: combineFormDataStructures(formData, extractedFormDataStructure),
      formErrors: validateMultiform(formData, { steps }),
      submitted: false,
      close: false,
      saveOnStepChange: (saveOnStepChange || false),
    };

    if (enableDebug) {
      console.log('MF: Constructor call', this);
    }

    this.changeStep = this.changeStep.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateValueAndError = this.updateValueAndError.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
  }

  submitForm() {
    const { enableDebug, onSubmit } = this.props;
    const { formData } = this.state;

    if (enableDebug) {
      console.log('MF: schematized for GRAPHQL prototype: ', convertExtractedFormatToSchema(formData));
    }

    onSubmit(formData);
  }

  updateForm() {
    const { onUpdate } = this.props;
    const { formData } = this.state;

    if (onUpdate) {
      onUpdate(formData);
    }
  }

  saveAndClose() {
    this.setState({ close: true }, this.updateForm());
  }

  updateValueAndError(name, value, errorMsg) {
    const { formData, formErrors } = this.state;

    if (typeOf(value) !== 'null') {
      formData[name].value = value;
    }

    if (errorMsg) {
      formErrors[name] = errorMsg;
    } else {
      delete (formErrors[name]);
    }

    this.setState({ formData, formErrors });
  }

  changeStep(nextStep) {
    const {
      saveOnStepChange,
      review,
    } = this.state;

    const { enableDebug } = this.props;

    if (enableDebug) {
      console.log('MF: Step change', this);
    }

    if (saveOnStepChange) {
      this.updateForm();
    }

    if (nextStep === -1) {
      this.setState({ review: !review });
    } else {
      this.setState({ activeStep: nextStep });
    }
  }

  handleInputChange(name, value) {
    const { enableDebug } = this.props;
    const { formData = {} } = this.state;

    const newValue = formData[name] || {};
    newValue.value = value;

    if (enableDebug) {
      console.log('MF: Input changed', newValue, value, formData);
    }

    this.setState({
      formData: {
        ...formData,
        [name]: newValue,
      },
    });
  }

  render() {
    const {
      steps,
      enableDebug = true,
      sidebar = false,
      onCloseRedirect = '/',
      isSubmitPending = false,
    } = this.props;

    const {
      close,
      submitted,
      review,
      activeStep,
      formData,
      formErrors,
    } = this.state;

    const SidebarElement = getSidebar(sidebar);

    return (
      <>
        <div style={{ paddingBottom: '1em' }} className="flex flex-row mb-4">
          <div className={sidebar ? 'w-8/12 lg:w-9/12 mr-10' : 'w-full'}>
            <div className="MultiForm">

              {(close && onCloseRedirect) && <Redirect to={onCloseRedirect} />}

              {submitted && (
                <div>
                  <h1 className="title">Submitted</h1>
                </div>
              )}

              {!submitted && review && (
                <ReviewStep
                  submitForm={this.submitForm}
                  steps={steps}
                  changeStep={this.changeStep}
                  formData={formData}
                  isPending={isSubmitPending}
                  formErrors={validateMultiform(formData, { steps }, true)}
                />
              )}

              {!submitted && !review && (
                <Step
                  activeStep={activeStep}
                  steps={steps}
                  changeStep={this.changeStep}
                  formData={formData}
                  formErrors={formErrors}
                  onChange={this.handleInputChange}
                  updateValueAndError={this.updateValueAndError}
                  {...steps[activeStep]}
                />
              )}

            </div>
          </div>

          {(!!SidebarElement && formData !== null) && (
            <div className="w-4/12 lg:w-3/12">
              <SidebarElement
                activeStep={activeStep}
                steps={steps}
                formData={formData}
                saveAndClose={this.saveAndClose}
                {...this.state}
              />
            </div>
          )}
        </div>

        {enableDebug && (
          <>
            <DebugPanel title="Form Data" value={formData} />
            <DebugPanel title="Form Errors" value={formErrors} />
          </>
        )}
      </>
    );
  }
}

MultiForm.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  activeStep: PropTypes.number,
  review: PropTypes.bool,
  isSubmitPending: PropTypes.bool,
  saveOnStepChange: PropTypes.bool,
  enableDebug: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onCloseRedirect: PropTypes.string,
  formData: PropTypes.shape({}),
  sidebar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

MultiForm.defaultProps = {
  activeStep: 0,
  review: false,
  isSubmitPending: false,
  saveOnStepChange: false,
  enableDebug: false,
  onUpdate: undefined,
  onCloseRedirect: '/',
  formData: {},
  sidebar: '',
};

export default MultiForm;
