/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

import { OverflowContainer } from './Dropdown.css';

// TODO: Refactor component to remove `button` props or properly merge other existing button props into this object.
const Dropdown = ({
  label,
  buttonClassName,
  activeDropdownClass,
  buttonId,
  children,
  childrenClassName,
  buttonProps = {
    color: 'peppercorn',
    size: 'md',
  },
  icon = 'angle-down',
  activeIcon = 'delete',
  dropdownWidth = 'w-48',
  dropdownSpacing = 'my-1 py-2',
}) => {
  const [hidden, setHidden] = useState(true);

  return (
    <React.Fragment>
      <div className={`dropdown relative ${!hidden ? 'z-20' : ''}`}>
        <Button
          type="button"
          id={buttonId}
          icon={hidden ? icon : activeIcon}
          onClickHandler={() => setHidden(!hidden)}
          className={`block flex flex-row items-center justify-between ${buttonClassName} ${!hidden ? activeDropdownClass : ''}`}
          {...buttonProps}
        >
          <span>{label}</span>
        </Button>

        {!hidden && (
          <div className={`flex flex-col absolute left-0 ${dropdownSpacing} ${dropdownWidth} bg-white shadow-xl z-20 ${childrenClassName}`} aria-labelledby={buttonId}>
            <OverflowContainer>
              {children}
            </OverflowContainer>
          </div>
        )}
      </div>

      {!hidden && (
        // TODO: Investigate a11y friendly ways to handle this.
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events,max-len
        <div role="button" className="fixed inset-0 min-w-full h-screen z-10" onClick={() => setHidden(true)} />
      )}
    </React.Fragment>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  activeIcon: PropTypes.string,
  buttonClassName: PropTypes.string,
  activeDropdownClass: PropTypes.string,
  childrenClassName: PropTypes.string,
  buttonId: PropTypes.string,
  dropdownWidth: PropTypes.string,
  dropdownSpacing: PropTypes.string,
  buttonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
  }),
};

Dropdown.defaultProps = {
  buttonClassName: '',
  activeDropdownClass: 'active',
  buttonId: '',
  childrenClassName: '',
  buttonProps: {
    color: 'peppercorn',
    size: 'md',
  },
  icon: 'angle-down',
  activeIcon: 'delete',
  dropdownWidth: 'w-48',
  dropdownSpacing: 'my-1 py-2',
};

export default Dropdown;
