import { urlToFqdn } from '../../utils/url-helper/utils';

// update a permit by ID:
export const createOrganizationMutation = (owner, organization) => (` mutation {
     createOrganization(
       owner: {
         firstName: "${owner.firstName}"
         lastName: "${owner.lastName}"
         email: "${owner.email}"
         password: "${owner.password}"
       }
       organization: {
         ${organization.domain ? `domain: "${urlToFqdn(organization.domain)}"` : ''}
         name: "${organization.name}"
         roles: "${organization.roles}"
         location: {
           address1: "${organization.location.address1}"
           address2: "${organization.location.address2}"
           zipcode: "${organization.location.zipcode}"
           state: "${organization.location.state}"
         }
       }
     ){ id }
   }`);

export const allOrganizationsSummary = `{
  organizations(query: "") {
    id
    name
    infoHash
    address
    domain
    location { state }
    owner { firstName lastName email }
    roles {identifier label}
    verified
  }
}`;
