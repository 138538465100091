import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../Elements/Accordion';

const DebugPanel = ({ title, value }) => (
  <div className="my-3">
    <Accordion
      header={`Open ${title} Debug Panel`}
    >
      <div className="py-3">
        <div className="flex-auto">
          <h4 className="font-header font-semibold text-md">
            {title}
          </h4>
          <h5 className="font-header font-bold text-sm">
            Debug Panel
          </h5>
          <div className="my-3">
            <pre className="overflow-x-scroll max-w-2xl bg-white p-4">
              {JSON.stringify(value, null, '  ')}
            </pre>
          </div>
        </div>
      </div>
    </Accordion>
  </div>
);

DebugPanel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.shape({}),
};

DebugPanel.defaultProps = {
  title: 'Debug Panel',
  value: {},
};

export default DebugPanel;
