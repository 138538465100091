import React from 'react';
import PropTypes from 'prop-types';

const Copy = ({
  children,
  fontSize,
  fontWeight,
  className,
}) => (
  <div className={`font-body text-${fontSize} font-${fontWeight} ${className}`}>
    {children}
  </div>
);

Copy.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOf(['xs', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']),
  fontWeight: PropTypes.oneOf(['hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
  className: PropTypes.string,
};

Copy.defaultProps = {
  fontSize: 'base',
  fontWeight: 'light',
  className: '',
};

export default Copy;
