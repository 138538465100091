// The attestation component intakes a set of claims and returns a true or false
// value based on whether all the boxes are checked or not.
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import SimpleCheckbox from './SimpleCheckbox';

const reducer = (state, action) => {
  const values = {
    ...state.values,
    [action.name]: !state.values[action.name],
  };
  const hasError = state.options
    .map(o => Boolean(values[o.name]))
    .includes(false);

  switch (action.type) {
    case 'updateValueandError':
      return {
        ...state,
        values,
        error: hasError ? 'Must agree to all terms and conditions.' : null,
      };
    default:
      return state;
  }
};

const Attestations = ({
  name,
  options,
  value,
  error,
  updateValueAndError,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    options,
    error,
    values: options.reduce((acc, opt) => { acc[opt.name] = value; return acc; }, {}),
  });

  useEffect(() => {
    updateValueAndError(name, !state.error, state.error);
  }, [state, name, updateValueAndError]);

  function toggleCheckBox(boxName) {
    dispatch({ type: 'updateValueandError', name: boxName });
  }

  return (
    <>
      <p className="mb-4">I understand and agree to the following:</p>
      {state.error && <p className="text-red-600 mb-4">{state.error}</p>}

      {options.map(opt => (
        <SimpleCheckbox
          key={opt.name}
          name={opt.name}
          value={opt.label}
          checked={Boolean(state.values[opt.name])}
          updateValueAndError={() => { }}
          error={state.error}
          onChange={toggleCheckBox}
        />
      ))}
    </>
  );
};

Attestations.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.bool,
  error: PropTypes.string,
  updateValueAndError: PropTypes.func.isRequired,
};

Attestations.defaultProps = {
  error: null,
  value: false,
};

export default Attestations;
