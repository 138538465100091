

/**
 * Center Map using Browser GeoLocation
 *
 * @export
 * @param {object} map Instance of Google Map
 * @returns
 */
export function setMapCenterFromBrowserLatLng(map) {
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      map.setCenter(pos);
    });
  } else {
    // TODO: Implement fallback using Google's geocoder Lib?
    // var loc = {};
    // var geocoder = new maps.Geocoder();
    // if(google.loader.ClientLocation) {
    //     loc.lat = google.loader.ClientLocation.latitude;
    //     loc.lng = google.loader.ClientLocation.longitude;

    //     var latlng = new google.maps.LatLng(loc.lat, loc.lng);
    //     geocoder.geocode({'latLng': latlng}, function(results, status) {
    //         if(status == google.maps.GeocoderStatus.OK) {
    //             alert(results[0]['formatted_address']);
    //         };
    //     });
    // }

    return false;
  }
  return true;
}

export function extractCenterLatLngFromPolygon(poly) {
  const latitudes = [];
  const longitudes = [];
  const vertices = poly.getPath();

  // put all latitudes and longitudes in arrays
  for (let i = 0; i < vertices.length; i += 1) {
    longitudes.push(vertices.getAt(i).lng());
    latitudes.push(vertices.getAt(i).lat());
  }

  // sort the arrays low to high
  latitudes.sort();
  longitudes.sort();

  // get the min and max of each
  const lowX = latitudes[0];
  const highX = latitudes[latitudes.length - 1];
  const lowy = longitudes[0];
  const highy = longitudes[latitudes.length - 1];

  // center of the polygon is the starting point plus the midpoint
  const centerX = lowX + ((highX - lowX) / 2);
  const centerY = lowy + ((highy - lowy) / 2);

  return {
    lat: centerX,
    lng: centerY,
  };
}

export function sqMetersToAcres(meters) {
  return meters / 4046.85642;
}

export function sqMetersToFeet(meters) {
  return meters * 10.764;
}
