import React from 'react';
// import PropTypes from 'prop-types';

import Head from 'components/head';
import { Consumer } from 'store/createContext';

import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
// import Copy from '~v2/elements/copy';

const AccountPage = () => {
  return (
    <>
      <Head
        pageTitle="Account Dashboard"
        pageDescription="User account details and services."
      />
      <Wrapper>
        <TitleContainer>
          <Title as="h1">Account Overview</Title>
        </TitleContainer>
        <Consumer>
          {({ state, dispatch }) => {
            return (
              <div>
                <Title>{state.authToken}</Title>
              </div>
            );
          }}
        </Consumer>
      </Wrapper>
    </>
  );
};

// Login.propTypes = {
//   onLoginSubmit: PropTypes.func.isRequired,
//   loginError: PropTypes.string
// }

export default AccountPage;
