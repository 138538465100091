import React from 'react';
import InputBase from './InputBase';
import InputWrapper from './InputWrapper';

class ImageFileUploader extends InputBase {
  render() {
    const { error, label, name } = this.props;

    return (
      <InputWrapper error={error} label={label}>
        <input
          onChange={(e) => { console.log(e.target.files); }}
          type="file"
          name={name}
          accept="image/png, image/jpeg"
        />
      </InputWrapper>
    );
  }
}

export default ImageFileUploader;
