/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
// TODO: Refactor to be a11y friendly & remove disabled rules on file.
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from './Confirmation';

const ConfirmationIcon = ({
  iconProps,
  titleText,
  children,
  confirm,
  imgSrc,
  useImg,
  showButtons,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <React.Fragment>
      <span
        style={{ cursor: 'pointer' }}
        className="mx-4"
        role="button"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
        }}
      >
        <i {...iconProps} />
      </span>
      <ConfirmationModal
        modal={{ isOpen, setOpen }}
        {...{
          imgSrc,
          useImg,
          titleText,
          showButtons,
        }}
        confirmFn={() => {
          if (confirm) {
            confirm();
          }
          setOpen(false);
        }}
        cancelFn={() => {
          setOpen(false);
        }}
      >
        {React.Children.map(children, child => React.cloneElement(child, {
          closeModal: () => {
            setOpen(false);
          },
        }))
      }
      </ConfirmationModal>
    </React.Fragment>
  );
};

ConfirmationIcon.propTypes = {
  imgSrc: PropTypes.string,
  titleText: PropTypes.string,
  iconProps: PropTypes.shape({}), // TODO: define this shape.
  children: PropTypes.node.isRequired,
  confirm: PropTypes.func,
  useImg: PropTypes.bool,
  showButtons: PropTypes.bool,
};

ConfirmationIcon.defaultProps = {
  iconProps: {},
  imgSrc: '',
  titleText: undefined,
  useImg: false,
  showButtons: true,
  confirm: () => {},
};

export default ConfirmationIcon;
