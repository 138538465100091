import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import Head from 'components/head';
import { updateUserPassword } from 'store/traceAPI';
import Wrapper from '~v2/elements/container/wrapper';
import Copy from '~v2/elements/copy';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import { Button, ModalBase } from 'core/src/components/Elements';
import PasswordReset from 'core/src/components/Forms/ResetPassword';

const ResetPasswordPage = ({ token, userRole }) => {
  const [formState, setStatus] = useState({
    status: 'init',
    result: undefined,
  });

  const isServer = typeof window === 'undefined';

  // We need a token/user role to properly reset password.
  // Redirect to request password reset page if not available.
  if ((!token || !userRole) && !isServer) {
    navigate('/app/forgot-password');
  }

  return (
    <>
      <ModalBase
        className="w-2/3 lg:w-1/2 xl:w-1/3"
        rootId="___gatsby"
        ariaLabel="Reset password success dialogue:"
        ariaCloseLabel="Close reset password success dialogue"
        isOpen={formState.status === 'complete'}
        setOpen={() => {
          /* TODO remove, but currently this is a required prop */
        }}
      >
        <div className="p-12">
          <Title fontWeight="bold" fontSize="3xl">
            Password Reset Complete
          </Title>
          <Copy className="mt-6" marginBottom="0.6rem">
            Your password was reset successfully.
          </Copy>
          <Copy className="mb-6">
            Click the button below to login to your account.
          </Copy>
          <Button
            color="green"
            type="link"
            to="/app/login/"
            LinkComponent={Link}
          >
            Login
          </Button>
        </div>
      </ModalBase>
      <Head
        pageTitle="User Login Page"
        pageDescription="The Trace team never sleeps. Check out our blog to stay apprised of how we’re elevating the hemp industry."
      />
      <div className="bg-gray-100">
        <Wrapper>
          <div id="LoginForm" className="w-full py-16">
            <div className="w-full lg:w-2/3 flex flex-col self-center text-black">
              <div className="py-2">
                <TitleContainer>
                  <Title as="h1" variant="subheading">
                    Reset Your Password
                  </Title>
                </TitleContainer>
                <div className="px-8 xl:px-0">
                  <Copy size="lg">
                    Enter a new password here to reset your account password.
                  </Copy>
                </div>
              </div>
              <div className="px-8 xl:px-0">
                {formState.status === 'complete' ? (
                  <Title fontWeight="bold" as="h3" fontColor="#333">
                    <Link to="/app/login">Return to Login</Link>
                  </Title>
                ) : (
                  <PasswordReset
                    handleSubmit={({ password }) => {
                      setStatus({
                        ...formState,
                        status: 'pending',
                      });

                      updateUserPassword(undefined, token, password).then(
                        result => {
                          console.log('Result from Update Password', result);

                          if (result) {
                            setStatus({
                              ...formState,
                              status: 'complete',
                            });
                          } else {
                            setStatus({
                              ...formState,
                              status: 'failed',
                            });
                          }
                        },
                      );
                    }}
                    resetResponse={
                      formState.status === 'error'
                        ? formState.result
                        : undefined
                    }
                    loginPath="/app/login/"
                    LinkComponent={Link}
                    isSubmitPending={formState.status === 'pending'}
                  />
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

ResetPasswordPage.propTypes = {
  resetResponse: PropTypes.string,
  token: PropTypes.string,
  userRole: PropTypes.string,
};
export default ResetPasswordPage;
