import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '../../Elements/Button';
import PasswordAndConfirmationInput from '../../MultiForm/parts/PasswordAndConfirmationInput';

const ResetPassword = ({ 
  handleSubmit, 
  resetResponse, 
  isSubmitPending,
  loginPath,
  LinkComponent,
}) => {
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({});

  const addError = (name, msg) => {
    if (msg) {
      setErrors({ ...errors, [name]: msg });
    } else {
      delete errors[name];
      setErrors({ ...errors });
    }
  };

  return (
    <form>

      <div className="my-6">
        <PasswordAndConfirmationInput
          name="reset_password"
          required
          type=""
          updateValueAndError={(_, newPasswordVal, err) => {
            setPassword(newPasswordVal);
            addError('password', err);
          }}
        />
      </div>

      <div>
        {resetResponse && <span className="text-red-500">{resetResponse}</span>}
      </div>

      <div className="my-8">
        <div className="flex flex-row items-center">
          <Button
            onClickHandler={(e) => {
              e.preventDefault();
              handleSubmit({ password });
            }}
            size="xl"
            icon={isSubmitPending ? 'loader' : ''}
            className="uppercase"
            isDisabled={isSubmitPending || Object.keys(errors).length !== 0 || password === ''}
          >
            {!isSubmitPending ? 'Reset Password' : null}
          </Button>
          
          {LinkComponent && loginPath && (
            <LinkComponent className="ml-6 text-sm hover:text-gray-700" to={loginPath}>
              Return to Login
            </LinkComponent>
          )}
        </div>
      </div>
    </form>
  );
};

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetResponse: PropTypes.string,
  isSubmitPending: PropTypes.bool,
};

ResetPassword.defaultProps = {
  resetResponse: null,
  isSubmitPending: false,
};

export default ResetPassword;
