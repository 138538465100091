import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Icon from '../Icon';

const ModalBase = ({
  children,
  ariaLabel,
  ariaCloseLabel,
  isOpen = false,
  setOpen,
  className = '',
  rootId = 'root',
  overlayClassName = '',
}) => {
  ReactModal.setAppElement(`#${rootId}`);

  return (
    <ReactModal
      closeTimeoutMS={200}
      isOpen={isOpen}
      contentLabel={ariaLabel}
      className={`bg-white rounded ${className}`}
      overlayClassName={`fixed z-50 flex self-stretch justify-center items-center h-screen top-0 left-0 min-w-full ${overlayClassName}`}
      style={{
        overlay: { inset: '0px', backgroundColor: 'rgba(215, 215, 215, 0.75)' },
      }}
      onRequestClose={() => setOpen(false)}
    >
      <div className="relative">
        <span className="absolute top-0 bottom-0 right-0 p-4 z-10">
          <button type="button" onClick={() => setOpen(false)}>
            <span className="sr-only">{ariaCloseLabel}</span>
            <Icon icon="delete" />
          </button>
        </span>
        {children}
      </div>

    </ReactModal>
  );
};

ModalBase.propTypes = {
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  ariaCloseLabel: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  rootId: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

ModalBase.defaultProps = {
  isOpen: false,
  className: '',
  overlayClassName: '',
  rootId: 'root',
  ariaLabel: 'Open Modal',
  ariaCloseLabel: 'Close Modal',
};


export default ModalBase;
