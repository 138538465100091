import React from 'react';
import PropTypes from 'prop-types';

import { Rings, ThreeDots, Oval } from 'svg-loaders-react';

const Loader = ({
  color,
  type,
  className,
}) => {
  switch (type) {
    case 'dots':
      return (
        <React.Fragment>
          <div className="flex items-center h-0 text-center">
            <div style={{ transform: 'scale(0.25)' }}>
              <ThreeDots fill={color} className={className} />
            </div>
          </div>
        &nbsp;
        </React.Fragment>
      );
    case 'circle':
      return (
        <div className="pending text-center">
          <Oval fill={color} stroke={color} className={className} />
        </div>
      );
    case 'rings':
    default:
      return (
        <div className="pending text-center">
          <Rings stroke={color} className={className} />
        </div>
      );
  }
};

Loader.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['dots', 'circle', 'rings']),
  className: PropTypes.string,
};

Loader.defaultProps = {
  color: '#333',
  type: 'circle',
  className: 'my-2 mx-auto',
};

export default Loader;
