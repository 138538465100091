import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';
import Dropdown from '../../Elements/Dropdown';

const NodeTypeDropdown = ({
  options = [],
  name,
  className,
  active,
  currentOption,
  onOptionSelect,
  onDeactivate,
}) => {
  const id = `${name}-dropdown`;

  return (
    <div className={className}>
      {active ? (
        <Button
          type="button"
          color="graygold"
          size="sm"
          icon="delete"
          iconSize="xl"
          className="flex font-medium flex-row items-center justify-start w-full"
          innerClassName="w-full justify-between"
          onClickHandler={() => onDeactivate(name)}
        >
          <span className="uppercase font-medium flex flex-row items-center text-sm">
            <i className={`icon-${currentOption.icon} flex items-center mr-3 text-lg`} />
            {' '}
            {currentOption.label}
          </span>
        </Button>
      ) : (
        <Dropdown
          activeDropdownClass=""
          label="CHOOSE POINT TYPE"
          buttonId={id}
          dropdownWidth="w-full"
          dropdownSpacing="my-1"
          buttonProps={{
            color: 'graygold',
            size: 'sm',
            iconSize: 'xl',
            className: 'flex font-medium flex-row items-center justify-start w-full',
            innerClassName: 'w-full justify-between',
          }}
        >
          {options.map(({ label, value, icon }) => (
            <Button
              key={`${name}-${value}`}
              type="button"
              color="graygold"
              size="md"
              icon={icon}
              iconFirst
              isSquareShape
              className="font-medium uppercase w-full text-left"
              onClickHandler={() => {
                onOptionSelect(name, value);
              }}
            >
              {label}
            </Button>
          ))}
        </Dropdown>
      )}
    </div>
  );
};

NodeTypeDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  currentOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.string,
  }),
  className: PropTypes.string,
  active: PropTypes.bool,
  onDeactivate: PropTypes.func.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
};

NodeTypeDropdown.defaultProps = {
  options: [],
  currentOption: {
    label: '',
    value: '',
    icon: '',
  },
  className: '',
  active: false,
};

export default NodeTypeDropdown;
