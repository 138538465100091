import styled from 'styled-components';
import PropTypes from 'prop-types';

// Handles adding dynamic styles to loaded SVG
const SvgWrapper = styled.span`

  svg {
    display: inline;
  }


  ${({ iconProps, hoverProps }) => {
    const { fill, stroke, transform } = iconProps;
    let styles = '';

    // Root level styles
    styles += fill ? `fill: ${fill}!important;` : '';
    styles += stroke ? `stroke: ${stroke}!important;` : '';
    styles += transform ? `transform: ${transform};` : '';

    // TODO: This should have some better validation to prevent against invalid CSS.
    // Could result in invalid rules if bad object keys is passed.
    Object.keys(iconProps).map((elementName) => {
      const {
        childStroke, childFill, childTransform, childBackground, childBorderRadius,
      } = iconProps[elementName];
      styles += `
        ${elementName} {
          ${childFill ? `fill: ${childFill}!important;` : ''}
          ${childStroke ? `stroke: ${childStroke}!important` : ''}
          ${childTransform ? `transform: ${childTransform}!important` : ''}
          ${childBackground ? `background: ${childBackground}!important` : ''}
          ${childBorderRadius ? `border-radius: ${childBorderRadius}!important` : ''}
        }
      `;

      // Appease linters.
      return true;
    });

    Object.keys(hoverProps).map((elementName) => {
      const { hoverStroke, hoverFill, hoverTransform } = hoverProps[elementName];
      styles += `&:hover {
        ${elementName} {
          ${hoverFill ? `fill: ${hoverFill}!important;` : ''}
          ${hoverStroke ? `stroke: ${hoverStroke}!important` : ''}
          ${hoverTransform ? `transform: ${hoverTransform}!important` : ''}
        }
      }`;

      // Appease linters.
      return true;
    });

    return styles;
  }};
`;

SvgWrapper.propTypes = {
  iconProps: PropTypes.shape({}),
  hoverProps: PropTypes.shape({}),
  className: PropTypes.string,
};

SvgWrapper.defaultProps = {
  iconProps: {},
  hoverProps: {},
  className: '',
};

export default SvgWrapper;
