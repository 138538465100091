import React from 'react';
import PropTypes from 'prop-types';

import Copy from '../../../Elements/Copy';
import { getFormPart } from '../../PartRegistery';

const ReviewField = ({ field, value, error }) => {
  if (field.type === 'text' || field.type === 'header') {
    return (null);
  }

  const registryDef = getFormPart(field.type);

  return (
    <div key={field.name} className="font-body">
      <h4 className="font-medium text-base pl-3 mt-3">
        {field.label}
      </h4>
      <div className="font-light border-b border-color-grey-400 pb-3 my-3">
        <Copy className="pl-3">
          {value && registryDef.displayValue(value, field)}
          {error && <p className="text-red-400">{error}</p>}
        </Copy>
      </div>
    </div>
  );
};

ReviewField.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf([
      PropTypes.shape({}),
    ]),
  ]),
  error: PropTypes.string,
};

ReviewField.defaultProps = {
  value: '',
  error: '',
};

export default ReviewField;

// TODO: Refactor `SitesView` Component into new file.
// const SitesView = ({ sites }) => (<pre>{JSON.stringify(sites)}</pre>);

// SitesView.propTypes = {
//   sites: PropTypes.shape({}),
// };
