
// get ALL pending Permits
// query { permits(status: pending) { results { id } } }

// start permit for a year
//

// update a permit by ID:
export const updatePermitMutation = (id, details) => {
  // todo this prevents nested objects from being saved.
  // let graphqlDetails = Object.keys(details).map((k) => {
  //   return(`${k}: "${details[k]}"`)
  // }).join("\n")

  // TODO this has to be double escaped to submit the string properly.
  // variants i tried:
  // -- JSON.stringify(details)
  // -- escape(JSON.stringify(details))
  // -- JSON.stringify(details).replace(/"/, '\\"')
  const graphqlDetails = JSON.stringify(JSON.stringify(details));

  return (`mutation {
     updatePermit(
       id: "${id}"
       details: ${graphqlDetails}
     ){
       id
       status
     }
   }`);
};


export const markPermitAsReadMutation = id => `mutation {
  markPermitAsRead(id: "${id}", read: true) {
    read
  }
}`;


export const loadPermitByIdQuery = id => `{
  permit(id: "${id}"){
    id
    details
    read
    organization { name }
    pdfLink
    period {
      start
      end
    }
    history {
      status
      modifiedBy {firstName lastName}
      reason
      created
      details
    }
    payment {
      isPaid
      totalPaid
      balance
    }
    status
    created
  }
}`;

export const newPermitMutation = year => `mutation {
  startPermit(year: ${year}) {
    id
  }
}`;

export const submitPermitMutation = id => `mutation {
  submitPermit(id: "${id}")
}`;

export const permitListingQuery = `{
  permits(status: null){results {
      id
      read
      organization { name }
      period {
        start
        end
      }
      payment {
        isPaid
        totalPaid
        balance
      }
      status
      pdfLink
      created
    }
  }
}`;

export const recordPermitPaymentMutation = ({
  permitId,
  paymentMethod,
  paymentAmount,
}) => `mutation {
  recordPermitPayment(
    id: "${permitId}",
    payment: {
      paymentMethod: ${paymentMethod},
      paymentAmount: ${paymentAmount}
    }
  ) {
    id
    payment {
      isPaid
      totalPaid
      balance
    }
  }
}`;


export const updatePermitStatusMutation = (id, status, reason) => `mutation {
  updatePermitStatus(
    id:     "${id}",
    status: ${status},
    reason: "${reason}"
  ) {
    id
    status
  }
}`;
