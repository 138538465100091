import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';

import NodeMeta from './NodeMeta';
import NodeTypeDropdown from './NodeTypeDropdown';


const NodeControl = (props) => {
  const {
    index,
    node = {
      nodeLabel: null,
      nodeType: null,
    },
    nodeTypes = [],
    isActive = false,
    removeNode,
    removeShape,
    updateNode,
    setActiveNode,
    mapApi,
    drawingManager,
    updateDrawingManagerOptions,
  } = props;

  const updateDrawingControls = (nodeType) => {
    // TODO: Refactor into ultility helpers file?
    const allowedShapes = (nodeType in ['grow_area_indoor', 'grow_area_outdoor'] ? ['polygon'] : ['marker']);
    const drawingMode = (nodeType in ['grow_area_indoor', 'grow_area_outdoor'] ? mapApi.drawing.OverlayType.POLYGON : mapApi.drawing.OverlayType.MARKER);

    updateDrawingManagerOptions(
      drawingManager,
      allowedShapes,
      {
        drawingMode,
      },
    );
  };

  let needle = node.nodeType;
  if (needle === 'grow_location') {
    needle = 'grow_area_outdoor';
  }

  const currentOption = needle
    ? nodeTypes.filter(({ value }) => needle === value)[0]
    : {
      label: 'Incomplete Node',
      icon: undefined,
      value: undefined,
    };

  return (
    <div
      key={`node_${index}`}
      className={`block mb-3 ${index > 0 ? 'mt-3 pt-3 border-t border-gray-200' : ''}`}
    >

      {!isActive && !!currentOption ? (
        <div className="flex flex-row">
          <Button
            type="button"
            color="transparent"
            // variant="outline"
            size="sm"
            className="items-center self-center w-full text-gold-900"
            innerClassName="w-full font-medium uppercase flex justify-between items-center"
            isPillShape
            icon="edit"
            iconMargin="0"
            iconSize="md"
            onClickHandler={() => {
              setActiveNode(index);
            }}
          >
            <span className="flex flex-row items-center w-full">
              <i className={`icon-${currentOption.icon} flex items-center mr-2 text-lg`} />
              {currentOption.label}
            </span>
          </Button>
          <Button
            type="button"
            color="transparent"
            size="icon"
            className="items-center self-center uppercase text-md opacity-25 hover:opacity-100 -ml-2"
            innerClassName="justify-center"
            isPillShape
            icon="delete"
            iconSize="xl"
            iconMargin="0"
            onClickHandler={() => {
              removeNode(index);
            }}
          />
        </div>
      ) : (
        <NodeTypeDropdown
          options={nodeTypes}
          key={`sitemap_node-${index}`}
          name={`sitemap_node_selectdropdown_${index}`}
          currentOption={currentOption}
          displayName="CHOOSE POINT TYPE"
          onOptionSelect={(name, value) => {
            // console.log('optionSelected', value, index);

            updateNode('nodeType', value, index);
            updateDrawingControls(value);
          }}
          onDeactivate={() => {
            // console.log('we are in deactivation mode', node, name, value)

            removeShape(node.overlay, node.overlayType);
            updateNode('overlayType', undefined, index);
            updateNode('nodeType', undefined, index);
            updateNode('overlay', undefined, index);
          }}
          active={!!node.nodeType}
        />
      )}

      <div className="mt-3 pl-5">
        <NodeMeta node={node} />
      </div>
    </div>
  );
};

NodeControl.propTypes = {
  index: PropTypes.number.isRequired,
  node: PropTypes.shape({
    acres: PropTypes.number,
    nodeType: PropTypes.string,
    overlayType: PropTypes.string,
    overlay: PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.func,
        lng: PropTypes.func,
      }),
    }),
  }).isRequired,
  nodeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  isActive: PropTypes.bool,
  removeNode: PropTypes.func.isRequired,
  removeShape: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  setActiveNode: PropTypes.func.isRequired,
  mapApi: PropTypes.shape({
    drawing: PropTypes.shape({
      OverlayType: PropTypes.shape({
        POLYGON: PropTypes.string,
        MARKER: PropTypes.string,
      }),
    }),
  }).isRequired,
  drawingManager: PropTypes.shape({}).isRequired,
  updateDrawingManagerOptions: PropTypes.func.isRequired,
};

NodeControl.defaultProps = {
  isActive: false,
};

export default NodeControl;
