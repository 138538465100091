import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from '../../configs/tailwind/colors';

import Loader from '../Elements/Loader';
import GoogleMap from '../Elements/GoogleMap';
import SearchBox from '../Elements/GoogleMap/SearchBox';
import {
  setMapCenterFromBrowserLatLng,
  extractCenterLatLngFromPolygon,
  sqMetersToAcres,
  sqMetersToFeet,
} from '../Elements/GoogleMap/utils';
import drawingUtils from '../Elements/GoogleMap/drawing-utils';

import NodeList from './NodeList';
import { nodeTypes } from './config';


class SiteMapper extends Component {
  constructor(props) {
    super(props);

    const {
      mapNodes = [],
    } = this.props;

    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      drawingManager: null,
      mapApi: null,
      mapNodes,
      activeNode: null,
    };
  }

  // force validation check when changing states
  componentWillUnmount() {
    const { updateOnUnmount } = this.props;

    if (updateOnUnmount) {
      this.saveMapState();
    }
  }

  removeShape = (shape, type) => {
    const {
      mapApiLoaded,
      mapApi,
    } = this.state;

    if (!mapApiLoaded) {
      return;
    }

    drawingUtils.removeShape(shape, type, mapApi);
  }

  updateDrawingManagerOptions = (drawingManager, drawingModes = ['polygon', 'marker'], options = {}) => {
    const {
      mapApiLoaded,
      mapApi,
    } = this.state;

    if (!mapApiLoaded) {
      return;
    }

    drawingUtils.updateDrawingOptions(drawingManager, mapApi, drawingModes, options);
  }

  // Store our Map Nodes.
  saveMapState = () => {
    const { mapNodes = [] } = this.state;
    const { onMapUpdate, isEditable, errors } = this.props;

    // Do not triger save if map isnt editable.
    if (!isEditable) {
      return;
    }

    const updatedMapNodes = mapNodes.filter(node => node !== null).map((mapNode) => {
      const {
        overlayType,
        overlay,
        nodeLabel,
        nodeType,
        sqFeet,
        acres,
      } = mapNode;

      let {
        nodeCords = [],
      } = mapNode;

      const additionalMeta = {};

      if (overlay) {
        if (overlayType === 'marker') {
          nodeCords[0] = {
            lat: overlay.position.lat(),
            lng: overlay.position.lng(),
          };
        }

        if (overlayType === 'polygon') {
          nodeCords = overlay.getPath().getArray().map(item => ({
            lat: item.lat(),
            lng: item.lng(),
          }));

          additionalMeta.acres = acres;
          additionalMeta.sqFeet = sqFeet;
        }
      }

      return {
        overlayType,
        nodeLabel,
        nodeType,
        nodeCords,
        ...additionalMeta,
      };
    });

    onMapUpdate('mapNodes', [
      ...updatedMapNodes,
    ], errors);
  }

  centerMapOnOverlay = (overlay, overlayType, map) => {
    let newMapCenter = {};

    if (overlayType === 'polygon') {
      newMapCenter = extractCenterLatLngFromPolygon(overlay);
    }

    if (overlayType === 'marker') {
      newMapCenter.lat = overlay.position.lat();
      newMapCenter.lng = overlay.position.lng();
    }

    if (newMapCenter.lat && newMapCenter.lng) {
      map.panTo(newMapCenter);
    }
  }

  generateMarkersOnLoad = (mapNodes, map, mapApi) => {
    if (mapNodes.length > 0) {
      return mapNodes.map(({
        nodeCords,
        nodeType,
        nodeLabel,
        overlayType,
        sqFeet,
        acres,
      }) => {
        let overlay = null;
        const additionalMeta = {};
        // TODO: Better way to programmatically draw map items
        // without accessing internal Google API Objects?

        // Polygons
        if (overlayType === 'polygon'
          && nodeCords
          && nodeCords.length > 0
        ) {
          overlay = new mapApi.Polygon({
            path: nodeCords,
            strokeColor: colors.gold['500'],
            fillColor: colors.gold['500'],
            strokeWeight: 1.25,
            fillOpacity: 0.6,
            map,
          });

          // Default area unit is sq. meters.
          const area = mapApi.geometry.spherical.computeArea(
            overlay.getPath(),
          );

          // console.log('calculating area', nodeType, overlayType)

          additionalMeta.acres = sqMetersToAcres(area);
          additionalMeta.sqFeet = sqMetersToFeet(area);
          // overlay.set('editable', true);

          this.addPolygonUpdateListeners(overlay);
        }

        // Markers
        if (overlayType === 'marker'
          && nodeCords
          && nodeCords.length > 0
        ) {
          overlay = new mapApi.Marker({
            position: nodeCords[0],
            title: nodeLabel,
            map,
          });

          // Set custom marker icon.
          switch (nodeType) {
            case 'storage_location':
              overlay.setIcon(
                {
                  url: '/img/maps/markers/storage-site.svg',
                  scaledSize: new mapApi.Size(40, 40),
                },
              );
              break;
            case 'drying_location':
              overlay.setIcon(
                {
                  url: '/img/maps/markers/drying-site.svg',
                  scaledSize: new mapApi.Size(40, 40),
                },
              );
              break;
            case 'processing_location':
              overlay.setIcon(
                {
                  url: '/img/maps/markers/processing-site.svg',
                  scaledSize: new mapApi.Size(40, 40),
                },
              );
              break;
            case 'road_access':
              overlay.setIcon(
                {
                  url: '/img/maps/markers/vehicle-access.svg',
                  scaledSize: new mapApi.Size(40, 40),
                },
              );
              break;
            default:
              break;
          }
        }

        return {
          nodeCords,
          nodeLabel,
          nodeType,
          overlayType,
          overlay,
          acres,
          sqFeet,
          ...additionalMeta,
        };
      });
    }
    // Returns empty array on failure.
    return [];
  }

  onGoogleApiLoad = (map, mapApi) => {
    const { mapNodes = [] } = this.state;
    const nodeBounds = new mapApi.LatLngBounds();
    const refreshedNodes = this.generateMarkersOnLoad(mapNodes, map, mapApi);

    if (refreshedNodes.length > 0) {
      for (let nodeIndex = 0; nodeIndex < refreshedNodes.length; nodeIndex += 1) {
        const { nodeCords } = refreshedNodes[nodeIndex];

        for (let cordIndex = 0; cordIndex < nodeCords.length; cordIndex += 1) {
          const latLng = nodeCords[cordIndex];
          nodeBounds.extend(latLng);
        }
      }
      map.fitBounds(nodeBounds);
    } else {
      setMapCenterFromBrowserLatLng(map);
    }

    // console.log('refreshed nodes:', refreshedNodes);

    const drawingManager = drawingUtils.initDrawingManager(map, mapApi, mapNodes);

    mapApi.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      this.onOverlayComplete(event, drawingManager);
    });

    // TODO: Replace with reducer v.s. component state & remove eslint disable comment below.
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      mapApiLoaded: true,
      mapInstance: map,
      mapNodes: refreshedNodes,
      drawingManager,
      mapApi,
    }, () => {
      // Create first node control on initial load if fresh state.
      if (!refreshedNodes) {
        this.createEmptyNode();
      }
    });
  }

  onOverlayComplete = (event, drawingManager) => {
    const {
      mapApiLoaded,
      mapNodes,
      activeNode,
    } = this.state;

    // console.log('overlay complete event', event, this.state);

    if (!mapApiLoaded) {
      return;
    }

    const { overlay, type } = event;

    if (type === 'polygon') {
      this.updatePolygonOverlayState(overlay);
      this.addPolygonUpdateListeners(overlay);
    } else {
      this.updateMarkerOverlayState(overlay);
    }

    // erase existing overlays before overriding reference in state.
    if (mapNodes[activeNode].overlay) {
      this.removeShape(mapNodes[activeNode].overlay, mapNodes[activeNode].overlayType);
    }

    this.updateNode('overlay', overlay, activeNode);
    this.updateNode('overlayType', type, activeNode);
    this.updateDrawingManagerOptions(drawingManager, [], { drawingMode: '' });
  }

  handlePolygonUpdate = () => {
    // console.log('polgyon changing event', event, obj);
    const { mapNodes, activeNode } = this.state;
    this.updatePolygonOverlayState(mapNodes[activeNode].overlay);
  }

  addPolygonUpdateListeners = (polygon) => {
    polygon.getPath().addListener('insert_at', this.handlePolygonUpdate);
    polygon.getPath().addListener('set_at', this.handlePolygonUpdate);
    polygon.getPath().addListener('remove_at', this.handlePolygonUpdate);
    polygon.getPath().addListener('dragend', this.handlePolygonUpdate);
  }

  updatePolygonOverlayState = (overlay) => {
    const { mapApi, activeNode } = this.state;

    // Returns the area of a closed path. The computed area uses the same units as the radius.
    // The radius defaults to the Earth's radius in meters, in which case
    // the area is in square meters.
    // @see https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical.computeArea
    const area = mapApi.geometry.spherical.computeArea(
      overlay.getPath(),
    );

    const acres = sqMetersToAcres(area);
    const sqFeet = sqMetersToFeet(area);

    // console.log("Cultivation Area:", area, `in Acres: ${acres}`);
    this.updateNode('acres', acres, activeNode);
    this.updateNode('sqFeet', sqFeet, activeNode);
  }

  updateMarkerOverlayState = (overlay) => {
    overlay.set('editable', false);
  }

  /**
   * Creates Empty Node
   *
   * @memberof SiteMapper
   */
  createEmptyNode = () => {
    const {
      mapNodes = [],
    } = this.state;

    // TODO: Replace with reducer v.s. component state & remove eslint disable comment below.
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      mapNodes: [
        ...mapNodes,
        {
          nodeType: null,
          overlayType: null,
          overlay: null,
        },
      ],
    }, () => {
      this.setActiveNode();
    });
  }

  /**
  * Retrieve node by Index
  *
  * @memberof SiteMapper
  */
  getNode = (index) => {
    const {
      activeNode,
      mapNodes,
    } = this.state;

    const activeItem = index || activeNode;
    return mapNodes[activeItem] ? mapNodes[activeItem] : false;
  }

  /**
   * Set Active Node via Index
   *
   * @memberof SiteMapper
   */
  setActiveNode = (index = null) => {
    const {
      activeNode,
      mapNodes,
    } = this.state;

    // console.log(`setting index: ${index}, current Node: ${activeNode}, default: ${mapNodes.length - 1}`);

    if (activeNode !== index) {
      this.setOverlayColorsFromState(false);
    }

    let currentNode = mapNodes.length - 1;
    if (index !== null) {
      currentNode = index;
    } else if (activeNode) {
      currentNode = activeNode;
    }

    // TODO: Replace with reducer v.s. component state & remove eslint disable comment below.
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      activeNode: currentNode,
    }, () => {
      this.setDrawModeFromState();
      this.setOverlayColorsFromState(true);
    });
  }

  /**
   * Attempts to set map drawing tools based on active node in state.
   *
   * @memberof SiteMapper
   */
  setDrawModeFromState = () => {
    // After setting activeNode to new node, check if we should update drawing controls.
    const {
      mapNodes,
      activeNode,
      mapApi,
      drawingManager,
    } = this.state;

    let drawingMode = '';


    let allowedShapes = [];

    if (
      mapNodes[activeNode].nodeType !== false
    ) {
      switch (mapNodes[activeNode].nodeType) {
        case 'grow_area_indoor':
        case 'grow_area_outdoor':
          drawingMode = !mapNodes[activeNode].overlayType ? mapApi.drawing.OverlayType.POLYGON : '';
          allowedShapes = ['polygon'];
          break;
        case 'storage_location':
        case 'processing_location':
        case 'drying_location':
        case 'road_access':
          drawingMode = mapApi.drawing.OverlayType.MARKER;
          allowedShapes = ['marker'];
          break;
        default:
          drawingMode = '';
          allowedShapes = [];
          break;
      }
    }

    if (!drawingManager
      || drawingMode === drawingManager.drawingMode
    ) {
      return;
    }

    this.updateDrawingManagerOptions(
      drawingManager,
      allowedShapes,
      { drawingMode },
    );
  }

  setOverlayColorsFromState = (isActive) => {
    const {
      mapNodes,
      activeNode,
      mapInstance: map,
      mapApi,
    } = this.state;

    if (mapNodes[activeNode] === undefined || mapNodes[activeNode].overlay === false) {
      return;
    }

    // Determine overlay config based on isActive.
    const { overlay } = mapNodes[activeNode];

    const strokeWeight = isActive ? 2 : 1.25;
    const strokeColor = isActive ? colors.gold['800'] : colors.gold['500'];
    const fillColor = isActive ? colors.gold['800'] : colors.gold['500'];
    const fillOpacity = isActive ? 0.6 : 0.45;
    const iconSize = isActive ? 46 : 40;

    if (!overlay) {
      return;
    }

    overlay.set('editable', isActive);

    switch (mapNodes[activeNode].nodeType) {
      case 'grow_area':
        overlay.setOptions({
          strokeColor,
          fillColor,
          strokeWeight,
          fillOpacity,
        });

        // TODO: MOVE `centerMapOnOverlay` INTO SEPERATE FUNCTION.
        this.centerMapOnOverlay(overlay, 'polygon', map);
        break;
      case 'storage_location':
        overlay.setIcon(
          {
            url: '/img/maps/markers/storage-site.svg',
            scaledSize: new mapApi.Size(iconSize, iconSize),
          },
        );
        this.centerMapOnOverlay(overlay, 'marker', map);
        break;
      case 'drying_location':
        overlay.setIcon(
          {
            url: '/img/maps/markers/drying-site.svg',
            scaledSize: new mapApi.Size(iconSize, iconSize),
          },
        );
        this.centerMapOnOverlay(overlay, 'marker', map);
        overlay.set('editable', false);
        break;
      case 'processing_location':
        overlay.setIcon(
          {
            url: '/img/maps/markers/processing-site.svg',
            scaledSize: new mapApi.Size(iconSize, iconSize),
          },
        );
        this.centerMapOnOverlay(overlay, 'marker', map);
        overlay.set('editable', false);
        break;
      case 'road_access':
        overlay.setIcon(
          {
            url: '/img/maps/markers/vehicle-access.svg',
            scaledSize: new mapApi.Size(iconSize, iconSize),
          },
        );
        this.centerMapOnOverlay(overlay, 'marker', map);
        break;
      default:
        break;
    }
  }

  updateNode = (key, value, index = null) => {
    const {
      mapNodes = [],
      activeNode,
    } = this.state;

    const activeItem = index !== null ? index : (activeNode || mapNodes.length - 1);

    if (
      mapNodes[activeItem]
      && mapNodes[activeItem][key]
      && mapNodes[activeItem][key] === value
    ) {
      return;
    }

    // console.log('Updating Node #', activeItem, key, value);

    mapNodes[activeItem][key] = value;

    // TODO: Replace with reducer v.s. component state & remove eslint disable comment below.
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      mapNodes,
    }, () => {
      this.saveMapState();
      this.setActiveNode(activeItem);
    });
  }

  /**
   * Removes Node from Site Map
   *
   * @memberof SiteMapper
   */
  removeNode = (index = null, confirmPrompt = true) => {
    const {
      mapNodes,
    } = this.state;

    if (index === null) {
      // console.warn('Failed to remove node, no index provided.');
      return;
    }

    // TODO: Replace with real confirmation modal here.
    if (confirmPrompt) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete?')) {
        return;
      }
    }
    const {
      overlay = false,
      overlayType = false,
    } = mapNodes[index];

    // Remove polygon/marker from map.
    if (overlay) {
      this.removeShape(overlay, overlayType);
    }

    // Removes map node from array.
    mapNodes.splice(index, 1);

    // TODO: Replace with reducer v.s. component state & remove eslint disable comment below.
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      mapNodes,
    }, () => {
      this.saveMapState();
    });
  }

  render() {
    const {
      mapNodes,
      mapApiLoaded,
      mapInstance,
      mapApi,
      activeNode,
      drawingManager,
    } = this.state;

    const {
      center,
      zoom,
      isEditable,
      options = [],
    } = this.props;

    // Determine what type of nodes are available based on config.
    let availeNodeTypes = [];
    for (let index = 0; index < options.length; index += 1) {
      const nodeType = options[index];

      availeNodeTypes = [
        ...availeNodeTypes,
        ...nodeTypes.filter(option => option.value === nodeType.value),
      ];
    }

    return (
      <div className="">
        {mapApiLoaded && isEditable && (
          <SearchBox
            map={mapInstance}
            mapApi={mapApi}
            addplace={this.addPlace}
          />
        )}
        <div className="flex flex-row">
          <div className={isEditable ? 'w-8/12' : 'w-full'}>
            <div className="h-128">
              <GoogleMap
                defaultCenter={center}
                defaultZoom={zoom}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.onGoogleApiLoad(map, maps);
                }}
              >
                {!mapApiLoaded && <Loader type="circle" />}
              </GoogleMap>
            </div>
          </div>
          {isEditable && (
            <div className="w-4/12 ml-3">
              {!mapApiLoaded ? (
                <Loader type="circle" />
              ) : (
                <NodeList
                  nodes={mapNodes}
                  map={mapInstance}
                  mapApi={mapApi}
                  activeNode={activeNode}
                  nodeTypes={availeNodeTypes}
                  createEmptyNode={this.createEmptyNode}
                  updateNode={this.updateNode}
                  setActiveNode={this.setActiveNode}
                  removeNode={this.removeNode}
                  removeShape={this.removeShape}
                  drawingManager={drawingManager}
                  updateDrawingManagerOptions={this.updateDrawingManagerOptions}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

// TODO: Completely define the prop-type shapes below.
SiteMapper.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  onMapUpdate: PropTypes.func.isRequired,
  updateOnUnmount: PropTypes.bool,
  isEditable: PropTypes.bool,
  mapNodes: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ),
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

SiteMapper.defaultProps = {
  mapNodes: [],
  center: {
    lat: 44.475883,
    lng: -73.212074,
  },
  options: undefined,
  errors: undefined,
  zoom: 16,
  isEditable: true,
  updateOnUnmount: true,
};

export default SiteMapper;
