import styled from 'styled-components';
import colors from '../../../configs/tailwind/colors';

export const Container = styled.section`

  .react-tabs__tab-list {
    display: flex;
    flex-direction: row;

    border-bottom: 3px solid ${colors.gold['300']}
  }

  .react-tabs__tab {
    cursor: pointer;
    color: ${colors.gold['300']};
    border-bottom: 3px solid ${colors.gold['300']};
    margin-bottom: -3px;


    &.react-tabs__tab--selected {
      border-bottom: 3px solid ${colors.gold.black};
      color: ${colors.gold.black};
    }
  }
`;
