import React from 'react';
import PropTypes from 'prop-types';

import Copy from '../Copy';
import Title from '../Title';
import Button from '../Button';
import ModalBase from './ModalBase';

const ConfirmationModal = ({
  imgSrc = '/img/cropped/requested.png',
  useImg = true,
  showButtons = true,
  titleText,
  children,
  confirmText = 'Confirm',
  confirmColor = 'black',
  cancelText = 'Cancel',
  cancelColor = 'gold',
  confirmFn,
  cancelFn,
  modal,
}) => (
  <ModalBase
    {...modal}
    ariaLabel="Confirmation dialogue:"
    ariaCloseLabel="Close confirmation dialogue"
  >
    <div className="pt-12 pb-12 mr-4 ml-4 w-128">
      <div className="max-w-md mx-auto w-full">
        {useImg && imgSrc && (
          <div className="flex flex-col items-center">
            <img className="w-8/12" src={imgSrc} alt={titleText} />
          </div>
        )}
      </div>

      <Copy>
        {titleText && <Title className="mt-6 text-center">{titleText}</Title>}

        {children}
        <div className="text-center">
          {showButtons && cancelFn && (
            <Button
              color={cancelColor}
              onClickHandler={cancelFn}
              className="mr-4"
              type="button"
            >
              {cancelText}
            </Button>
          )}

          {showButtons && confirmFn && (
            <Button
              onClickHandler={confirmFn}
              color={confirmColor}
              className="ml-4"
              type="button"
            >
              {confirmText}
            </Button>
          )}
        </div>
      </Copy>
    </div>
  </ModalBase>
);

ConfirmationModal.propTypes = {
  imgSrc: PropTypes.string,
  titleText: PropTypes.string,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  confirmFn: PropTypes.func.isRequired,
  confirmColor: PropTypes.string,
  cancelText: PropTypes.string,
  cancelFn: PropTypes.func,
  cancelColor: PropTypes.string,
  modal: PropTypes.shape({}),
  useImg: PropTypes.bool,
  showButtons: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  imgSrc: '/img/cropped/requested.png',
  useImg: true,
  showButtons: true,
  titleText: undefined,
  confirmText: 'Confirm',
  confirmColor: 'black',
  cancelText: 'Cancel',
  cancelFn: undefined,
  cancelColor: 'gold',
  modal: {},
  children: undefined,
};

export default ConfirmationModal;
