import React from 'react';
import PropTypes from 'prop-types';

import ProgressStepper from '../../Elements/ProgressStepper';

import StepFooterNavigation from './StepFooterNavigation';
import { getFormPart } from '../PartRegistery';

const Step = ({
  title,
  updateValueAndError,
  formData = {},
  formErrors = {},
  parts,
  activeStep,
  steps,
  changeStep,
  onChange,
}) => {
  const { component: HeaderPart } = getFormPart(title.type);
  const { component: DefaultHeader } = getFormPart(title.type);

  // ProgressStepper expects array of strings to use as labels for each step.
  const progressStepperSteps = Object.keys(steps).map((item, index) => ({
    label: (index + 1).toString(),
    title: `Step ${(index + 1).toString()}: ${steps[item].title.value ? steps[item].title.value : steps[item].title}`,
  }));


  return (
    <>
      <ProgressStepper
        steps={progressStepperSteps}
        activeStep={activeStep}
        onClickHandler={index => changeStep(index)}
      />

      <div key={title} style={{ minHeight: '500px' }} className="Step">

        { HeaderPart ? (
          <HeaderPart {...title} currentStep={activeStep} />
        ) : (
          <DefaultHeader value={title} />
        )}

        {parts.map((part) => {
          // console.log(`Part Name: ${part.name}`, formData[part.name], formData);


          const { component: CurrentPart } = getFormPart(part.type);

          // console.log('Component Part:', CurrentPart, part);

          return (
            <CurrentPart
              key={part.name}
              name={part.name}
              formData={formData}
              value={formData && formData[part.name] ? formData[part.name].value : null}
              errors={formErrors && formErrors[part.name] ? formErrors[part.name] : null}
              onChange={onChange}
              updateValueAndError={updateValueAndError}
              {...part}
            />
          );
        })}
      </div>
      <StepFooterNavigation {...{ activeStep, steps, changeStep }} />
    </>
  );
};

Step.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ]).isRequired,
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  updateValueAndError: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({

  }),
  activeStep: PropTypes.number,
  parts: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  ).isRequired,
  changeStep: PropTypes.func,
  onChange: PropTypes.func,
};

Step.defaultProps = {
  activeStep: 0,
  formErrors: {},
  changeStep: () => {
    console.log('Default changeStep callback for Step Component');
  },
  onChange: () => {
    console.log('Default onChange callback for Step Component.');
  },
};

export default Step;
