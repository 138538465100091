export const nodeTypes = [
  {
    label: 'Outdoor Grow Area',
    icon: 'plant',
    type: 'polygon',
    value: 'grow_area_outdoor',
  },
  {
    label: 'Indoor Grow Area',
    icon: 'leaf',
    type: 'polygon',
    value: 'grow_area_indoor',
  },
  {
    label: 'Storage Site',
    icon: 'box',
    type: 'marker',
    value: 'storage_location',
  },
  {
    label: 'Drying Site',
    icon: 'drying',
    type: 'marker',
    value: 'drying_location',
  },
  {
    label: 'Processing Site',
    icon: 'testing',
    type: 'marker',
    value: 'processing_location',
  },
  {
    label: 'Vehicle Access',
    icon: 'vehicle',
    type: 'marker',
    value: 'road_access',
  },
];

export const emptyNodeObjTemplate = {
  nodeType: null,
  overlayType: null,
  overlay: null,
};
