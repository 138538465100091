import React from 'react';
// import PropTypes from 'prop-types';

import Head from 'components/head';
import { Consumer } from 'store/createContext';

// import { Pending } from 'core/src/components/Elements';

import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import Copy from '~v2/elements/copy';

const DashboardPage = () => {
  return (
    <>
      <Head
        pageTitle="User Dashboard"
        pageDescription="Overview of account details and services."
      />
      <Wrapper>
        <TitleContainer>
          <Title as="h1">Dashboard</Title>
        </TitleContainer>
        <Consumer>
          {({ state, dispatch }) => {
            return (
              <div className="px-8 xl:p-0">
                <Title as="h2" variant="subheading">
                  Currently under construction but check back soon!
                </Title>
                <Copy marginBottom="0.6rem" size="lg">
                  Thanks for signing up {state.username},
                </Copy>
                <Copy marginBottom="0.6rem" size="lg">
                  we can&apos;t understate how excited we are to have you
                  onboard. In the coming weeks Trace is planning on releasing
                  it&apos;s first wave of products and we can&apos;t wait to
                  share them with you.
                </Copy>
              </div>
            );
          }}
        </Consumer>
      </Wrapper>
    </>
  );
};

// DashboardPage.propTypes = {}

export default DashboardPage;
