import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';

import Button from '../../Elements/Button';
import PasswordInput from '../../MultiForm/parts/PasswordInput';
import EmailInput from '../../MultiForm/parts/EmailInput';

import HttpService from '../../../services/HttpService';

const http = new HttpService();

const ResendVerification = ({ email }) => {
  const [isSent, setIsSent] = useState(false);
  const handleResetRequest = () => {
    http.resendVerificationRequest(email, () => {
      setIsSent(true);
    });
  };
  return (
    <span className="text-red-500">
      The email
      {' '}
      <strong>{email}</strong>
      {' '}
      has not yet been verified.
      <br />
      <br />
      {!isSent && (
        <>
          Please check your email or
          {' '}
          <Button
            className="text-red-500 underline ml-0 pl-0"
            type="link"
            onClickHandler={handleResetRequest}
            color="transparent"
          >
            click here to re-send verification message.
          </Button>
        </>
      )}
      {isSent && (
        <>
          Email verification has been re-sent. Please check your email and
          follow the instructions in the verification email.
        </>
      )}
    </span>
  );
};

ResendVerification.propTypes = {
  email: PropTypes.string.isRequired,
};

/**
 * Login Form
 *
 * Uses the `useFormState` package for handling form state.
 */
const Login = ({
  handleSubmit,
  loginResponse,
  invertColor,
  submitBtnColor,
  displayForgotPassword,
  forgotPasswordPath,
  isSubmitPending,
  LinkComponent,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [formState, { email, password }] = useFormState({});

  // Checks if any of our fields contain invalid values.
  const isValid = Object.keys(formState.validity)
    .map(field => formState.validity[field])
    .every(value => value === true)
    && Object.keys(formState.validity).length === 2;

  const passwordInvalid = !!formState.values
    && !!formState.values.password
    && !formState.validity.password;

  const updateField = (name, value) => {
    formState.setField(name, value);
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event, formState.values);
      }}
    >
      <div className="my-6">
        <EmailInput
          label="Email"
          name="email"
          type="email"
          invertColor={invertColor}
          required
          placeholder="Enter your email"
          value={formState.values.email}
          updateValueAndError={updateField}
        />
      </div>

      <div className="my-6">
        <PasswordInput
          error={passwordInvalid ? 'Invalid Password' : ''}
          label="Password"
          name="password"
          invertColor={invertColor}
          required
          placeholder="Enter your password"
          value={formState.values.password}
          updateValueAndError={updateField}
        />
      </div>
      <div>
        {loginResponse
          && loginResponse !== 'User has not verified their email address' && (
            <span className="text-red-500">{loginResponse}</span>
        )}
        {loginResponse
          && loginResponse === 'User has not verified their email address' && (
            <ResendVerification email={formState.values.email} />
        )}
      </div>

      <div className="my-8">
        <div className="flex flex-row items-center">
          <Button
            type="submit"
            color={submitBtnColor}
            size="xl"
            icon={isSubmitPending ? 'loader' : ''}
            iconMargin="3"
            className="uppercase"
            isDisabled={!isValid || isSubmitPending}
          >
            {!isSubmitPending ? 'Login' : null}
          </Button>

          {LinkComponent && forgotPasswordPath && displayForgotPassword && (
            <LinkComponent
              className="ml-6 text-sm hover:text-gray-700"
              to={forgotPasswordPath}
            >
              Forgot Password?
            </LinkComponent>
          )}
        </div>
      </div>
    </form>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loginResponse: PropTypes.string,
  invertColor: PropTypes.bool,
  submitBtnColor: PropTypes.string,
  displayForgotPassword: PropTypes.bool,
  isSubmitPending: PropTypes.bool,
  forgotPasswordPath: PropTypes.string,
  LinkComponent: PropTypes.func,
};

Login.defaultProps = {
  loginResponse: null,
  invertColor: false,
  submitBtnColor: 'gold',
  displayForgotPassword: true,
  isSubmitPending: false,
  forgotPasswordPath: '/login/',
  LinkComponent: undefined,
};

export default Login;
