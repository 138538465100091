import InputBase from './InputBase';

class TextInput extends InputBase {}

TextInput.defaultProps = {
  type: 'text',
  validator: 'default',
  updateOnUnmount: true,
};

export default TextInput;
