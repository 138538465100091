import InputBase from './InputBase';

class EmailInput extends InputBase {}

EmailInput.defaultProps = {
  validator: 'email',
  icon: 'email',
};

export default EmailInput;
