import React from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import Copy from '../Copy';

const Card = ({
  title,
  titleProps,
  children,
  className,
  contentProps,
  ...containerProps
}) => (
  <div className={`flex flex-col border rounded p-3 ${className && className}`} {...containerProps}>
    <Title {...titleProps}>
      {title}
    </Title>
    {children && (
      <Copy {...contentProps}>
        {children}
      </Copy>
    )}
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  titleProps: PropTypes.shape({}),
  contentProps: PropTypes.shape({}),
};

Card.defaultProps = {
  className: '',
  titleProps: {
    as: 'h4',
    fontSize: 'xl',
    className: 'text-right',
  },
  contentProps: {},
  children: false,
};

export default Card;
