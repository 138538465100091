module.exports = {
  transparent: 'transparent',

  white: '#fff',
  black: '#000',
  offblack: '#050810',
  traceblack: '#211F1E',
  darkbrown: '#2A2827',
  nomad: '#E5E2DA',
  redblack: '#191615',
  peppercorn: '#514c4a',
  warmgray: '#B8B09C',
  navy: '#10151D',

  gray: {
    100: '#f6f6f6',
    200: '#edeef0',
    300: '#dfe1e4',
    400: '#cbd5e0',
    500: '#9da7b7',
    600: '#65728a',
    700: '#555555',
    800: '#2d3748',
    900: '#1a202c',
  },
  red: {
    100: '#edabac',
    200: '#e99697',
    300: '#e48182',
    400: '#dc5725',
    500: '#C84E20',
    600: '#d74243',
    700: '#d32d2e',
    800: '#9b2c2c',
    900: '#742a2a',
  },
  gold: {
    100: '#dccab2',
    200: '#d4bea0',
    300: '#ccb28f',
    400: '#C4A67D',
    500: '#BD945B',
    600: '#b58849',
    700: '#b7791f',
    800: '#9E7741',
    900: '#916d3a',
  },
  green: {
    100: '#c5d9c9',
    200: '#a8d1b6',
    300: '#a6c5ad',
    400: '#82ae8c',
    500: '#649970',
    600: '#5a8a65',
    700: '#507b5a',
    800: '#466b4e',
    900: '#3c5c43',
  },

  primary: {
    default: '#BD945B',
    hover: '#C4A67D',
    focus: '#b58849',
  },
  success: {
    default: '#649970',
    hover: '#82ae8c',
    focus: '#5a8a65',
  },
  pending: {
    default: '#65728a',
    hover: '#9da7b7',
    focus: '#555555',
  },
  error: {
    default: '#C84E20',
    hover: '#dc5725',
    focus: '#d74243',
  },
  info: {
    default: '#f6f6f6',
    hover: '#edeef0',
    focus: '#ededed',
  },

  dark_bg: '#211F1E',
  darker_bg: '#191615',

  light_bg: '#E5E2DA',
  lighter_bg: '#fefefe',
};
