import React from 'react';
import PropTypes from 'prop-types';

import { StepperContainer } from './ProgressStepper.css';

const ProgressStepper = ({
  steps,
  activeStep,
  type,
  onClickHandler,
  containerClassName,
}) => (
  <StepperContainer direction={type} className={containerClassName}>
    <ul className="step-bullets">
      {steps.map((step, index) => (
        // TODO: Refactor to use a11y compatible elements here.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={`bullet-${index.toString()}`}
          aria-label={`Step ${index.toString()}: ${step.title}`}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onClick={(event) => {
            if (onClickHandler) {
              onClickHandler(index, event);
            }
          }}
          title={step.title}
          className={`h-4 w-4 rounded-full centered inline-block relative leading-loose bg-green-400 mt-12 ml-12 hover:cursor-pointer ${activeStep.toString() === (index).toString() ? 'active' : ''}`}
        />
      ))}
    </ul>

    <ul className="step-label">
      {steps.map((step, index) => (
        // TODO: Refactor to use a11y compatible elements here.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={`bullet-${index.toString()}`}
          aria-label={`Step ${index.toString()}: ${step.title}`}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onClick={(event) => {
            if (onClickHandler) {
              onClickHandler(index, event);
            }
          }}
          className={`list-none leading-loose font-medium ${activeStep.toString() === (index).toString() ? 'active' : ''}`}
        >
          {step.label}
        </li>
      ))}
    </ul>
  </StepperContainer>
);

ProgressStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  activeStep: PropTypes.number,
  type: PropTypes.oneOf(['horizontal', 'vertical']),
  onClickHandler: PropTypes.func,
  containerClassName: PropTypes.string,
};

ProgressStepper.defaultProps = {
  type: 'horizontal',
  activeStep: 0,
  containerClassName: 'mb-6',
  onClickHandler: (index, event) => {
    console.log(`Default onClickHandler for Progress Stepper, Step: ${index}`, event);
  },
};

export default ProgressStepper;
