import React from 'react';
import PropTypes from 'prop-types';

import { stringifyDate } from '../../../utils/date-time/utils';

// I was getting annoyed with all the various ways that time was getting handled
const Timestamp = ({ date, format }) => (
  <React.Fragment>
    {stringifyDate(date, format)}
  </React.Fragment>
);

Timestamp.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.oneOf(
    [
      'yyyy',
      'mm/dd/yyyy',
      'mmmmm dd, yyyy',
      'mm/dd/yyyy, hh:MM AM',
      'm dd, hh:MM AM',
    ],
  ),
};

Timestamp.defaultProps = {
  format: 'mm/dd/yyyy',
};

export default Timestamp;
