import React from 'react';
import { Link, navigate } from 'gatsby';

import Head from 'components/head';
import { PageLoader } from 'core/src/components/Elements';
import Login from 'core/src/components/Forms/Login';
// import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Wrapper from '~v2/elements/container/wrapper';
import Copy from '~v2/elements/copy';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';

const LoginPage = () => {
  return (
    <>
      <Head
        pageTitle="User Login Page"
        pageDescription="The Trace team never sleeps. Check out our blog to stay apprised of how we’re elevating the hemp industry."
      />
      <div className="bg-gray-100">
        <Wrapper>
          <div id="LoginForm" className="w-full bg-gray-100 py-16">
            <div className="w-full lg:w-2/3 flex flex-col self-center text-black">
              <div className="py-2">
                <TitleContainer>
                  <Title as="h1" variant="subheading">
                    Login to Trace
                  </Title>
                </TitleContainer>
                <div className="px-8 xl:px-0">
                  <Copy size="lg">
                    Login using your Trace account credentials or{' '}
                    <Link to="/app/register/">
                      register a new account here.
                    </Link>
                  </Copy>
                </div>
              </div>
              <div className="px-8 xl:px-0">
                <Consumer>
                  {({ state, dispatch }) => {
                    const isPending = state.type === 'awaitingAuth';
                    let onlyOnce = true;

                    return (
                      <>
                        {isPending ? (
                          <PageLoader />
                        ) : state.authToken ? (
                          navigate('/app/')
                        ) : (
                          <Login
                            handleSubmit={(event, form) => {
                              if (onlyOnce) {
                                event.preventDefault();
                                const { email, password } = form;

                                dispatch({
                                  type: 'loginUser',
                                  creds: { email, password },
                                });
                              }
                              onlyOnce = false;
                            }}
                            loginResponse={state.authError || ''}
                            submitBtnColor="green"
                            forgotPasswordPath="/app/forgot-password/"
                            LinkComponent={Link}
                          />
                        )}
                      </>
                    );
                  }}
                </Consumer>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

// LoginPage.propTypes = {
//   onLoginSubmit: PropTypes.func.isRequired,
//   loginError: PropTypes.string
// }

export default LoginPage;
