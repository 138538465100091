const outdoorAcreageToFee = (acreage = 0) => {
  const val = parseFloat(acreage);
  // Sum of acres for outdoor:
  // -- If less than 0.5 acres: $100
  // -- If greater than or equal to 0.5 acres and less than or equal to 9.9 acres: $500
  // -- If greater than or equal to 10.0 acres and less than 50 acres: $1000.00
  // -- If greater than or equal to 50 acres $3000
  if (val === 0 || !acreage) {
    return 0;
  }

  if (val < 0.5) {
    return 100;
  }

  if (val >= 0.5 && val < 10) {
    return 500;
  }

  if (val >= 10 && val <= 50) {
    return 1000;
  }
  return 3000;
};

const indoorSquareFootageToFee = (footage = 0) => {
  const val = parseFloat(footage);

  // If square footage for indoor cultivation is
  // -- Less than or equal to 500 sqft: $1000
  // -- If greater than 500 sqft: $2000
  if (val === 0 || !footage) {
    return 0;
  }
  if (val <= 500) {
    return 1000;
  }
  return 2000;
};

const processingFeeByVolume = (volume = 0) => {
  const val = parseFloat(volume);
  // Estimate of pounds processed:
  // -- Less than 500 pounds: $100
  // -- Greater than or equal to 500 pounds, less than 10000 pounds: $500
  // -- Greater than or equal to 10000 pounds, less than 50000 pounds: $1000
  // -- Greater than or equal to 50000 pounds: $3000
  if (val === 0 || !volume) {
    return 0;
  }
  if (val < 500) {
    return 100;
  }
  if (val >= 500 && val < 10000) {
    return 500;
  }
  if (val >= 10000 && val <= 50000) {
    return 1000;
  }
  return 3000;
};

// intakes a full permit submission and attempts to return a price
export const calculateVermontPrice = (formData) => {
  // Grower Registration Fees:
  // Personal use only (exclusive category, single type registration).
  // TODO -- this is unhandledPersonal use must be less than 0.5 acres. $25 (flat fee)

  // eslint-disable-next-line camelcase
  const { Commercial_or_Personal } = formData;
  // eslint-disable-next-line camelcase
  if (Commercial_or_Personal && Commercial_or_Personal.value === 'Personal') {
    return 25;
  }
  // Commercial use
  // Grain and/or fiber cultivation
  // (if exclusive, if used in conjunction use other feestructures):
  //                                      $100 (flat fee, regardless of acreage)
  //
  // eslint-disable-next-line camelcase
  const { Commercial_Use } = formData;
  // eslint-disable-next-line camelcase
  if (Commercial_Use && Commercial_Use.value === 'Grain_Fiber') {
    return 100;
  }

  const {
    // eslint-disable-next-line camelcase
    Outdoor_Area_of_Cultivation,
    // eslint-disable-next-line camelcase
    Indoor_Area_of_Cultivation,
    // eslint-disable-next-line camelcase
    Processor_Lbs_Floral_Material,
  } = formData;


  // eslint-disable-next-line camelcase
  const processingFee = processingFeeByVolume((Processor_Lbs_Floral_Material || {}).value);

  // eslint-disable-next-line camelcase
  const outdoorFee = outdoorAcreageToFee((Outdoor_Area_of_Cultivation || {}).value);

  // eslint-disable-next-line camelcase
  const indoorFee = indoorSquareFootageToFee((Indoor_Area_of_Cultivation || {}).value);


  // If we are growing we can also be processing so lets do all the things
  if (outdoorFee > 0 || indoorFee > 0) {
    // Compare (TOTAL_OUTDOOR_ACRES) to (TOTAL_POUNDS_ANNUAL_ONFARM), charge higher fee.
    const clampedOutdoorGrowingOrProcessingFee = outdoorFee > processingFee
      ? outdoorFee
      : processingFee;


    // Max fee for grower cannot exceed $5100
    let growerFee = clampedOutdoorGrowingOrProcessingFee + indoorFee;

    // eslint-disable-next-line camelcase
    if (Commercial_Use && Commercial_Use.value === 'Both') {
      growerFee += 100;
    }

    if (growerFee > 5100) {
      growerFee = 5100;
    }

    return growerFee;
  }

  if (processingFee > 0) {
    return processingFee;
  }

  return 0;
};
