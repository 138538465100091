import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonIcon from './ButtonIcon';

import { getButtonColorConfig, sizeClasses } from './utils';

const Button = ({
  color,
  variant,
  isPillShape,
  isSquareShape,
  style,
  size,
  fontWeight,
  type,
  className,
  innerClassName,
  onClickHandler,
  children,
  icon,
  iconFirst,
  iconMargin,
  iconSize,
  to,
  LinkComponent,
  isDisabled,
}) => {
  const colorConfig = getButtonColorConfig(color, variant);
  let common = `font-${fontWeight} font-body hover:no-underline disabled:cursor-not-allowed`;

  if (isPillShape) {
    common = `${common} rounded-full`;
  } else if (isSquareShape) {
    common = `${common} rounded-none`;
  } else {
    common = `${common} rounded`;
  }

  // Internal link.
  if (type === 'link') {
    return (
      <React.Fragment>
        {LinkComponent ? (
          <LinkComponent
            className={`${common} ${sizeClasses[size]} ${colorConfig.button} ${className}`}
            to={to || '/'}
            style={{ ...style }}
          >
            <span className={`${innerClassName} ${icon ? 'flex flex-row items-center' : ''}`}>
              {children}

              <ButtonIcon
                icon={icon}
                iconFirst={iconFirst}
                iconMargin={iconMargin}
                iconSize={iconSize}
                iconColor={colorConfig.icon}
                className="flex items-center"
              />
            </span>
          </LinkComponent>
        ) : (
          <Link
            className={`${common} ${sizeClasses[size]} ${colorConfig.button} ${className}`}
            to={to || '/'}
            style={{ ...style }}
          >
            <span className={`${innerClassName} ${icon ? 'flex flex-row items-center' : ''}`}>
              {children}

              <ButtonIcon
                icon={icon}
                iconFirst={iconFirst}
                iconMargin={iconMargin}
                iconSize={iconSize}
                iconColor={colorConfig.icon}
                className="flex items-center"
              />
            </span>
          </Link>
        )}
      </React.Fragment>
    );
  }

  // External link.
  if (type === 'external') {
    return (
      <React.Fragment>
        <a
          className={`${common} ${sizeClasses[size]} ${colorConfig.button} ${className}`}
          href={to}
          style={{ ...style }}
        >
          <span className={`${innerClassName} ${icon ? 'flex flex-row items-center' : ''}`}>
            {children}

            <ButtonIcon
              icon={icon}
              iconFirst={iconFirst}
              iconMargin={iconMargin}
              iconSize={iconSize}
              iconColor={colorConfig.icon}
              className="flex items-center"
            />
          </span>
        </a>
      </React.Fragment>
    );
  }

  // Default button type.
  return (
    <button
      type="button"
      className={`${common} ${sizeClasses[size]} ${colorConfig.button} ${className}`}
      onClick={onClickHandler}
      style={{ ...style }}
      disabled={isDisabled}
      {... { type: type || 'button' }} // Workaround for 'submit' buttons to appease eslint.
    >
      <span className={`${innerClassName} ${icon ? 'flex flex-row items-center' : ''}`}>
        {children}

        <ButtonIcon
          icon={icon}
          iconFirst={iconFirst}
          iconMargin={iconMargin}
          iconSize={iconSize}
          iconColor={colorConfig.icon}
          className="flex items-center"
        />
      </span>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isPillShape: PropTypes.bool,
  isSquareShape: PropTypes.bool,
  iconFirst: PropTypes.bool,
  iconMargin: PropTypes.string,
  iconSize: PropTypes.oneOf(['xs', 'sm', 'base', 'md', 'lg', 'xl']),
  color: PropTypes.oneOf(['white', 'graygold', 'gold', 'green', 'red', 'black', 'lgray', 'gray', 'dgray', 'transparent', 'peppercorn', 'custom']),
  variant: PropTypes.oneOf(['solid', 'outline']),
  fontWeight: PropTypes.oneOf(['hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
  to: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  size: PropTypes.oneOf(['icon', 'xs', 'sm', 'base', 'md', 'lg', 'xl']),
  type: PropTypes.oneOf(['link', 'button', 'submit', 'external']),
  onClickHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  LinkComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.shape({})]),
};

Button.defaultProps = {
  color: 'gold',
  type: 'button',
  variant: 'solid',
  isPillShape: false,
  isSquareShape: false,
  style: {},
  size: 'base',
  fontWeight: 'normal',
  className: 'inline-block',
  innerClassName: '',
  onClickHandler: () => {
    console.log('default button click handler');
  },
  children: null,
  icon: null,
  iconFirst: false,
  iconMargin: '3',
  iconSize: 'base',
  to: '',
  isDisabled: false,
  LinkComponent: false,
};

export default Button;
