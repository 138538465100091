import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

const GoogleMap = ({ children, ...props }) => {
  const createMapOptions = maps => ({
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    fullscreenControl: false,
    enableCloseButton: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: ['satellite', 'roadmap'],
      style: maps.MapTypeControlStyle.DROPDOWN_MENU,
    },
  });


  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: ['places', 'drawing', 'geometry'],
        }}
        yesIWantToUseGoogleMapApiInternals
        options={createMapOptions}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  );
};

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
