import React from 'react';
import PropTypes from 'prop-types';

import { Header1, Header2, Header3 } from 'constants/typography';

const Title = ({
  children,
  as = 'h2',
  variant = 'heading',
  fontSize = '',
  className = false,
  textIndent = '',
  innerHtml = false,
}) => {
  return (
    <>
      {innerHtml ? (
        <>
          {as === 'h1' && (
            <Header1
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          )}

          {as === 'h2' && (
            <Header2
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          )}

          {as === 'h3' && (
            <Header3
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          )}
        </>
      ) : (
        <>
          {as === 'h1' && (
            <Header1
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
            >
              {children}
            </Header1>
          )}

          {as === 'h2' && (
            <Header2
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
            >
              {children}
            </Header2>
          )}

          {as === 'h3' && (
            <Header3
              variant={variant}
              fontSize={fontSize}
              className={className}
              textIndent={textIndent}
            >
              {children}
            </Header3>
          )}
        </>
      )}
    </>
  );
};

Title.propTypes = {
  as: PropTypes.string,
  textIndent: PropTypes.string,
  variant: PropTypes.oneOf(['heading', 'subheading']),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  innerHtml: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Title;
