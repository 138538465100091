// Extracts all field parts from multiform JSON 'steps' object into flat object.
// We're removing any static `text` fields from resulting object as well.
export function extractFieldsFromConfig(config) {
  const {
    steps,
  } = config;

  return steps.reduce((accum, formStep) => ({
    ...accum,
    ...formStep.parts.reduce((fields, field) => ({
      ...fields,
      ...(field.type === 'text' ? {} : { [field.name]: field }),
    }), {}),
  }), {});
}

// TODO remove this once we have converted the rest of the system overflow
export function combineFormDataStructures(legacy, extractedFromConfig) {
  const baseKeys = Object.keys(legacy);
  for (let i = 0; i < baseKeys.length; i += 1) {
    if (extractedFromConfig[baseKeys[i]]) {
      // eslint-disable-next-line no-param-reassign
      extractedFromConfig[baseKeys[i]].value = legacy[baseKeys[i]];
    }
  }
  return extractedFromConfig;
}

// TODO remove this once we have converted the rest of the system overflow
export function convertExtractedFormatToLegacy(extractedFormData) {
  const baseKeys = Object.keys(extractedFormData);
  const returnObj = {};

  for (let i = 0; i < baseKeys.length; i += 1) {
    returnObj[baseKeys[i]] = extractedFormData[baseKeys[i]].value;
  }
  return (returnObj);
}

export function convertExtractedFormatToSchema(extractedFormData) {
  const selectFields = ({
    name, value, required, type,
  }) => ({
    name, value, required, type,
  });
  const removeNulls = (fields) => {
    for (let i = 0; i < Object.keys(fields).length; i += 1) {
      const fieldName = Object.keys(fields)[i];
      if (fields[fieldName] === undefined || fields[fieldName] === null) {
        // eslint-disable-next-line no-param-reassign
        delete fields[fieldName];
      }
    }
    return (fields);
  };
  return (
    Object.values(extractedFormData)
      .map(selectFields)
      .map(removeNulls)
  );
}
