import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import InputBase from './InputBase';
import InputWrapper from './InputWrapper';

import colors from '../../../configs/tailwind/colors';

class SelectDropdownInput extends InputBase {
  render() {
    const {
      name,
      value,
      label,
      error,
      updateValueAndError = () => true,
      options,
      padding = '.5em',
    } = this.props;

    const customStyles = {
      input: provided => ({
        ...provided,
        borderColor: colors.gray['400'],
        color: error ? 'red' : colors.gold['800'],
        padding,
      }),
      control: (provided, state) => ({
        ...provided,
        '&:hover': { borderColor: colors.gray['400'] }, // border style on hover
        borderColor: state.isFocused ? colors.gold['500'] : colors.gray['400'],
        padding: '0 0.25em',
        boxShadow: 'none',
      }),
      placeholder: provided => ({
        ...provided,
        color: colors.gold['500'],
        fontWeight: 300,
        opacity: 0.5,
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#FFF' : colors.black,
        backgroundColor: state.isSelected ? colors.gold['800'] : colors.white,
        '&:hover': {
          color: colors.white,
          backgroundColor: colors.gold['400'],
        },
        padding: '0.75em',
        fontWeight: 300,
      }),
      singleValue: provided => ({
        ...provided,
        color: error ? 'red' : colors.gray['900'],
        // '&:hover': { backgroundColor: colors.gold['400'] }, // border style on hover
        fontWeight: 300,
      }),
    };

    const defaultValue = value ? options.reduce((accum, option) => {
      if (accum === false) {
        return (option.value === value ? option : false);
      }
      return accum;
    }, false) : '';

    return (
      <InputWrapper error={error} label={label} iconProps={{ icon: false }} {...this.props}>
        <div className="select w-full">
          <Select
            styles={customStyles}
            options={options}
            defaultValue={defaultValue}
            onChange={(item) => {
              updateValueAndError(name, item.value, null);
            }}
          />
        </div>
      </InputWrapper>
    );
  }
}

SelectDropdownInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  updateValueAndError: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
};

export default SelectDropdownInput;
