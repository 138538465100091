import styled from 'styled-components';

import colors from '../../../configs/tailwind/colors';

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction !== 'vertical' ? 'column' : 'row')};

  li {
    cursor: pointer;
    
    &.active {
      cursor: auto;
    }

  }

  .step-bullets {
    display: flex;
    flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};

    li::before{
      content: '';
      position: absolute;
      background: ${colors.green['500']};
      z-index: 99999;


      &:hover {
        background: ${colors.green['300']};
      }

      ${({ direction }) => {
    if (direction === 'vertical') {
      return `
            left: 38%;
            top: -3em;
            width: 0.2em;
            height: 3em;
          `;
    }
    return `
            top: 38%;
            width: 3em;
            left: -3rem;
            height: 0.2em;
          `;
  }};
    }

    li:first-child {
      ${({ direction }) => (direction === 'vertical' ? 'margin-top: 0.5rem;' : 'margin-left: 0.25rem')};
      

      &::before {
        display: none;
      }
    }

    .active {
      background: ${colors.green['400']};

      & ~ li {
        background: ${colors.gray['900']};

        &::before {
          background: ${colors.gray['900']};
        }

        
        &:hover {
          background: ${colors.green['300']};
        }
      }
    }
  }

  .step-label {
    li {
      color: ${colors.green['500']};
      display: ${({ direction }) => (direction === 'vertical' ? 'block' : 'inline-block')};
      margin-top: ${({ direction }) => (direction === 'vertical' ? '2rem' : '0.5rem')};
      margin-left: ${({ direction }) => (direction === 'vertical' ? '1.2rem' : '3.4rem')};

      &:first-child {
        margin-top: 0;
        ${({ direction }) => (direction !== 'vertical' ? 'margin-left: 0.5rem;' : '')};

      }
    }

    .active {
      color: ${colors.green['500']};

      & ~ li {
        color: #ccc;

        &::before {
          color: #ccc;
        }
      }
    }
  }
`;
