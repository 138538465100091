import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'constants/typography';

const Copy = ({ children, variant, size, fontColor, marginBottom }) => {
  return (
    <Paragraph
      variant={variant}
      size={size}
      fontColor={fontColor}
      marginBottom={marginBottom}
    >
      {children}
    </Paragraph>
  );
};

Copy.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fontColor: PropTypes.string,
  marginBottom: PropTypes.string,
};

export default Copy;
