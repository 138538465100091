import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Head from 'components/head';
import Wrapper from '~v2/elements/container/wrapper';
import Copy from '~v2/elements/copy';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import { Pending } from 'core/src/components/Elements';
import { colors } from 'constants/theme';
import RequestResetForm from 'core/src/components/Forms/RequestReset';

import { requestPasswordReset } from 'store/traceAPI';

const RequestPasswordResetPage = () => {
  const [formState, setStatus] = useState({
    status: 'init',
    result: undefined,
  });

  return (
    <>
      <Head
        pageTitle="User Login Page"
        pageDescription="The Trace team never sleeps. Check out our blog to stay apprised of how we’re elevating the hemp industry."
      />
      <div className="bg-gray-100">
        <Wrapper>
          <div id="LoginForm" className="w-full py-16">
            <div className="w-full lg:w-2/3 flex flex-col self-center text-black">
              <div className="py-2">
                <TitleContainer>
                  <Title as="h1" variant="subheading">
                    {formState.status === 'complete'
                      ? 'Thanks!'
                      : 'Forgot Password?'}
                  </Title>
                </TitleContainer>
                <div className="px-8 xl:px-0">
                  <Copy size="lg">
                    We’ll send you an email with a link to reset your password.
                    If you want to try logging in again, return to{' '}
                    <Link
                      className="underline hover:no-underline font-bold hover:text-gold-400"
                      to="/app/login"
                    >
                      Login
                    </Link>
                    .
                  </Copy>
                  <Copy size="lg">
                    If you&apos;d like to create a new account or team,{' '}
                    <Link
                      className="underline hover:no-underline font-bold hover:text-gold-400"
                      to="/app/register/"
                    >
                      Register here
                    </Link>{' '}
                    .
                  </Copy>
                </div>
              </div>
              <div className="px-8 xl:px-0">
                {formState.status === 'complete' ? (
                  <div className="text-left">
                    <Copy size="md" fontColor={colors.green}>
                      A password reset email has been sent to the address
                      requested.
                      <br /> Please check your email and follow the steps
                      provided.
                    </Copy>
                  </div>
                ) : (
                  <RequestResetForm
                    handleSubmit={email => {
                      setStatus({
                        ...formState,
                        status: 'pending',
                      });

                      requestPasswordReset(email).then(result => {
                        console.log('Request Reset Result', result);
                        setStatus({
                          status: 'complete',
                          result,
                        });
                      });
                    }}
                    loginPath="/app/login"
                    errorMessage={formState.result}
                    isSubmitPending={formState.status === 'pending'}
                    LinkComponent={Link}
                  />
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

RequestPasswordResetPage.propTypes = {
  loginError: PropTypes.string,
  http: PropTypes.object,
};

export default RequestPasswordResetPage;
