import React from 'react';
import PropTypes from 'prop-types';

import DataService from '../../../services/DataService';
import HttpService from '../../../services/HttpService';

import NotificationService, { NOTIFICATION_NEW_STATE_ROLES } from '../../../services/NotificationService';

import InputBase from './InputBase';
import SelectDropdownInput from './SelectDropdownInput';

const ds = new DataService();
const http = new HttpService();
const ns = new NotificationService();

const transformStateRolesForSelectInput = roles => (roles.map(item => ({
  label: item.label,
  value: item.identifier,
})));

class StateRoleSelectDropdownInput extends InputBase {
  constructor(props) {
    super(props);
    const stateRoles = ds.getStateRoles();

    // Load roles if not already in local storage.
    if (stateRoles.length === 0) {
      http.retrieveStateRoles();
    }

    this.state = {
      roles: (stateRoles ? transformStateRolesForSelectInput(stateRoles) : [{ label: 'Loading...', value: false }]),
    };
    this.receiveRoles = this.receiveRoles.bind(this);
    ns.addObserver(NOTIFICATION_NEW_STATE_ROLES, this, this.receiveRoles, 'StateRoleSelectDropdownInput');
  }

  componentWillUnmount() {
    ns.removeObserver(NOTIFICATION_NEW_STATE_ROLES, this);
  }

  receiveRoles(roles) {
    this.setState({ roles: transformStateRolesForSelectInput(roles) });
  }

  render() {
    return (<SelectDropdownInput options={this.state.roles} {...this.props} />);
  }
}

StateRoleSelectDropdownInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
    }),
  ),
};

export default StateRoleSelectDropdownInput;
