import React from 'react';
import PropTypes from 'prop-types';

const Title = ({
  children,
  as = 'h3',
  font = 'header',
  fontSize = 'xl',
  fontWeight = 'light',
  className = false,
}) => {
  const Header = React.createElement(
    as,
    {
      className: `font-${font} text-${fontSize} font-${fontWeight} ${className}`,
    },
    children,
  );

  return Header;
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  font: PropTypes.oneOf(['header', 'base']),
  fontSize: PropTypes.oneOf(['xs', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']),
  fontWeight: PropTypes.oneOf(['hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  textIndent: PropTypes.string,
};

export default Title;
