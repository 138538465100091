import React from 'react';
import PropTypes from 'prop-types';

import SimpleCheckbox from '../parts/SimpleCheckbox';

const AttestationsReview = (value, field) => (
  <>
    {field.options.map(opt => (
      <SimpleCheckbox
        key={opt.label}
        name={opt.label}
        value={opt.label}
        checked={value}
        updateValueAndError={() => {}}
        onChange={() => {}}
      />
    ))}
  </>
);

AttestationsReview.propTypes = {
  value: PropTypes.shape({}).isRequired,
  field: PropTypes.shape({}).isRequired,
};

export default AttestationsReview;
