import validator from 'validator';
import { extractFieldsFromConfig } from './field-helpers';
import { typeOf } from '../../../utils/helpers';

// TODO: Complete all the function docblocks in this file.

// TODO: Update this to use individual imports for each validator function.
export const fieldConfigs = {
  /**
   * Default Validation
   */
  default: {
    // eslint-disable-next-line no-unused-vars
    validate: input => true,
    errorMessage: 'Invalid Input',
    requiredErrorMessage: 'This field is required.',
  },

  /**
   * Email Validation
   */
  email: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }

      return validator.isEmail(input);
    },
    errorMessage: 'Invalid Email',
  },
  zipcode: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }
      const re = /^[0-9]{5}$/;
      return re.test(String(input).toLowerCase());
    },
    errorMessage: 'Invalid Zip Code. Use format "01234"',
  },
  /**
   * Domain Validation
   */
  domain: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }

      return (validator.isURL(input) || validator.isFQDN(input));
    },
    errorMessage: 'Invalid domain name.',
  },

  /**
   * Lat/Long Validation
   */
  latlng: {
    validate: (input = '') => {
      // TODO: Implement validators here.  This may change when we change site input.
      const re = /^-?[0-9]{2,3}\.[0-9]{4,6}$/;
      return re.test(String(input).toLowerCase());
    },
    errorMessage: 'Must take the form DDD.DDDD or -DDD.DDDD',
  },

  /**
   * Numerical Validation
   */
  number: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }

      return validator.isNumeric(input);
    },
    errorMessage: 'Value must be a number',
  },

  /**
   * Numerical Validation
   */
  max_point_four_nine_numeric_input: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }

      return validator.isNumeric(input) && parseFloat(input) >= 0.49;
    },
    errorMessage: 'Value must be a number and must be 0.49 or less',
  },

  /**
   * Phone Validation
   *
   * TODO: Add length requirement.
   */
  phone: {
    validate: (input = '') => {
      if (!['string', 'number'].includes(typeOf(input))) {
        return false;
      }

      return validator.isMobilePhone(input, 'en-US');
    },
    errorMessage: 'Please enter valid US based phone number.',
  },

  /**
   * Password Validation
   *
   * TODO: Add length requirement.
   */
  password: {
    validate: (input = '') => input !== undefined,
    errorMessage: 'Password required.',
  },

  passwordAndConfirmation: {
    validate: (input = '') => {
      const isValid = (
        (input.password === undefined && input.confirmation === undefined)
        || (input.password === input.confirmation && input.password.length >= 8)
      );
      return isValid;
    },
    errorMessage: 'Minimum length: 8 characters. Password and confirmation must match',
  },

  /**
   * Vermont Payment Widget
   *
   */
  vt_payment: {
    validate: (input = {}) => input.agreedToTerms,
    errorMessage: 'Must agree to terms before submitting',
  },

  /**
   * Sites Mapper Inputs
   *
   */
  sites: {
    validate: (input) => {
      let isValid = true;
      // Loop over array of sites and determine if they contain errors.
      for (let siteIndex = 0; siteIndex <= input.length; siteIndex += 1) {
        const site = input[siteIndex] || {};

        if ('errors' in site) {
          const fieldKeys = Object.keys(site.errors);
          const errorValues = Object.values(site.errors);

          for (let errorIndex = 0; errorIndex < errorValues.length; errorIndex += 1) {
            const errorKey = fieldKeys[errorIndex];
            const errorValue = site.errors[errorKey];

            if (errorKey === 'mapNodes' && typeOf(errorValue) === 'array' && errorValue.length !== 0) {
              isValid = false;
            } else if (errorKey !== 'mapNodes' && errorValue === undefined) {
              // Check if empty field is required.
              if (errorKey in ['siteName']) {
                isValid = false;
              }
            }
          }
        }
      }
      return isValid;
    },
    errorMessage: 'Missing required information, please return to site mapper to fix verify',
  },

  /**
   * attestation validations
   */
  attestation: {
    validate: input => (input === true),
    errorMessage: 'Must agree to terms before approval',
  },
};

/**
 *
 *
 * @export
 * @param {string} [validatorType='default']
 * @returns
 */
export function getValidatorConfig(validatorType = 'default') {
  return fieldConfigs[validatorType] ? fieldConfigs[validatorType] : fieldConfigs.default;
}

/**
 *
 *
 * @export
 * @param {string} [validatorType='default']
 * @returns
 */
export function getValidateErrorMsg(validatorType = 'default') {
  return fieldConfigs[validatorType] ? fieldConfigs[validatorType].errorMessage
    : fieldConfigs.default.errorMessage;
}

/**
 *
 *
 * @export
 * @param {string} [validatorType='default']
 * @returns
 */
export function getValidateRequiredMsg(validatorType = 'default') {
  return (fieldConfigs[validatorType] && fieldConfigs[validatorType].requiredErrorMessage)
    ? fieldConfigs[validatorType].requiredErrorMessage
    : fieldConfigs.default.requiredErrorMessage;
}

/**
 *
 *
 * @export
 * @param {string} [type='default']
 * @param {*} value
 * @returns
 */
export function validateInput(
  type = 'default',
  value = '',
  isRequired = false,
  returnError = false,
) {
  const config = getValidatorConfig(type);

  // console.log(`validating ${type}, isReq: ${isRequired}`, value, config);

  // useful for tracking down a specific value, just change the name here
  // if (value.name === 'Applicant_First_Name') {
  //   console.log(value, typeOf(value), (typeOf(value) === 'string' && validator.isEmpty(value)))
  // }

  // TODO: Expand this to support objects.
  if (isRequired && (
    (typeOf(value) === 'string' && validator.isEmpty(value))
    || typeOf(value) === 'null'
    || typeOf(value) === 'undefined'
  )) {
    return config.requiredErrorMessage ? config.requiredErrorMessage
      : fieldConfigs.default.requiredErrorMessage;
  }

  const validateFunc = config.validate ? config.validate : fieldConfigs.default.validate;
  const result = validateFunc(value);

  if (returnError && !result) {
    return config.errorMessage;
  }
  return result;
}

/**
 * Validate Multiform State for Errors.
 *
 * @export
 * @param {*} formData
 * @param {*} formConfig
 * @returns
 */
export function validateMultiform(formData = {}, formConfig, strictMode = false) {
  // console.log(`Validating Multiform, strictMode: ${strictMode}`, formData);

  const fields = extractFieldsFromConfig(formConfig);

  // console.log('Fields Extracted from Config:', fields);
  const deriveFieldValue = (data, fieldName) => {
    if (data && data[fieldName]) {
      if (typeOf(data[fieldName]) === 'object') {
        return data[fieldName].value;
      }

      return data[fieldName];
    }

    return '';
  };

  const fieldsToValidate = Object.keys(fields).filter((fieldName) => {
    // All fields are validated in strictMode.
    if (strictMode) {
      return true;
    }

    const dataType = formData ? typeof formData[fieldName] : '';

    const fieldValue = deriveFieldValue(formData, fieldName);

    const { required } = fields[fieldName];

    switch (dataType) {
      case 'string':
        return !validator.isEmpty(fieldValue) || required;
      case 'object':
      default:
        return false;
    }
  });

  // TODO: Swap reduce function with 'For In' loop.
  const errors = fieldsToValidate.reduce((accum, fieldName) => {
    const {
      type,
      required,
    } = fields[fieldName];

    const fieldValue = deriveFieldValue(formData, fieldName);

    const inputValidation = validateInput(type, fieldValue, required, true);

    // useful for tracking down a specific value, just change the name here
    // if (fieldName === "Applicant_First_Name") {
    //   console.log("validating ", fieldName, " type: ", type, " required: ", required, fieldValue, inputValidation)
    // }
    // console.log(`Testing Errors on ${fieldName}`, type,required, fieldValue,  inputValidation);

    if (inputValidation === true) {
      return { ...accum };
    }

    return {
      ...accum,
      ...{
        [fieldName]: inputValidation,
      },
    };
  }, {});

  return errors;
}
