import React from 'react';
import PropTypes from 'prop-types';

const NodeMeta = ({ node }) => {
  const {
    overlayType = false,
    overlay = {},
    nodeType,
    acres,
    sqFeet,
  } = node;

  switch (overlayType) {
    case 'polygon':
      return (
        <p className="font-light text-xs">
          {nodeType === 'grow_area_outdoor' && acres && (
            <React.Fragment>
              Acres:
              {' '}
              <span className="font-medium max-w-16">{(acres).toFixed(3)}</span>
            </React.Fragment>
          )}
          {nodeType === 'grow_area_indoor' && acres && (
            <React.Fragment>
              Sq. Feet:
              {' '}
              <span className="font-medium max-w-16">{(sqFeet).toFixed(3)}</span>
            </React.Fragment>
          )}
        </p>
      );

    case 'marker':
      return (
        <p className="font-light text-xs">
          {overlay.position && (
            <React.Fragment>
              Lat:
              {' '}
              <span className="font-medium">{overlay.position.lat().toFixed(7)}</span>
              <span className="font-medium text-gray-500 mx-1">|</span>
              Lng:
              {' '}
              <span className="font-medium">{overlay.position.lng().toFixed(7)}</span>
            </React.Fragment>
          )}
        </p>
      );

    default:
      return (
        <p className="font-light text-xs text-gray-500">Please select & place a point on the map.</p>
      );
  }
};

NodeMeta.propTypes = {
  node: PropTypes.shape({
    overlayType: PropTypes.string,
    overlay: PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.func,
        lng: PropTypes.func,
      }),
    }),
    nodeType: PropTypes.string,
    acres: PropTypes.number,
    sqFeet: PropTypes.number,
  }).isRequired,
};

export default NodeMeta;
