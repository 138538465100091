import styled from 'styled-components';

import purpleSvgPattern from '../../../images/svg/backgrounds/logo-pattern-purple.svg';
import graySvgPattern from '../../../images/svg/backgrounds/logo-pattern-gray.svg';

export const TracePattern = styled.div`
  background-image: url(' ${props => (props.color === 'purple' ? purpleSvgPattern : graySvgPattern)}');
  background-repeat: no-repeat;

  @media (max-width: 1439px) {
    background-position: ${props => (props.bgPosition ? props.bgPosition : '120% bottom')};
  }

  @media (min-width: 1440px) {
    background-position-x: right;
    background-position-y: ${props => (props.bgPosition.split(' ')[1])};
  }
 
  background-attachment: ${props => (props.bgAttachment ? props.bgAttachment : 'fixed')};;

`;
