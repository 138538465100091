import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';
import NodeControl from '../NodeControl';

const NodeList = (props) => {
  const {
    nodes = [],
    nodeTypes,
    activeNode,
    createEmptyNode,
    updateNode,
    setActiveNode,
    removeNode,
    removeShape,
    mapApi,
    drawingManager,
    updateDrawingManagerOptions,
  } = props;

  return (
    <div className="w-full flex flex-col justify-around items-start">
      <div className="w-full flex flex-col flex-grow mb-3">
        {nodes && nodes.map((node, index) => (
          <NodeControl
            key={`node_${index.toString()}`}
            index={index}
            node={node}
            nodeTypes={nodeTypes}
            isActive={activeNode === index}
            removeNode={removeNode}
            setActiveNode={setActiveNode}
            removeShape={removeShape}
            updateNode={updateNode}
            mapApi={mapApi}
            drawingManager={drawingManager}
            updateDrawingManagerOptions={updateDrawingManagerOptions}
          >
            {node}
          </NodeControl>
        ))}
      </div>
      <Button
        type="button"
        color="transparent"
        variant="outline"
        size="sm"
        iconMargin="0"
        onClickHandler={() => {
          createEmptyNode();
        }}
        className="font-medium uppercase items-center mb-3"
      >
        Add Point
      </Button>
    </div>
  );
};

// TODO: Replace with shared reusable store instead of prop drilling.
NodeList.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  nodeTypes: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  activeNode: PropTypes.number,
  createEmptyNode: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  setActiveNode: PropTypes.func.isRequired,
  removeNode: PropTypes.func.isRequired,
  removeShape: PropTypes.func.isRequired,
  mapApi: PropTypes.shape({

  }).isRequired,
  drawingManager: PropTypes.shape({}).isRequired,
  updateDrawingManagerOptions: PropTypes.func.isRequired,
};

NodeList.defaultProps = {
  activeNode: 0,
};

export default NodeList;
