import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';
import Title from '../../Elements/Title';

import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';
import MapPolygonInput from './MapPolygonInput';
import { validateInput, getValidateErrorMsg } from '../util/validators';

const SiteInput = ({
  index,
  label,
  options,
  updateValueAndError,
  siteName,
  mapNodes,
  notes,
  errors,
}) => {
  const mapNodeErrors = {};

  options.forEach((option) => {
    if (!option.required) {
      return;
    }
    mapNodeErrors[option.value] = `At least one ${option.label} node is required.`;

    mapNodes.forEach((node) => {
      if (node && node.nodeType === option.value && node.nodeCords.length !== 0) {
        delete mapNodeErrors[option.value];
      }
    });
  });

  return (
    <div className="bg-white p-4 mb-6 rounded">
      <Title className="mb-3 flex flex-row">
        <span>{label}</span>
        &nbsp;
        <strong className="ml-auto">
          #
          {index + 1}
        </strong>
      </Title>

      <TextInput
        updateValueAndError={updateValueAndError}
        error={errors.siteName ? errors.siteName : ''}
        value={siteName}
        name="siteName"
        label="Site Name"
        containerClasses="mb-3"
        required
      />

      <MapPolygonInput
        updateValueAndError={updateValueAndError}
        name="mapNodes"
        value={mapNodes}
        options={options}
        errors={Object.values(mapNodeErrors)}
      />
      {mapNodeErrors !== {} && (
        <ul className="mt-3 mb-6 pl-6 list-disc">
          {Object.values(mapNodeErrors).map((error, errorIndex) => (
            <li
              key={`error_${errorIndex.toString()}`}
              className="text-red-500"
            >
              {error}
            </li>
          ))}
        </ul>
      )}


      <TextAreaInput
        onChange={updateValueAndError}
        updateValueAndError={updateValueAndError}
        error={errors.notes ? errors.notes : ''}
        value={notes}
        name="notes"
        label="Site Notes"
      />

    </div>
  );
};

// TODO: Complete these shape objects.
SiteInput.propTypes = {
  updateValueAndError: PropTypes.func.isRequired,
  siteName: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  mapNodes: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ),
  notes: PropTypes.string,
  errors: PropTypes.shape({
    siteAcres: PropTypes.string,
    siteName: PropTypes.string,
    notes: PropTypes.string,
  }),
};

SiteInput.defaultProps = {
  siteName: '',
  label: 'Site ',
  index: 0,
  notes: '',
  mapNodes: [],
  options: [],
  errors: [],
};

class SitesInput extends Component {
  constructor(props) {
    super(props);

    // console.log('initial stuff', props);

    const {
      name,
      value = [
        {
          errors: {},
        },
      ],
    } = this.props;


    this.state = {
      sites: value,
    };

    console.log('Construct SiteInput:', name, value);

    this.addSite = this.addSite.bind(this);
    this.removeSite = this.removeSite.bind(this);
    this.updateSite = this.updateSite.bind(this);
    this.validate = this.validate.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  // force validation check when changing states
  componentWillUnmount() {
    const {
      name,
      value,
      updateValueAndError,
      updateOnUnmount,
    } = this.props;

    const { sites } = this.state;
    console.log('We are unmounting SiteInput', sites, value);

    if (updateOnUnmount) {
      updateValueAndError(
        name,
        sites,
        this.getErrorMessage(sites)
      );
    }
  }

  getErrorMessage(value) {
    let errorMsg;

    if (!this.validate(value)) {
      console.warn('Found validation Issue');
      errorMsg = getValidateErrorMsg('sites');
    }

    return errorMsg;
  }

  removeSite(index) {
    const { sites } = this.state;
    sites.splice(index, 1);
    this.setState({ sites });
  }

  addSite() {
    const { sites } = this.state;
    sites.push({ errors: {} });
    this.setState({ sites });
  }

  validate(value) {
    const { options } = this.props;
    console.log('Calling Validate', options);

    return validateInput('sites', { value, options });
  }

  updateSite(index, siteName, value, error) {
    const { updateValueAndError, name } = this.props;
    const { sites = [] } = this.state;

    // console.log('Updating Sites', name, siteName);
    if (sites[index] === undefined) {
      return;
    }

    sites[index][siteName] = value;
    sites[index].errors[siteName] = error;

    this.setState(sites, () => {
      updateValueAndError(
        name,
        sites,
        this.getErrorMessage(sites),
      );
    });
  }

  render() {
    const { name, label, options } = this.props;
    const { sites = [] } = this.state;

    return (
      <>
        {sites.map((site, i) => (
          <SiteInput
            key={`${name}_site_input_${i.toString()}`}
            label={label}
            index={i}
            options={options}
            updateValueAndError={(siteName, value, error) => {
              // console.log(`Updating... Site: ${i} / Input: ${siteName}`, value, error);
              this.updateSite(i, siteName, value, error);
            }}
            {...site}
          />
        ))}
        <div className="mt-6">
          <Button
            type="button"
            color="dgray"
            variant="outline"
            size="sm"
            onClickHandler={this.addSite}
          >
            {sites.length > 1 ? 'Add another Site' : 'Create Site'}
          </Button>

          {sites.length > 1 && (
            <Button
              type="button"
              color="red"
              variant="outline"
              className="ml-3 outline opacity-500 hover:opacity-100"
              size="sm"
              onClickHandler={() => {
                this.removeSite(sites.length - 1);
              }}
            >
              Remove Site
            </Button>
          )}
        </div>
      </>
    );
  }
}

// TODO: Complete the definition of this shape.
SitesInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validator: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
  ),
  updateValueAndError: PropTypes.func.isRequired,
  updateOnUnmount: PropTypes.bool,
};

SitesInput.defaultProps = {
  label: undefined,
  updateOnUnmount: true,
  validator: 'sites',
  options: [
    {
      label: 'Outdoor Grow Area',
      value: 'grow_area_outdoor',
    },
    {
      label: 'Indoor Grow Area',
      value: 'grow_area_indoor',
    },
    {
      label: 'Storage Site',
      value: 'storage_location',
    },
    {
      label: 'Drying Site',
      value: 'drying_location',
    },
    {
      label: 'PRocessing Site',
      value: 'processing_location',
    },
    {
      label: 'Vehicle Access',
      value: 'road_access',
    },
  ],
};

export default SitesInput;
