import React from 'react';
import PropTypes from 'prop-types';

const StepHeader = ({ value, currentStep }) => (
  <h3 className="font-header text-xl font-light">
    Step
    {' '}
    {currentStep + 1}
    .
    {' '}
    {value}
  </h3>
);

StepHeader.propTypes = {
  value: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
};


export default StepHeader;
