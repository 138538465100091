import React from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import Loader from './index';

const PageLoader = ({ text }) => (
  <div className="flex min-w-full min-h-full">
    <div className="m-auto centered">
      {text && (
        <Title fontSize="sm" fontWeight="light" className="text-gray-600">
          {text}
        </Title>
      )}
      <div className="self-center centered my-3">
        <Loader type="circle" />
      </div>
    </div>
  </div>
);

PageLoader.propTypes = {
  text: PropTypes.string,
};

PageLoader.defaultProps = {
  text: undefined,
};

export default PageLoader;
