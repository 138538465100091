import React from 'react';
import PropTypes from 'prop-types';

import InputBase from './InputBase';
import InputWrapper from './InputWrapper';

class TextAreaInput extends InputBase {
  render() {
    const {
      error, label, value, name, updateValueAndError, placeholder,
    } = this.props;
    // console.log('Text area Input', this.props);

    return (
      <InputWrapper error={error} label={label} {...this.props}>
        <textarea
          onChange={(event) => {
            updateValueAndError(name, event.target.value, null);
          }}
          className={`focus:border-gold-500 ${error ? 'is-danger textarea' : 'textarea'}`}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
        />
      </InputWrapper>
    );
  }
}

TextAreaInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default TextAreaInput;
