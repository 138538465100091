import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';
import { validateInput, getValidateErrorMsg, getValidateRequiredMsg } from '../util/validators';

import './parts.css';

class InputBase extends Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);

    // this.getValidator = this.validator.bind(this)
    this.checkRequired = this.checkRequired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.requiredErrorMessage = this.requiredErrorMessage.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  // force validation check when changing states
  componentWillUnmount() {
    const {
      name,
      value,
      updateValueAndError,
      updateOnUnmount,
    } = this.props;

    if (updateOnUnmount) {
      const errorMessage = this.getErrorMessage(value);
      updateValueAndError(name, value, errorMessage);
    }
  }

  getErrorMessage(value) {
    const { required } = this.props;
    let errorMsg;

    if (required) {
      if (!value) {
        errorMsg = this.requiredErrorMessage();
      }
    }

    if (!this.validate(value)) {
      errorMsg = this.errorMessage();
    }

    return errorMsg;
  }

  requiredErrorMessage() {
    const { validator } = this.props;
    return getValidateRequiredMsg(validator);
  }

  errorMessage() {
    const { validator } = this.props;
    return getValidateErrorMsg(validator);
  }

  validate(value) {
    const { validator } = this.props;

    return validateInput(validator || 'default', value);
  }

  checkRequired(value) {
    const { required } = this.props;

    if (required) {
      if (!value) {
        return false;
      }
    }
    return (true);
  }

  handleChange(e) {
    const { value } = e.target;
    const {
      name,
      updateValueAndError,
    } = this.props;
    const errorMsg = this.getErrorMessage(value);

    updateValueAndError(name, value, errorMsg, e);
  }

  render() {
    const {
      name,
      value,
      error,
      placeholder,
      type = 'text',
      size = 'md',
      className,
    } = this.props;

    let sizeClasses = '';

    switch (size) {
      case 'xs':
        sizeClasses = 'p-2 text-xs';
        break;
      case 'sm':
        sizeClasses = 'p-2 text-sm';
        break;
      case 'md':
      default:
        sizeClasses = 'p-3';
        break;
    }

    return (
      <InputWrapper size={size} {...this.props}>
        <input
          id={`mf_input_${name}`}
          onChange={this.handleChange}
          className={`rounded border border-gray-400 focus:border-gold-500 font-light text-traceblack placeholder-gold-900 ${sizeClasses || ''} ${error ? 'border-red-400' : ''} ${className || ''}`}
          type={type}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
        />
      </InputWrapper>
    );
  }
}

InputBase.defaultProps = {
  value: '',
  validator: 'default',
  updateOnUnmount: true,
  placeholder: '',
  size: 'md',
  type: 'text',
  error: false,
  className: '',
  required: false,
  invertColor: false,
};

InputBase.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  validator: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  updateValueAndError: PropTypes.func.isRequired,
  required: PropTypes.bool,
  invertColor: PropTypes.bool,
  updateOnUnmount: PropTypes.bool,
};

export default InputBase;
