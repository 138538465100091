import React from 'react';
import PropTypes from 'prop-types';

import SvgWrapper from './SvgWrapper';

const Icon = ({
  icon = '',
  type = 'svg',
  className,
  hoverProps,
  iconRoot = 'icons',
  ...props
}) => {
  if (icon === '') {
    return 'Missing `icon` Prop.';
  }

  switch (type) {
    case 'font':
      return (
        <i className={`icon-${icon} ${className}`} {...props} />
      );
    case 'img':
      return (
        <img alt={`${icon} icon`} className={className} src={`/${iconRoot}/${icon}.svg`} {...props} />
      );
    case 'svg':
    default:
      // TODO: Add better error handling here.
      // Prevent against breaking application given an incorrect path.

      // eslint-disable-next-line no-case-declarations,import/no-dynamic-require,global-require
      const svg = icon && require(`!raw-loader!../../../images/svg/icons/${icon}.svg`);
      return (
        <React.Fragment>

          { // eslint-disable-next-line
          svg && (
            <SvgWrapper
              iconProps={{ ...props }}
              hoverProps={{ ...hoverProps }}
              className={className}
              dangerouslySetInnerHTML={{ __html: svg && svg.default }}
            />
          )}
        </React.Fragment>
      );
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconRoot: PropTypes.string,
  type: PropTypes.oneOf(['img', 'svg', 'font']),
  className: PropTypes.string,
  hoverProps: PropTypes.shape({}),
};

Icon.defaultProps = {
  iconRoot: 'icons',
  type: 'svg',
  className: '',
  hoverProps: {},
};

export default Icon;
