import 'whatwg-fetch';
import NotificationService, {
  NOTIFICATION_LOGIN_ERROR,
  NOTIFICATION_REGISTRATION_ERROR,
  NOTIFICATION_REGISTRATION_SUCCESS,
  NOTIFICATION_PASSWORD_CHANGE_ERROR,
  NOTIFICATION_PASSWORD_CHANGE_SUCCESS,
  NOTIFICATION_UPDATE_REGULATOR_ERROR,
  NOTIFICATION_UPDATE_REGULATOR_SUCCESS,
  NOTIFICATION_INVITE_REGULATOR_ERROR,
  NOTIFICATION_UPDATE_HEMP_USER_ERROR,
  NOTIFICATION_UPDATE_HEMP_USER_SUCCESS,
  NOTIFICATION_INVITE_REGULATOR_SUCCESS,
  NOTIFICATION_PASSWORD_RESET_ERROR,
  NOTIFICATION_PASSWORD_RESET_SUCCESS,
  NOTIFICATION_REGISTER_PAYMENT_SUCCESS,
  NOTIFICATION_REGISTER_PAYMENT_ERROR,
  NOTIFICATION_REQUEST_RESET_ERROR,
  NOTIFICATION_REQUEST_RESET_SUCCESS,
  NOTIFICATION_VERIFICATION_ERROR,
  NOTIFICATION_VERIFICATION_SUCCESS,
} from './NotificationService';

import DataService from './DataService';

import {
  loginQuery,
  updateHempUserMutation,
  meOrganizationAll,
  meQuery,
  retrieveStateRolesQuery,
  verifyUserMutation,
  resendVerificationEmailMutation,
} from './graphql-queries/hempuser-queries';

import {
  regulatorLoginQuery,
  statsQuery,
  listRegulatorsQuery,
  updateRegulatorUserMutation,
  registerRegulatorMutation,
  passwordForgottenMutation,
  deleteUserMutation, // not strictly for regulator
  updateUserPasswordMutation, // not strictly for regulator
  regulatorOrganizationsSummary,
  regulatorLoadPermitById,
  regulatorPermitsQuery,
  newPermitForOrg,
} from './graphql-queries/regulator-queries';

import {
  createOrganizationMutation,
  allOrganizationsSummary,
} from './graphql-queries/organization-queries';

import {
  allLotsSummary,
  lotByAddressQuery,
} from './graphql-queries/lot-queries';

import {
  updatePermitMutation,
  newPermitMutation,
  loadPermitByIdQuery,
  permitListingQuery,
  submitPermitMutation,
  updatePermitStatusMutation,
  recordPermitPaymentMutation,
  markPermitAsReadMutation, // shouild be used by regulators only
} from './graphql-queries/permit-queries';

import { extractRoleFromToken } from '../utils/jwt';


const ds = new DataService();
const ns = new NotificationService();

// TODO: Update default API endpoint(?)
const {
  REACT_APP_API_ENDPOINT: endpoint = 'https://trace-backend-dev-pr-236.herokuapp.com',
} = process.env;

const GRAPHQL_PATH = `${endpoint}/graphql/`;

class HttpService {
  // TODO: Refactor into static function.
  // eslint-disable-next-line class-methods-use-this
  doGraphqQLRequest(graphqlPayload, onSuccessCallback, onErrorCallback) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    console.group('HTTP Service - Request');

    // if logged in add auth header
    if (ds.getSessionKey()) {
      headers.Authorization = `Bearer ${ds.getSessionKey()}`;
    }

    fetch(GRAPHQL_PATH, {
      method: 'POST',
      headers,
      body: JSON.stringify({ query: graphqlPayload }),
    })
      .then(response => response.json())
      .then((json) => {
        const { errors, data } = json;

        if (errors) {
          // TODO we should remove this and connect to
          //      some sort of error capture service
          console.log('GRAPHQL RESPONSE ERROR');
          console.log('PAYLOAD: ', graphqlPayload);
          console.log('ERRORS: ', errors);
          console.log('DATA: ', data);
          console.log('ERROR CALLBACK: ', onErrorCallback);

          if (onErrorCallback) {
            onErrorCallback(errors, data);
          }
        }

        if (data && onSuccessCallback) {
          console.log(
            'Firing onSuccessCallback',
            data,
            onSuccessCallback,
            errors,
          );
          onSuccessCallback(data);
        }

        console.groupEnd();
      });
  }

  requestPasswordReset(email) {
    this.doGraphqQLRequest(
      passwordForgottenMutation(email),
      () => {
        ns.postNotification(NOTIFICATION_REQUEST_RESET_SUCCESS, true);
      },
      (err) => {
        ns.postNotification(NOTIFICATION_REQUEST_RESET_ERROR, err[0].message);
      },
    );
  }

  deleteRegulatorUser(id) {
    this.doGraphqQLRequest(deleteUserMutation(id), () => {
      ds.deleteRegulatorUser(id);
    });
  }

  verifyUser(token) {
    if (token) {
      this.doGraphqQLRequest(
        verifyUserMutation(token),
        (data) => {
          ns.postNotification(NOTIFICATION_VERIFICATION_SUCCESS, data);
        },
        (err) => {
          ns.postNotification(
            NOTIFICATION_VERIFICATION_ERROR,
            err[0].message,
          );
        },
      );
    } else {
      // just hard enforce we show the login page
      ns.postNotification(NOTIFICATION_VERIFICATION_ERROR, 'Missing Token');
    }
  }

  registerRegulator({ firstName, lastName, email }) {
    const TODO_FIX_HARDCODED_STATE = 'VT';
    this.doGraphqQLRequest(
      registerRegulatorMutation(TODO_FIX_HARDCODED_STATE, {
        firstName,
        lastName,
        email,
      }),
      ({ registerRegulator }) => {
        if (registerRegulator) {
          const {
            id,
            regulatorFirstName,
            regulatorLastName,
            regulatorEmail,
          } = registerRegulator;
          ds.updateRegulatorById(id, {
            regulatorFirstName,
            regulatorLastName,
            regulatorEmail,
          });
          ns.postNotification(NOTIFICATION_INVITE_REGULATOR_SUCCESS, true);
        }
      },
      (err) => {
        ns.postNotification(
          NOTIFICATION_INVITE_REGULATOR_ERROR,
          err[0].message,
        );
      },
    );
  }

  updateRegulator(regulator) {
    this.doGraphqQLRequest(
      updateRegulatorUserMutation(regulator),
      () => {
        ds.updateRegulatorById(regulator.id, regulator);
        ns.postNotification(NOTIFICATION_UPDATE_REGULATOR_SUCCESS, true);
      },
      err => ns.postNotification(NOTIFICATION_UPDATE_REGULATOR_ERROR, err),
    );
  }

  getDashboardCounts() {
    this.doGraphqQLRequest(statsQuery, (data) => {
      ds.setDashboardCounts(data.statistics);
    });
  }

  changeUserPassword({ email, token, newPassword }) {
    this.doGraphqQLRequest(
      updateUserPasswordMutation({ email, token, newPassword }),
      () => ns.postNotification(NOTIFICATION_PASSWORD_CHANGE_SUCCESS, true),
      err => ns.postNotification(NOTIFICATION_PASSWORD_CHANGE_ERROR, err),
    );
  }

  listRegulators(state) {
    this.doGraphqQLRequest(
      listRegulatorsQuery(state),
      ({ regulatorAgency }) => {
        ds.setRegulators(regulatorAgency.regulators);
      },
    );
  }

  retrieveStateRoles(state) {
    this.doGraphqQLRequest(retrieveStateRolesQuery(state || 'VT'), (data) => {
      ds.setStateRoles(data.retrieveStateRoles);
    });
  }

  createNewUser(owner, organization) {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(
      createOrganizationMutation(owner, organization),
      () => {
        ds.setIsLoading(false);
        ns.postNotification(NOTIFICATION_REGISTRATION_SUCCESS, true);
      },
      (errors) => {
        ds.setIsLoading(false);
        ns.postNotification(NOTIFICATION_REGISTRATION_ERROR, errors[0].message);
      },
    );
  }

  startNewPermit(year) {
    this.doGraphqQLRequest(newPermitMutation(year), (data) => {
      ds.setCurrentPermitId(data.startPermit.id);
    });
  }

  initiatePermitForOrg(year, id, callback) {
    this.doGraphqQLRequest(newPermitForOrg(year, id), (data) => {
      callback(data.startPermit.id);
    });
  }

  loadPermitListing() {
    const userType = ds.getUserType();
    let query;

    if (userType === 'regulator') {
      query = regulatorPermitsQuery('VT');
    } else {
      query = permitListingQuery;
    }
    // TODO regulator permit query regulatorPermitListingQuery
    this.doGraphqQLRequest(query, (data) => {
      let permits;

      if (userType === 'regulator') {
        // eslint-disable-next-line prefer-destructuring
        permits = data.regulatorAgency.permits || [];
      } else {
        // eslint-disable-next-line prefer-destructuring
        permits = data.permits.results || [];
      }

      ds.setPermits(permits);
    });
  }

  loadPermitById(id) {
    const userType = ds.getUserType();
    let query;

    if (userType === 'regulator') {
      query = regulatorLoadPermitById('VT', id);
    } else {
      query = loadPermitByIdQuery(id);
    }

    this.doGraphqQLRequest(query, (data) => {
      let permit;

      if (userType === 'regulator') {
        // eslint-disable-next-line prefer-destructuring
        permit = data.regulatorAgency.permit;
      } else {
        // eslint-disable-next-line prefer-destructuring
        permit = data.permit;
      }

      ds.addPermit(permit.id, permit);
    });
  }

  markPermitAsRead(id) {
    this.doGraphqQLRequest(
      markPermitAsReadMutation(id),
      ({ markPermitAsRead: { read } }) => {
        ds.updatePermitById(id, { read });
      },
    );
  }

  registerPaymentForPermit(id, paymentAmount, paymentMethod) {
    this.doGraphqQLRequest(
      recordPermitPaymentMutation({
        permitId: id,
        paymentMethod,
        paymentAmount,
      }),
      (data) => {
        ds.updatePermitById(
          data.recordPermitPayment.id,
          data.recordPermitPayment,
        );
        ns.postNotification(NOTIFICATION_REGISTER_PAYMENT_SUCCESS, true);
      },
      (err) => {
        ns.postNotification(
          NOTIFICATION_REGISTER_PAYMENT_ERROR,
          err[0].message,
        );
      },
    );
  }

  updatePermit(id, details) {
    this.doGraphqQLRequest(updatePermitMutation(id, details));
  }

  updatePermitStatus(id, status, reason) {
    this.doGraphqQLRequest(
      updatePermitStatusMutation(id, status, reason),
      () => {
        ds.updatePermitStatus(id, status);
      },
    );
  }

  submitPermit(id) {
    this.doGraphqQLRequest(submitPermitMutation(id), () => {
      const p = ds.getPermitById(id);
      p.status = 'submitted';
      ds.addPermit(id, p);
    });
  }

  submitReset({ newPassword, token }) {
    this.doGraphqQLRequest(
      updateUserPasswordMutation({ token, newPassword }),
      () => ns.postNotification(NOTIFICATION_PASSWORD_RESET_SUCCESS, true),
      (err) => {
        ns.postNotification(NOTIFICATION_PASSWORD_RESET_ERROR, err[0].message);
      },
    );
  }

  resendVerificationRequest(email, widgetCallback) {
    this.doGraphqQLRequest(
      resendVerificationEmailMutation(email),
      () => { widgetCallback(); }
    );
  }

  submitLogin(email, password) {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(loginQuery(email, password), (data) => {
      let key;
      if (data && data.login) {
        key = data.login.authToken;
      } else {
        key = false;
      }
      if (key) {
        // TODO make user types a constant+
        ds.setUserType(extractRoleFromToken(key));
        ds.setUsername(`${data.login.firstName} ${data.login.lastName}`);
        ds.setSessionKey(key);
        ds.setIsLoading(false);
      }
    },
    (errors) => {
      ns.postNotification(
        NOTIFICATION_LOGIN_ERROR,
        errors[0].message
      );
    });
  }

  submitRegulatorLogin(email, password) {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(regulatorLoginQuery('VT', email, password), (data) => {
      let key;
      if (data && data.regulatorAgency && data.regulatorAgency.login) {
        key = data.regulatorAgency.login.authToken;
      } else {
        key = false;
      }
      if (key) {
        ds.setUserType(extractRoleFromToken(key));
        ds.setUsername(
          `${data.regulatorAgency.login.firstName} ${data.regulatorAgency.login.lastName}`,
        );
        ds.setSessionKey(key);
        ds.setIsLoading(false);
        this.updateOrganizationsData();
        this.getDashboardCounts();
        this.getLotSummaries();
      }
    },
    (errors) => {
      ns.postNotification(
        NOTIFICATION_LOGIN_ERROR,
        errors[0].message
      );
    });
  }

  getLotSummaries() {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(allLotsSummary, (data) => {
      const { lots } = data;
      if (lots) {
        ds.updateLots({
          ...data,
          lots: lots.filter(x => x !== null),
        });
      }
      ds.setIsLoading(false);
    });
  }

  getLotByAddress(address) {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(lotByAddressQuery(address), (data) => {
      ds.addLot(data);
      ds.setIsLoading(false);
    });
  }

  updateOrganizationsData() {
    ds.setIsLoading(true);
    const userType = ds.getUserType();
    let query;

    if (userType === 'regulator') {
      query = regulatorOrganizationsSummary('VT');
    } else {
      query = allOrganizationsSummary;
    }
    // console.log(regulatorOrganizationsSummary("VT"))
    this.doGraphqQLRequest(query, (data) => {
      let orgs = data.organizations;

      if (userType === 'regulator') {
        orgs = data.regulatorAgency.organizations;
      } else {
        orgs = data.organizations;
      }

      ds.setOrganizations(orgs);
      ds.setIsLoading(false);
    });
  }

  updateOrganizationData() {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(meOrganizationAll, (data) => {
      ds.setOrganization(data.me.organization);
      ds.setIsLoading(false);
    });
  }

  getCurrentUser() {
    this.doGraphqQLRequest(meQuery, (data) => {
      ds.setCurrentUser(data.me);
    });
  }

  updateUser(user) {
    ds.setIsLoading(true);
    this.doGraphqQLRequest(
      updateHempUserMutation(user),
      (data) => {
        const { updateUser: newUser } = data;
        ds.updateCurrentUser(newUser);
        ds.setUsername(`${newUser.firstName} ${newUser.lastName}`);

        ns.postNotification(NOTIFICATION_UPDATE_HEMP_USER_SUCCESS, newUser);

        ds.setIsLoading(false);
      },
      err => ns.postNotification(NOTIFICATION_UPDATE_HEMP_USER_ERROR, err[0].message),
    );
  }

  // special case, doesnt use raw dta, but looks at response status
  // and therefor doesnt use doGraphqQLRequest
  // TODO: Refactor into static function.
  // eslint-disable-next-line class-methods-use-this
  checkAuth() {
    fetch(GRAPHQL_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${ds.getSessionKey()}`,
      },
      body: JSON.stringify({ query: meQuery }),
    })
      .then((response) => {
        // if we are logged in we can just set that status, otherwise
        // we can clean up local storage so that data which may
        // have been added by a delayed callback response does not clutter up
        // our lives
        if (response.status === 200) {
          ds.setIsLoggedIn(true);
        } else {
          ds.destroySession();
        }
      })
      .catch((/* error */) => {
        // ensure we clear the session on 4xx responses
        ds.destroySession();
      });
  }
}

export default HttpService;
