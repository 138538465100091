import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalBase from './ModalBase';
import Button from '../Button';

const Modal = ({
  children,
  button,
  ariaLabel,
  ariaCloseLabel,
  className = '',
  rootId = 'root',
  overlayClassName = '',
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Button
        type="button"
        onClickHandler={() => setOpen(true)}
        {...button}
      >
        {button.title}
      </Button>

      <ModalBase {...{
        ariaLabel,
        ariaCloseLabel,
        className,
        overlayClassName,
        isOpen,
        rootId,
        setOpen,
      }}
      >
        {children}
      </ModalBase>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.shape({
    title: PropTypes.string,
  }),
  rootId: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaCloseLabel: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
};

Modal.defaultProps = {
  button: {
    title: 'Open Modal',
  },
  rootId: 'root',
  ariaLabel: '',
  ariaCloseLabel: '',
  className: '',
  overlayClassName: '',
};

export default Modal;
