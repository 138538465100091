import React from 'react';
import PropTypes from 'prop-types';

import { LogoSVG, IconSVG } from './Logo.svg';

const Logo = ({
  iconOnly,
  color,
  svgFill,
  width,
  className,
  svgTitle,
}) => (
  <div className={`text-${color} flex flex-row items-center ${className}`}>
    <div style={{ width }}>
      {iconOnly ? (
        <IconSVG fill={svgFill} />
      ) : (
        <LogoSVG fill={svgFill} title={svgTitle} />
      )}
    </div>
  </div>
);

Logo.propTypes = {
  iconOnly: PropTypes.bool,
  color: PropTypes.string, // TODO: Deprecate & remove?
  svgFill: PropTypes.string,
  width: PropTypes.string, // TODO: Simplify how this works within the component.
  className: PropTypes.string,
  svgTitle: PropTypes.string,
};

Logo.defaultProps = {
  iconOnly: false,
  color: 'black',
  svgFill: '#fff',
  width: '35',
  className: '',
  svgTitle: 'Trace LLC Logo',
};

export default Logo;
