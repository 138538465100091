import React from 'react';
import PropTypes from 'prop-types';

import SiteMapper from '../../SiteMapper';

const MapPolygonInput = ({
  updateValueAndError = (name, value, errors) => {
    console.warn(`default input update callback | name: ${name} | value: `, value, errors);
  },
  options,
  value = [],
  errors = [],
  ...props
}) => (
  <div>
    <SiteMapper
      onMapUpdate={updateValueAndError}
      errors={errors}
      options={options}
      mapNodes={value.filter(item => item !== null)}
      {...props}
    />
  </div>
);

// TODO: Fully define the shape for `value` prop-type.
MapPolygonInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
  ),
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
  updateValueAndError: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

MapPolygonInput.defaultProps = {
  value: [],
  errors: [],
  options: undefined,
};

export default MapPolygonInput;
