import React from 'react';
import PropTypes from 'prop-types';

import MapPolygonInput from '../parts/MapPolygonInput';

const SitesInputReview = (value, field) => {
  console.log('value in site review', value);

  return (
    <>
      {value ? (
        value.map((site, index) => {
          const {
            mapNodes = [],
            siteName = '',
            notes = '',
          } = site;

          const totalArea = {
            acres: 0,
            sqFeet: 0,
          };
          let hasGrowArea = false;

          for (let nodeCounter = 0; nodeCounter < mapNodes.length; nodeCounter += 1) {
            const node = mapNodes[nodeCounter];

            if (node.nodeType === 'grow_area_indoor') {
              totalArea.sqFeet += node.sqFeet;
              hasGrowArea = true;
            }

            if (node.nodeType === 'grow_area_outdoor') {
              totalArea.acres += node.acres;
              hasGrowArea = true;
            }
          }

          return (
            <div
              key={`site_${field.name}_${index.toString()}`}
              className="mb-3"
            >
              <div className="mb-3">
                <h4 className="flex flex-row items-center justify-between mb-1">
                  <span className="font-semibold text-lg mr-1">
                    {siteName}
                  </span>
                  <code className="text-lg mr-3 bg-gray-100 p-2">
                    #
                    {index + 1}
                  </code>
                </h4>

                <p className="w-full text-sm mb-3 border-l-4 border-gray-200 pl-4">{notes}</p>

                {hasGrowArea && (
                  <p className="flex flex-col text-sm">
                    <span className="flex flex-row items-center">
                      <span className="font-medium mr-1 text-gray-800">Indoor Area: </span>
                      <code className="">{(totalArea.sqFeet).toFixed(3)}</code>
                      <span className="ml-1 text-xs">sq. feet</span>
                    </span>

                    <span className="flex flex-row items-center">
                      <span className="font-medium mr-1 text-gray-800">Outdoor Area: </span>
                      <code className="">{(totalArea.acres).toFixed(3)}</code>
                      <span className="ml-1 text-xs">acres</span>
                    </span>
                  </p>
                )}
              </div>
              <MapPolygonInput
                name={field.name}
                mapNodes={mapNodes}
                isEditable={false}
                updateValueAndError={() => {}}
              />
            </div>
          );
        })
      ) : (
        <div className="">
          <h4 className="flex flex-row items-center text-lg mb-1">
            No Site Data Entered
          </h4>
        </div>
      )}

    </>
  );
};

SitesInputReview.propTypes = {
  value: PropTypes.shape({}).isRequired,
  field: PropTypes.shape({}).isRequired,
};

export default SitesInputReview;
