/* eslint-disable class-methods-use-this */
// TODO: Remove this eslint comment and refactor methods into static functions.
import deepmerge from 'deepmerge';
import NotificationService, {
  NOTIFICATION_AUTH_CHANGED,
  NOTIFICATION_LOTS_CHANGED,
  NOTIFICATION_ORGANIZATIONS_CHANGED,
  NOTIFICATION_DASHBOARD_CHANGED,
  NOTIFICATION_LOADING,
  NOTIFICATION_NEW_PERMIT_DATA,
  NOTIFICATION_PERMIT_STARTED,
  NOTIFICATION_LOADED_PERMITS,
  NOTIFICATION_NEW_STATE_ROLES,
  NOTIFICATION_LOADED_REGULATORS,
  NOTIFICATION_NEW_REGULATOR_DATA,
} from './NotificationService';


const ns = new NotificationService();
let instance = null;

class DataService {
  constructor() {
    if (!instance) {
      instance = this;
    }
  }

  deleteRegulatorUser(id) {
    const r = this.getRegulators();
    delete (r[id]);
    this.setRegulators(Object.values(r));
  }

  setStateRoles(roles) {
    localStorage.setItem('state_roles', JSON.stringify(roles));
    ns.postNotification(NOTIFICATION_NEW_STATE_ROLES, roles);
  }

  getStateRoles() {
    const raw = localStorage.getItem('state_roles');
    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : [];
    return p;
  }

  getRegulators() {
    const raw = localStorage.getItem('regulators');

    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return p;
  }

  addRegulator(id, regulator) {
    const raw = localStorage.getItem('regulators');
    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    p[id] = regulator;

    if (id !== 'undefined' && regulator) {
      localStorage.setItem('regulators', JSON.stringify(p));
      ns.postNotification(NOTIFICATION_NEW_REGULATOR_DATA, id);
    }
  }

  setRegulators(regulators = []) {
    const pMap = {};
    for (let i = 0; i < regulators.length; i += 1) {
      pMap[regulators[i].id] = regulators[i];
    }
    localStorage.setItem('regulators', JSON.stringify(pMap));
    ns.postNotification(NOTIFICATION_LOADED_REGULATORS, pMap);
  }

  getRegulatorById(id) {
    const raw = localStorage.getItem('regulators');


    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return p[id];
  }

  updateRegulatorById(id, values = {}) {
    const old = this.getRegulatorById(id);
    if (old) {
      const newR = deepmerge(old, values);
      this.addRegulator(id, newR);
    }
  }

  setPermits(permits) {
    const pMap = {};
    for (let i = 0; i < permits.length; i += 1) {
      pMap[permits[i].id] = permits[i];
    }
    localStorage.setItem('permits', JSON.stringify(pMap));
    ns.postNotification(NOTIFICATION_LOADED_PERMITS, pMap);
  }

  getPermits() {
    const raw = localStorage.getItem('permits');
    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return p;
  }

  updatePermitStatus(id, status) {
    const permit = this.getPermitById(id);
    permit.status = status;
    this.addPermit(id, permit);
  }

  addPermit(id, permit) {
    const raw = localStorage.getItem('permits');
    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};

    // this handles an edge case where a started but un-edited permit returns
    // null for the details from graphql
    if (!permit.details) {
      permit.details = {};
    }

    p[id] = permit;

    if (id !== 'undefined' && permit) {
      localStorage.setItem('permits', JSON.stringify(p));
      ns.postNotification(NOTIFICATION_NEW_PERMIT_DATA, permit);
    }
  }

  getPermitById(id) {
    const raw = localStorage.getItem('permits');
    const p = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return p[id];
  }

  setCurrentPermitId(id) {
    localStorage.setItem('current_permit_id', id);
    ns.postNotification(NOTIFICATION_PERMIT_STARTED, id);
  }

  getCurrentPermitId() {
    return localStorage.getItem('current_permit_id');
  }

  updatePermitById(id, values = {}) {
    const oldPermit = this.getPermitById(id);
    if (oldPermit) {
      const newPermit = deepmerge(oldPermit, values);
      this.addPermit(id, newPermit);
    }
  }

  setUserType(type) {
    localStorage.setItem('user_type', type);
  }

  getUserType() {
    return localStorage.getItem('user_type');
  }

  // APP STATE
  setIsLoading(bool) {
    localStorage.setItem('is_loading', bool);
    ns.postNotification(NOTIFICATION_LOADING, true);
  }

  getIsLoading() {
    // cast as bool
    return localStorage.getItem('is_loading') === 'true';
  }

  // LOGIN / Session
  setSessionKey(key) {
    localStorage.setItem('session_key', key);
    this.setIsLoggedIn(true);
  }

  getSessionKey() {
    return localStorage.getItem('session_key');
  }

  // this is handling logout so cleaning up a few things here
  destroySession() {
    const keysToRemove = [
      'session_key',
      'current_permit_id',
      'organization',
      'username',
      'organizations',
      'lots',
      'permits',
      'dashboardCounts',
      'domain',
      'is_loading',
      'user_type',
      'regulators',
      'currentUser',
    ];

    for (let i = 0; i < keysToRemove.length; i += 1) {
      localStorage.removeItem(keysToRemove[i]);
    }

    this.setIsLoggedIn(false);
  }

  setIsLoggedIn(bool) {
    localStorage.setItem('is_logged_in', bool);
    ns.postNotification(NOTIFICATION_AUTH_CHANGED, bool);
  }

  setUsername(username) {
    localStorage.setItem('username', username);
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  setCurrentUser(user = {}) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  updateCurrentUser(user = {}) {
    const raw = localStorage.getItem('currentUser');
    const data = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    this.setCurrentUser(deepmerge(data, user));
  }

  getCurrentUser() {
    const raw = localStorage.getItem('currentUser');
    const data = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return data;
  }

  getIsLoggedIn() {
    // cast as bool
    return localStorage.getItem('is_logged_in') === 'true';
  }

  getDashboardCounts() {
    const raw = localStorage.getItem('dashboardCounts');
    const data = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return data;
  }

  setDomain(domain) {
    localStorage.setItem('domain', domain);
  }

  getDomain() {
    localStorage.getItem('domain');
  }

  setDashboardCounts(stats) {
    const seed = { lots: {} };
    if (stats.lots) {
      for (let i = 0; i < stats.lots.length; i += 1) {
        const x = stats.lots[i];
        seed.lots[x.state] = x.count;
      }
    }
    localStorage.setItem('dashboardCounts', JSON.stringify(seed));
    ns.postNotification(NOTIFICATION_DASHBOARD_CHANGED, seed);
  }

  // ORGANIZATIONS
  setOrganization(organization) {
    localStorage.setItem('organization', JSON.stringify(organization));
    // ns.postNotification(NOTIFICATION_ORGANIZATIONS_CHANGED, organization);
  }

  getOrganization() {
    const rawOrg = localStorage.getItem('organization');
    const org = rawOrg && rawOrg !== 'undefined' ? JSON.parse(rawOrg) : { lots: [] };
    return org;
  }

  setOrganizations(organizations) {
    localStorage.setItem('organizations', JSON.stringify(organizations));
    ns.postNotification(NOTIFICATION_ORGANIZATIONS_CHANGED, organizations);
  }

  getOrganizations() {
    const rawOrgs = localStorage.getItem('organizations');
    const orgs = rawOrgs && rawOrgs !== 'undefined' ? JSON.parse(rawOrgs) : [];
    return orgs;
  }

  getOrganizationByAddress(address) {
    return this.getOrganizations().find(org => org.address === address);
  }

  // LOTS
  getAllLots() {
    return Object.values(this.getLots());
  }

  getProcessing() {
    return this.getAllLots().filter(lot => lot.parentLot !== null);
  }

  getCultivating() {
    return this.getAllLots().filter(lot => lot.parentLot === null);
  }

  updateLots(data) {
    const { lots } = data;
    const currentLots = this.getLots();

    for (let i = 0; i < lots.length; i += 1) {
      const lot = lots[i];
      const { address } = lot;
      const current = currentLots[address];
      if (current === undefined) {
        currentLots[address] = lot;
      } else {
        currentLots[address] = deepmerge(current, lot);

        // i dont like doing this :)
        currentLots[address].subLots = lot.subLots;
        currentLots[address].details = lot.details;
      }
    }
    this.setLots(currentLots);
  }

  getLotByAddress(address) {
    return this.getLots()[address];
  }

  getLots() {
    const raw = localStorage.getItem('lots');
    const lots = raw && raw !== 'undefined' ? JSON.parse(raw) : {};
    return lots;
  }

  addLot(lot) {
    // shape data like a summary response for updateLots()
    const simulatedResponse = {
      lots: [lot.lot],
    };
    this.updateLots(simulatedResponse);
  }

  setLots(lots) {
    // TODO remove this
    const filteredLots = {};
    const incomingAddresses = Object.keys(lots);

    for (let i = 0; i < incomingAddresses.length; i += 1) {
      const address = incomingAddresses[i];
      if (lots[address].organization) {
        filteredLots[address] = lots[address];
      }
    }

    localStorage.setItem('lots', JSON.stringify(filteredLots));
    ns.postNotification(NOTIFICATION_LOTS_CHANGED, Object.values(filteredLots));
  }

  // PRODUCTS
  setProducts(products) {
    localStorage.setItem('products', JSON.stringify(products));
  }

  getProducts() {
    const p = localStorage.getItem('products');
    const products = p && p !== 'undefined' ? JSON.parse(p) : [];
    return products;
  }
}

export default DataService;
