import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';
import EmailInput from '../../MultiForm/parts/EmailInput';

const RequestPasswordReset = ({
  handleSubmit,
  errorMessage,
  isSubmitPending,
  loginPath,
  LinkComponent,
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(email);
      }}
    >
      <div className="my-6">
        <EmailInput
          label="Enter the email associated with your account"
          name="email"
          type="email"
          required
          error={error}
          value={email}
          placeholder="Enter your email"
          updateValueAndError={(_, v, e) => {
            setEmail(v);
            setError(e);
          }}
        />
      </div>

      <div>
        {errorMessage && (
          <span className="text-orange-500">{errorMessage}</span>
        )}
      </div>

      <div className="my-8">
        <div className="flex flex-row items-center">
          <Button
            type="submit"
            size="xl"
            className="uppercase"
            icon={isSubmitPending ? 'loader' : ''}
            isDisabled={!email || error !== undefined}
          >
            {!isSubmitPending ? 'Continue' : null}
          </Button>

          {LinkComponent && loginPath && (
            <LinkComponent className="ml-6 text-sm hover:text-gray-700" to={loginPath}>
              Return to Login
            </LinkComponent>
          )}
        </div>
      </div>
    </form>
  );
};

RequestPasswordReset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isSubmitPending: PropTypes.bool,
  loginPath: PropTypes.string,
  LinkComponent: PropTypes.func,
};

RequestPasswordReset.defaultProps = {
  errorMessage: null,
  isSubmitPending: false,
  LinkComponent: undefined,
  loginPath: '/login/',
};

export default RequestPasswordReset;
