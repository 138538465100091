import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Elements/Button';
import Title from '../../Elements/Title';
import Copy from '../../Elements/Copy';

import { calculateVermontPrice } from '../util/vermont';

// TODO: This is permit specific code, should be moved out of core into reg portal.
// TODO: Add link to static page explaining pricing & fees.
const PricingTip = ({ formData }) => (
  <div className="bg-peppercorn p-3 mt-3 rounded-lg text-center text-white">
    <Copy>
      <Title
        className="bg-gold-300 rounded-full mb-3 font-bold w-10 ml-auto mr-auto"
        style={{ fontFamily: 'monospace' }}
        as="h1"
      >
        i
      </Title>
      <Title as="h3">
        Your application fee is
        <br />
        <span className="font-bold">
          $
          {calculateVermontPrice(formData)}
        </span>
      </Title>
      <p>
        To find out more about pricing or fees
        <br />
        <button type="button">click here.</button>
      </p>
    </Copy>
  </div>
);

PricingTip.propTypes = {
  formData: PropTypes.shape({}),
};

PricingTip.defaultProps = {
  formData: {},
};

const VermontSidebar = (props) => {
  const {
    formData, steps, activeStep, saveAndClose,
  } = props;

  return (
    <div className="sidebar">
      <Button
        className="w-full h-12 uppercase text-right flex justify-end mb-3"
        color="transparent"
        icon="angle-left"
        iconFirst
        onClickHandler={() => {
          saveAndClose();
        }}
      >
        Back
      </Button>
      <Button
        color="gold"
        className="w-full block uppercase items-center text-center"
        size="lg"
        onClickHandler={() => {
          saveAndClose();
        }}
      >
        Close & Save as Draft
      </Button>
      {steps[activeStep].name === 'payment' && <PricingTip formData={formData} />}
    </div>
  );
};

VermontSidebar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  formData: PropTypes.shape({}).isRequired,
  saveAndClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
};

VermontSidebar.defaultProps = {
  activeStep: 0,
};

export default VermontSidebar;
