import React from 'react';
import PropTypes from 'prop-types';
import StepButton from '../StepButton';

import ReviewField from '../ReviewField';

const ReviewStep = ({
  steps,
  formData,
  formErrors,
  changeStep,
  submitForm,
  isPending = false,
}) => {
  const hasErrors = Object.values(formErrors).length !== 0;
  let nextBtnIcon;
  if (isPending) {
    nextBtnIcon = 'loader';
  } else if (hasErrors) {
    nextBtnIcon = 'delete';
  } else {
    nextBtnIcon = 'caret-right';
  }

  return (
    <div>
      <h1 className="font-header font-thin text-2xl my-6">Final Review</h1>
      <div className="bg-white p-8">
        {steps.map((step, index) => {
          const stepTitle = step.title.value ? step.title.value : step.title;
          return (
            <div key={`review_step_${index.toString()}`} className="mb-6">
              <h2 className="font-header block font-thin text-xl border-b border-black pb-3 mb-8">
                Step&nbsp;
                {index + 1}
                .
                {stepTitle}
              </h2>
              {step.parts.map(fieldObj => (
                <ReviewField
                  key={fieldObj.name}
                  field={fieldObj}
                  error={formErrors[fieldObj.name]}
                  value={(formData[fieldObj.name] || {}).value}
                />
              ))}
            </div>
          );
        })}
        <div className="flex flex-row justify-between">
          <StepButton
            className="uppercase items-center"
            icon="caret-left"
            iconMargin="3"
            onClick={() => {
              changeStep(-1);
            }}
            label="Return to Form"
          />

          <StepButton
            className="uppercase items-center"
            onClick={() => {
              submitForm();
            }}
            isDisabled={isPending || hasErrors}
            icon={nextBtnIcon}
            iconMargin="3"
            color="green"
            label={isPending ? '' : 'Submit'}
            variant={isPending ? 'outline' : 'solid'}
          />
        </div>
      </div>
    </div>
  );
};

ReviewStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formData: PropTypes.shape({}),
  formErrors: PropTypes.shape({}),
  changeStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

ReviewStep.defaultProps = {
  formData: {},
  formErrors: {},
  isPending: false,
};

export default ReviewStep;
