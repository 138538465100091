import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Elements/Icon';
import colors from '../../../configs/tailwind/colors';

const InputWrapper = ({
  help,
  error,
  label,
  size,
  children,
  required,
  name,
  icon,
  iconClickHandler,
  iconProps,
  invertColor,
  containerClasses,
}) => {
  let textColor = 'text-gray-900';

  if (error) {
    textColor = 'text-red-500';
  } else if (invertColor) {
    textColor = 'text-gray-100';
  }

  // eslint-disable-next-line no-param-reassign
  iconProps = {
    icon: error ? 'invalid' : icon,
    use: {
      fill: error ? colors.red['500'] : colors.gray['900'],
    },
    path: {
      fill: error ? colors.red['500'] : colors.gray['900'],
    },
    ...iconProps,
  };

  let sizeClasses = '';

  switch (size) {
    case 'xs':
      sizeClasses = 'text-xs';
      break;
    case 'sm':
      sizeClasses = 'text-sm';
      break;
    case 'md':
    default:
      sizeClasses = '';
      break;
  }

  return (
    <div className={`field ${containerClasses}`}>
      {label && (
        <label className={`flex flex-row justify-between mb-1 ${textColor}`} htmlFor={`mf_input_${name}`}>
          <div className={`font-semibold ${sizeClasses || ''}`}>{label}</div>
          { (required || error) && <div className="text-xs">{error || 'Required'}</div> }
        </label>
      )}
      <div className="control relative">
        {children}

        { iconProps.icon && (
          // TODO: Replace with appropriate a11y friendly approach & remove eslint disables below.
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
          <span
            role="button"
            className="absolute right top-0 right-0 mt-3 mr-4"
            onClick={iconClickHandler}
          >
            <Icon {...iconProps} />
          </span>
        )}
      </div>
      {help && <p className="help">{help}</p>}

    </div>
  );
};

InputWrapper.propTypes = {
  help: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  name: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
  icon: PropTypes.string,
  iconClickHandler: PropTypes.func,
  iconProps: PropTypes.shape({
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }),
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  invertColor: PropTypes.bool,
};

InputWrapper.defaultProps = {
  help: '',
  error: '',
  label: '',
  size: 'md',
  containerClasses: 'my-3',
  required: false,
  invertColor: false,
  iconProps: {},
  icon: undefined,
  iconClickHandler: undefined,
};

export default InputWrapper;
