import React from 'react';
import PropTypes from 'prop-types';

import Title from '../../Elements/Title';

const SampleSidebar = ({
  children,
  activeStep,
  className,
}) => (
  <aside className={`sidebar ${className}`}>
    <Title>
      Sample Sidebar Step:
      {activeStep + 1}
    </Title>
    <div>
      {children}
    </div>
  </aside>
);

SampleSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  activeStep: PropTypes.number,
  className: PropTypes.string,
};

SampleSidebar.defaultProps = {
  activeStep: 0,
  className: '',
};


export default SampleSidebar;
