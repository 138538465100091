import React from 'react';
import PropTypes from 'prop-types';

import InputBase from './InputBase';
import PasswordInput from './PasswordInput';

import { validateInput } from '../util/validators';

// This is a duck typed smarter component with state that wraps two password
// fields and intercepts their communication withthe parent MultiForm
// {onChange, error, label, placeholder, name, required, value}
class PasswordAndConfirmationInput extends InputBase {
  constructor(props) {
    super(props);

    this.state = {
      valid: true,
      password: props.value,
      passwordConfirmation: props.value,
    };

    this.updateValueAndError = this.updateValueAndError.bind(this);
  }

  validate() {
    return validateInput(this.props.validator, {
      password: this.state.password,
      confirmation: this.state.passwordConfirmation,
    });
  }

  updateValueAndError(name, value) {
    const newState = {};
    newState[name] = value;

    this.setState(newState, () => {
      if (
        this.validate()
        && this.state.password !== undefined
        && this.state.passwordConfirmation !== undefined
      ) {
        this.props.updateValueAndError(this.props.name, this.state.password, null);
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <PasswordInput
          error={this.validate() ? null : this.errorMessage()}
          label="Password"
          required
          placeholder="Enter your new password"
          updateValueAndError={this.updateValueAndError}
          name="password"
          value={this.state.password}
        />
        <PasswordInput
          error={this.validate() ? null : this.errorMessage()}
          label="Confirmation"
          placeholder="Re-enter your new password"
          updateValueAndError={this.updateValueAndError}
          name="passwordConfirmation"
          value={this.state.passwordConfirmation}
        />
      </React.Fragment>
    );
  }
}

PasswordAndConfirmationInput.defaultProps = {
  validator: 'passwordAndConfirmation',
};

PasswordAndConfirmationInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  validator: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  updateValueAndError: PropTypes.func,
  required: PropTypes.bool,
};

export default PasswordAndConfirmationInput;
