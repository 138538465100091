import React from 'react';
import { Router } from '@reach/router';

// import NotFound from './404';
import Layout from 'components/layout';

import PrivateRoute from 'components/PrivateRoute';

import AccountPage from 'components/app/account';
import DashboardPage from 'components/app/dashboard';
import LoginPage from 'components/app/login';
import RegisterPage from 'components/app/register';
import ResetPassword from 'components/app/ResetPassword';
import RequestPasswordReset from 'components/app/RequestPasswordReset';

const App = () => (
  <Layout>
    <Router>
      <RequestPasswordReset path="/app/forgot-password" />
      <ResetPassword path="/app/reset-password/" />
      <ResetPassword path="/app/reset-password/:userRole/:token" />

      <LoginPage path="/app/login" />
      <RegisterPage path="/app/register" />

      <PrivateRoute path="/app/account" component={AccountPage} />
      <PrivateRoute path="/app/" component={DashboardPage} />
    </Router>
  </Layout>
);

export default App;
