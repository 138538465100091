import React, { useState } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

const Content = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' },
});

const Accordion = ({
  children,
  header,
  icon,
  iconActive,
  headerClassName,
  activeHeaderClass,
  contentClassName,
}) => {
  const [hidden, setHidden] = useState(true);

  // rotate icon depending on accordion.
  const currentIcon = hidden ? icon : iconActive;

  return (
    <div className="overflow-hidden">
      <div className="">
        <button
          type="button"
          aria-expanded={(!hidden).toString()}
          onClick={() => setHidden(!hidden)}
          className={`block flex flex-row items-center justify-between ${headerClassName} ${!hidden ? activeHeaderClass : ''}`}
        >
          <span>{header}</span>
          {icon && (
            <i className={`icon-${currentIcon}`} />
          )}
        </button>
      </div>
      <Content className="content" pose={!hidden ? 'open' : 'closed'}>
        <div className={`${contentClassName}`}>
          {children}
        </div>
      </Content>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  icon: PropTypes.string,
  iconActive: PropTypes.string,
  headerClassName: PropTypes.string,
  activeHeaderClass: PropTypes.string,
  contentClassName: PropTypes.string,
};

Accordion.defaultProps = {
  icon: 'angle-right',
  iconActive: 'angle-down',
  headerClassName: '',
  activeHeaderClass: '',
  contentClassName: '',
};

export default Accordion;
