export const loginQuery = (email, password) => (`{
    login(user: {password: "${password}", email: "${email}"}) {
      firstName
      lastName
      authToken
    }
  }`);

export const retrieveStateRolesQuery = state => (`{
  retrieveStateRoles(state: "${state}"){
    label
    identifier
  }
}`);


export const resendVerificationEmailMutation = email => (`mutation {
  resendVerificationEmail(email: "${email}")
}`);

export const updateHempUserMutation = ({
  id,
  firstName,
  lastName,
  email,
}) => {
  const fields = [
    firstName ? ` firstName: "${firstName}" ` : '',
    lastName ? ` lastName: "${lastName}" ` : '',
    email ? ` email: "${email}" ` : '',
  ].join('\n');

  return `mutation {
      updateUser(
        id: "${id}"
        update: {${fields}}
      ) {
        id
        firstName
        lastName
        email
      }
    }`;
};

// TODO retrieve role
export const meQuery = '{me {id firstName lastName email organization { domain }}}';

export const meOrganizationAll = `{
  me {
    id
    organization
    {
      name
      domain
    }
  }
}`;

export const verifyUserMutation = token => `
mutation {
  verifyUser(token: "${token}")
}`;
