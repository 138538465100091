import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextInput from '../../MultiForm/parts/TextInput';

const SearchForm = styled.form``;

const Search = ({
  inputPlaceholder,
  inputName,
  onSubmit,
}) => {
  const [searchVal, setSearch] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(searchVal, event);
  };

  return (
    <SearchForm className="relative" onSubmit={event => handleSubmit(event)}>
      <TextInput
        name={inputName}
        icon="search"
        type="text"
        iconClickHandler={(event) => { handleSubmit(event); }}
        placeholder={inputPlaceholder}
        value={searchVal}
        className="w-64 italic"
        updateValueAndError={(name, value) => {
          setSearch(value);
        }}
      />
    </SearchForm>
  );
};

Search.propTypes = {
  inputPlaceholder: PropTypes.string,
  inputName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

Search.defaultProps = {
  inputName: 's',
  inputPlaceholder: 'Search Public Records...',
};

export default Search;
