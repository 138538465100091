// import ...
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { Consumer } from 'store/createContext';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const isServer = typeof window === 'undefined';

  return (
    <Consumer>
      {({ state }) => {
        console.log('Checking Private Route', state, path.pathname);

        if (
          !state.authToken &&
          (!path?.pathname ||
            (path.pathname.match(/^\/app/) && path.pathname !== '/app/login'))
        ) {
          if (!isServer) {
            navigate('/app/login');
          }
          return null;
        }
        return <Component {...rest} />;
      }}
    </Consumer>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
