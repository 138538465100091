import React from 'react';
import PropTypes from 'prop-types';

import StepButton from '../StepButton';

const StepFooterNavigation = ({ activeStep, steps, changeStep }) => {
  const hasPrevStep = activeStep > 0;
  const hasNextStep = activeStep < steps.length - 1;
  const isFinalStep = activeStep === steps.length - 1;

  return (
    <div className={`flex flex-row mt-12 ${hasPrevStep ? 'justify-between' : 'justify-end'}`}>

      {hasPrevStep && (
        <StepButton
          className="uppercase items-center"
          icon="caret-left"
          iconMargin="3"
          onClick={() => { changeStep(activeStep - 1); }}
          label="Previous"
        />
      )}

      {hasNextStep && (
        <StepButton
          className="uppercase items-center"
          icon="caret-right"
          iconMargin="3"
          color="green"
          onClick={() => { changeStep(activeStep + 1); }}
          label="Next"
        />
      )}

      {isFinalStep && (
        <StepButton
          className="uppercase items-center"
          icon="caret-right"
          iconMargin="3"
          color="green"
          onClick={() => { changeStep(-1); }}
          label="Review & Submit"
        />
      )}
    </div>
  );
};

StepFooterNavigation.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  activeStep: PropTypes.number,
  changeStep: PropTypes.func.isRequired,
};

StepFooterNavigation.defaultProps = {
  activeStep: 0,
};

export default StepFooterNavigation;
