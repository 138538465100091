import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs as TabContainer, TabList, TabPanel,
} from 'react-tabs';

import Copy from '../Copy';

import { Container } from './Tabs.css';

const Tabs = ({
  items, className, containerProps, tabContainerProps,
}) => (
  <Container className={`${className}`} {...containerProps}>
    <TabContainer>
      <TabList>
        {items.map((tab, index) => (
          <Tab key={`tabTitle-${tab.title}`}>
            <div className={`font-medium uppercase font-body py-2 ${index ? 'px-6' : 'pr-6'}`}>
              {tab.title}
            </div>
          </Tab>
        ))}
      </TabList>

      <div className="mt-3" {...tabContainerProps}>
        {items.map(tab => (
          <TabPanel key={`tabPanel-${tab.title}`}>
            <Copy>
              {tab.content}
            </Copy>
          </TabPanel>
        ))}
      </div>
    </TabContainer>
  </Container>
);

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    }),
  ).isRequired,
  className: PropTypes.string,
  containerProps: PropTypes.shape({}),
  tabContainerProps: PropTypes.shape({}),
};

Tabs.defaultProps = {
  className: '',
  containerProps: {},
  tabContainerProps: {},
};

export default Tabs;
