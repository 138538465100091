import React from 'react';
import PropTypes from 'prop-types';

export const LogoSVG = ({ fill, title }) => (
  <svg width="100%" height="100%" viewBox="0 0 1081 230" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>{title}</title>
    <defs>
      <polygon id="trace_logo_svg_polygon" points="991.1491 49.1112 1080.302 49.1112 1080.302 180.7612 991.1491 180.7612" />
    </defs>
    <g id="trace-logo-horizontal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="trace_logo_full_fill_01" fill={fill} points="0 -0.0003 0 45.9657 214.54 45.9657 214.54 61.2927 137.917 61.2927 137.917 214.5357 122.586 214.5357 122.586 61.2927 76.617 61.2927 76.617 229.8567 91.941 229.8567 91.941 76.6197 107.262 76.6197 107.262 229.8567 153.238 229.8567 153.238 76.6197 229.864 76.6197 229.864 30.6447 15.318 30.6447 15.318 15.3207 229.864 15.3207 229.864 -0.0003" />
      <path d="M401.3652,180.7613 C405.4912,174.8843 408.0462,170.4473 408.0462,157.7093 L408.0462,64.4453 L385.4912,64.4453 C377.0632,64.4453 369.0172,67.0993 362.9152,72.5963 L362.9152,49.1113 L473.2422,49.1113 L473.2422,72.5903 C467.1402,67.0993 459.1042,64.4453 450.6692,64.4453 L428.3112,64.4453 L428.3112,157.7093 C428.3112,170.2023 431.5022,173.8053 436.2262,180.7613 L401.3652,180.7613 Z" id="trace_logo_full_fill_02" fill={fill} />
      <path d="M843.3008,114.9381 C843.3008,81.8541 858.4838,60.2581 888.4988,60.2581 C915.0538,60.2581 928.7678,90.4341 928.7678,90.4341 L933.7158,57.0161 C916.2358,49.9531 900.1468,47.8711 888.5538,47.8711 C848.3328,47.8711 821.0078,72.7091 821.0078,114.9381 C821.0078,157.1671 848.3328,181.9991 888.5538,181.9991 C900.1468,181.9991 916.5478,179.9171 933.7158,172.7191 L929.0788,139.9301 C929.0788,139.9301 915.7598,169.2041 889.2118,169.2041 C859.1968,169.2041 843.3008,148.0151 843.3008,114.9381" id="trace_logo_full_fill_04" fill={fill} />
      <path d="M593.7109,88.6988 C593.7109,105.8698 584.3449,112.7788 566.2569,112.7788 L545.5929,112.7788 L545.5929,62.1768 L566.1769,61.9648 C584.7009,61.9648 593.7109,71.0518 593.7109,87.1818 L593.7109,88.6988 Z M628.8219,180.8158 C627.3179,179.8068 624.5679,178.0918 623.0319,176.6648 C606.3679,161.1548 583.0849,124.0448 583.0849,124.0448 C590.0099,123.7458 596.1079,121.8588 600.7099,118.5818 C609.9639,112.0078 614.6579,101.6158 614.6579,87.6958 C614.6579,63.5318 597.1019,49.1108 567.6959,49.1108 L518.8419,49.1108 C522.9999,55.3898 525.3229,59.3938 525.3229,74.7178 L525.3229,154.3518 C525.3229,167.7188 524.7129,175.9648 521.5379,180.7608 L550.6249,180.7608 C547.7129,176.0418 545.5929,167.8538 545.5929,155.1548 L545.5929,124.1598 L558.9529,124.1598 C558.9529,124.1598 581.9319,160.5208 589.7939,170.8428 C602.2809,187.2648 628.8219,180.8158 628.8219,180.8158 L628.8219,180.8158 Z" id="trace_logo_full_fill_06" fill={fill} />
      <mask id="trace_logo_full_mask_01" fill={fill}>
        <use xlinkHref="#trace_logo_svg_polygon" />
      </mask>
      <g id="Clip-9" />
      <path d="M1052.3721,65.5372 C1064.3481,65.5372 1073.8471,66.0452 1080.3021,71.5072 L1080.3021,49.1112 L991.2431,49.1112 C994.4041,53.9052 997.7921,61.9382 997.7921,75.5172 L997.7921,154.3522 C997.7921,170.3892 995.3621,174.3132 991.1491,180.7612 L1080.3021,180.7612 L1080.3021,158.3622 C1069.8101,164.6662 1061.3051,164.0562 1047.7831,164.0562 L1018.0601,164.0562 L1018.0601,119.9702 L1048.0501,119.9702 C1054.1781,119.9702 1060.3061,120.7602 1066.3851,121.5322 L1066.3851,121.1292 L1066.3851,105.8282 L1066.3851,105.5942 C1060.3061,106.3652 1054.1781,106.9822 1048.0501,106.9822 L1018.0601,106.9822 L1018.0601,65.5372 L1052.3721,65.5372 Z" id="trace_logo_full_fill_08" fill={fill} />
      <path d="M703.9102,128.1959 L724.6712,65.1969 L745.5562,128.1959 L703.9102,128.1959 Z M774.3222,152.0159 C768.4412,136.5759 744.9852,69.4129 744.9852,69.4129 C741.4542,59.4969 743.1702,52.2349 745.3252,47.7939 L704.0192,47.7939 C706.1752,52.2349 707.8882,59.4969 704.3562,69.4129 C704.3562,69.4129 680.9032,136.5759 675.0192,152.0159 C669.1362,167.4589 666.7712,174.2419 661.5782,180.7609 L691.6772,180.7609 C691.6772,180.7609 686.4592,177.0379 689.2062,169.3509 L698.8012,142.4949 L750.5632,142.4949 L760.1452,169.3509 C762.8832,177.0379 758.2332,180.7609 758.2332,180.7609 L787.7662,180.7609 C782.5702,174.2419 780.2052,167.4589 774.3222,152.0159 L774.3222,152.0159 Z" id="trace_logo_full_fill_011" fill={fill} />
    </g>
  </svg>
);

LogoSVG.propTypes = {
  fill: PropTypes.string,
  title: PropTypes.string,
};

LogoSVG.defaultProps = {
  fill: '#000',
  title: 'Trace Logo',
};

export const IconSVG = ({
  fill,
  height,
  width,
  title,
}) => (
  <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <defs>
      <polygon id="logo_path" points="0 0 0 2.2974359 0 4.59487179 0 6.89219282 32.1641026 6.89219282 32.1641026 9.18974359 22.974359 9.18974359 20.6769231 9.18974359 20.6769231 32.1641026 18.3794872 32.1641026 18.3794872 9.18974359 16.0820513 9.18974359 13.7847303 9.18974359 11.4871795 9.18974359 11.4871795 34.4615385 13.7847303 34.4615385 13.7847303 11.4871795 16.0820513 11.4871795 16.0820513 34.4615385 18.3794872 34.4615385 20.6769231 34.4615385 22.974359 34.4615385 22.974359 11.4871795 34.4615385 11.4871795 34.4615385 4.59487179 2.2974359 4.59487179 2.2974359 2.2974359 34.4615385 2.2974359 34.4615385 0" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Drawings/Logo">
        <mask id="logo_mask" fill={fill}>
          <use xlinkHref="#logo_path" />
        </mask>
        <use id="logo" fill={fill} xlinkHref="#logo_path" />
      </g>
    </g>
  </svg>
);

IconSVG.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

IconSVG.defaultProps = {
  fill: '#000',
  title: 'Trace Logo',
  height: '35',
  width: '35',
};
