import React from 'react';
import PropTypes from 'prop-types';

import Title from '../../Elements/Title';

const GenericHeader = ({ value, ...props }) => (
  <Title {...props}>
    {value}
  </Title>
);

GenericHeader.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default GenericHeader;
