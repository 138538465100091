import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Copyable from '../Copyable';

const Email = ({ email }) => (
  <React.Fragment>
    <Copyable text={email} />
    <a className="hover:text-underline" href={`mailto:${email}`}>
      <Icon type="font" icon="email" />
    </a>
  </React.Fragment>
);
Email.propTypes = { email: PropTypes.string.isRequired };
export default Email;
