import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Head from 'components/head';

import MultiForm from 'core/src/components/MultiForm';
import NewUserRegistration from 'core/src/configs/multiform/NewUserRegistration';

import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import Copy from '~v2/elements/copy';

const RegisterPage = () => {
  const [formState, setStatus] = useState({
    status: 'init',
    result: undefined,
  });

  return (
    <>
      <Head
        pageTitle="User Login Page"
        pageDescription="The Trace team never sleeps. Check out our blog to stay apprised of how we’re elevating the hemp industry."
      />
      <div className="bg-gray-100">
        <Wrapper>
          <div id="RegisterPage" className="w-full lg:py-16">
            <div className="w-full lg:w-2/3 flex flex-col self-center text-black">
              <div className="py-2">
                <TitleContainer>
                  <Title as="h1" variant="subheading">
                    Sign up for Trace
                  </Title>
                </TitleContainer>
                <div className="px-8 xl:px-0">
                  <Copy size="lg" marginBottom="0">
                    If you have already signed up,{' '}
                    <Link className="underline font-semibold" to="/app/login">
                      login here
                    </Link>
                    .
                  </Copy>
                </div>
              </div>
              <div className="px-8 xl:px-0">
                <MultiForm
                  {...NewUserRegistration}
                  saveOnStepChange={false}
                  onSubmit={() => {
                    setStatus({
                      ...formState,
                      status: 'pending',
                    });
                  }}
                  isSubmitPending={formState.status === 'pending'}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

// RegisterPage.propTypes = {
//   onLoginSubmit: PropTypes.func.isRequired,
//   loginError: PropTypes.string
// }

export default RegisterPage;
