import React from 'react';
import InputBase from './InputBase';
import InputWrapper from './InputWrapper';
import SimpleCheckbox from './SimpleCheckbox';

class MultipleCheckboxesInput extends InputBase {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
    this.toggleValue = this.toggleValue.bind(this);
  }

  toggleValue(name, value) {
    const {
      values: currentValues,
    } = this.state;

    currentValues[value] = !currentValues[value];

    this.setState({ values: currentValues }, () => {
      this.props.onChange(this.props.name, this.state.values);
    });
  }


  render() {
    const {
      error, label, name, options,
    } = this.props;
    return (
      <InputWrapper error={error} label={label}>
        {
         options.map(opt => (
           <SimpleCheckbox
             key={opt}
             value={opt}
             checked={this.state.values[name]}
             name={name}
             onChange={this.toggleValue}
           />
         ))
       }
      </InputWrapper>
    );
  }
}

export default MultipleCheckboxesInput;
