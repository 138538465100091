import React from 'react';
import InputBase from './InputBase';

class SimpleCheckbox extends InputBase {
  componentWillUnmount() {
    // override
    return (true);
  }

  render() {
    const {
      onChange, name, value, checked,
    } = this.props;
    return (
      <div className="mb-3">
        <label className="checkbox" htmlFor={`simplecheckbox_${name}`}>
          <input
            id={`simplecheckbox_${name}`}
            className="inline-block w-3 mr-3"
            onChange={() => { onChange(name, value); }}
            defaultValue={value}
            type="checkbox"
            checked={checked}
            name={name}
          />
          {value}
        </label>
      </div>
    );
  }
}

export default SimpleCheckbox;
