import InputBase from './InputBase';

class NumericInput extends InputBase {}

NumericInput.defaultProps = {
  type: 'numeric_input',
  validator: 'number',
};

// (⊙▂⊙) - forgive me padre, for i have sinned
export class MaxPointFourNineNumericInput extends InputBase {}

MaxPointFourNineNumericInput.defaultProps = {
  type: 'max_point_four_nine_numeric_input',
  validator: 'max_point_four_nine_numeric_input',
};

export default NumericInput;
