import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { colors } from 'constants/theme';

export const Header1 = styled.h1`
  font-family: ${props =>
    props.variant === 'heading' ? 'Archivo Black' : 'Domaine Display'};
  font-weight: 700;
  text-transform: ${props =>
    props.variant !== 'heading' ? 'inherit' : 'uppercase'};
  color: ${props => props.fontColor || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
  font-size: ${props => props.fontSize || '2.4rem'};
  letter-spacing: -0.8px;
  line-height: 2rem;
  margin-bottom: 2rem;
  text-indent: ${props => props.textIndent || 'inherit'};

  ${MEDIA.MIN_TABLET`
    font-size: ${props => props.fontSize || '2.8rem'};
    letter-spacing: -0.8px;
    line-height: 2.4rem;
  `};

  ${MEDIA.MIN_DESKTOP`
    font-size: ${props => props.fontSize || '3rem'};
    letter-spacing: -0.8px;
    line-height: 2.8rem;
  `};

  ${MEDIA.MIN_LG_DESKTOP`
    font-size: ${props => props.fontSize || '4rem'};
    letter-spacing: -0.8px;
    line-height: 3.8rem;
  `};
`;

export const Header2 = styled.h2`
  font-family: ${props =>
    props.variant === 'heading' ? 'Archivo Black' : 'Domaine Display'};
  text-transform: ${props =>
    props.variant !== 'heading' ? 'inherit' : 'uppercase'};
  font-size: ${props => props.fontSize || '46px'};
  letter-spacing: -0.13px;
  color: ${props => props.fontColor || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin-bottom: 1.6rem;
  text-indent: ${props => props.textIndent || 'inherit'};

  a {
    color: ${props => props.fontColor || 'inherit'};
    text-decoration: none;
    transition: color 150ms ease-in;

    &:hover {
      color: ${colors.collective_red};
    }
  }

  ${MEDIA.PHONE`
    text-indent: inherit;
  `};

  ${MEDIA.MIN_TABLET`
    font-size: 2.4rem;
    letter-spacing: -0.8px;
    line-height: 2.2rem;
  `};

  ${MEDIA.MIN_DESKTOP`
    font-size: 2.8rem;
    letter-spacing: -0.8px;
    line-height: 2.8rem;
  `};

  ${MEDIA.MIN_LG_DESKTOP`
    font-size: 46px;
    letter-spacing: -0.8px;
    line-height: 4rem;
  `};
`;

export const Header3 = styled.h3`
  font-family: ${props =>
    props.variant === 'heading' ? 'Archivo Black' : 'Domaine Display'};
  font-size: ${props => props.fontSize || '34px'};
  text-transform: ${props =>
    props.variant !== 'heading' ? 'inherit' : 'uppercase'};
  color: ${props => props.fontColor || 'inherit'};
  letter-spacing: -0.5px;
  line-height: 40px;
  margin-bottom: 1.4rem;
  text-indent: ${props => props.textIndent || 'inherit'};

  ${MEDIA.PHONE`
    text-indent: inherit;
  `};

  ${MEDIA.TABLET`
    max-width: 100%;
    font-size: 30px;
    line-height: auto;
  `};
`;

export const Paragraph = styled.p`
  font-family: ${props => {
    switch (props.variant) {
      case 'heading':
        return 'Archivo Black';
      case 'subheading':
        return 'Domaine Display';
      default:
        return 'Montserrat';
    }
  }};

  font-weight: 400;
  color: ${props => props.fontColor || 'inherit'};
  letter-spacing: -0.1px;

  margin-bottom: ${props => props.marginBottom || '2rem'};
  margin-left: ${props => props.marginLeft || ''};

  ${props => {
    switch (props.size) {
      case 'sm':
        return `
          font-size: 0.75rem;
          line-height: 1rem;
        `;
      case 'lg':
        return `
          font-size: 1.3rem;
          line-height: 1.5rem;
        `;
      default:
        return `
          font-size: 1rem;
          line-height: 1.5rem;
        `;
    }
  }};

  ${MEDIA.MIN_DESKTOP`
    ${props => {
      switch (props.size) {
        case 'sm':
          return `
            font-size: 0.85rem;
            line-height: 1.4rem;
          `;
        case 'lg':
          return `
            font-size: 1.5rem;
            line-height: 2.2rem;
          `;
        default:
          return `
            font-size: 1rem;
            line-height: 1.6rem;
          `;
      }
    }};
  `};
`;

export const Anchor = styled.a`
  color: ${props => props.fontColor || colors.cool_grey};
  transition: color 0.2s ease;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`;
