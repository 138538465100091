/**
 * Convert Date String to Formatted Date.
 *
 * @param {string} dateString
 */
export function localizeDateFromString(dateString) {
  const d = new Date(dateString);
  const lang = 'en-US';
  const opts = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return (d.toLocaleDateString(lang, opts));
}

/**
 * Strinfiy Date
 *
 */
export function stringifyDate(date, format) {
  const d = new Date(date);
  switch (format) {
    case 'mm/dd/yyyy, hh:MM AM':
      return d.toLocaleDateString('en-US', {
        hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric',
      });
    case 'm dd, hh:MM AM':
      return d.toLocaleDateString('en-US', {
        hour: 'numeric', minute: 'numeric', month: 'long', day: 'numeric',
      });
    case 'mmmmm dd, yyyy':
      return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    case 'yyyy':
      return d.toLocaleDateString('en-US', { year: 'numeric' });
    case 'mm/dd/yyyy':
    default:
      return d.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
}
