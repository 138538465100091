import React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';
import InputBase from './InputBase';

import colors from '../../../configs/tailwind/colors';

class PasswordInput extends InputBase {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: true,
    };
  }

  render() {
    const {
      error,
      required,
      value,
      name,
      placeholder,
      size = 'md',
      className,
    } = this.props;

    const { isHidden } = this.state;

    let sizeClasses = '';

    switch (size) {
      case 'xs':
        sizeClasses = 'p-2 text-xs';
        break;
      case 'sm':
        sizeClasses = 'p-2 text-sm';
        break;
      case 'md':
      default:
        sizeClasses = 'p-3';
        break;
    }

    return (
      <InputWrapper
        iconProps={{
          icon: 'view',
          path: { fill: colors.gold['500'] },
          hoverProps: { path: { fill: colors.gold['900'] } },
        }}
        iconClickHandler={() => {
          this.setState({
            isHidden: !isHidden,
          });
        }}
        {...this.props}
      >
        <input
          id={`mf_input_${name}`}
          onChange={this.handleChange}
          required={required}
          className={`rounded border border-gray-400 focus:border-gray-500 font-light text-traceblack placeholder-gold-900 ${sizeClasses || ''} ${error ? 'border-red-400' : ''} ${className || ''}`}
          type={this.state.isHidden ? 'password' : 'text'}
          defaultValue={value}
          name={name}
          placeholder={placeholder}
        />
      </InputWrapper>
    );
  }
}

PasswordInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  updateValueAndError: PropTypes.func,
  required: PropTypes.bool,
};

export default PasswordInput;
