import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import NotificationService, {
  NOTIFICATION_SHOW_NOTICE,
} from '../../../services/NotificationService';

const ns = new NotificationService();

const Copyable = ({ text }) => {
  function doCopy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    ns.postNotification(
      NOTIFICATION_SHOW_NOTICE,
      `Copied "${text}" to clipboard!`,
    );
  }

  return (
    <a href="/" className="cursor-pointer hover:text-underline" onClick={doCopy}>
      {text}
      {' '}
      <Icon type="font" icon="paperclip" />
    </a>
  );
};

Copyable.propTypes = { text: PropTypes.string.isRequired };
export default Copyable;
