import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Elements/Button';

const StepButton = ({
  className,
  onClick,
  isDisabled,
  label,
  icon = '',
  iconMargin,
  color,
  variant,
}) => (
  <Button
    type="button"
    size="lg"
    color={color}
    variant={variant}
    onClickHandler={onClick}
    isDisabled={isDisabled}
    icon={icon}
    iconFirst={icon === 'caret-left'}
    iconMargin={iconMargin}
    iconSize="lg"
    className={className}
  >
    {label}
  </Button>
);

StepButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  icon: PropTypes.oneOf(['caret-left', 'caret-right', 'loader', 'delete']),
  iconMargin: PropTypes.oneOf(['auto', '1', '2', '3', '4', '5', '6', '8', '10', '12', '24', '48']),
};

StepButton.defaultProps = {
  className: '',
  iconMargin: 'auto',
  color: 'gold',
  variant: 'solid',
  isDisabled: false,
  icon: 'caret-right',
};

export default StepButton;
