export const NOTIFICATION_AUTH_CHANGED = 'auth_changed';
export const NOTIFICATION_LOTS_CHANGED = 'lots_changed';
export const NOTIFICATION_LOGIN_ERROR = 'login_error';
export const NOTIFICATION_REGISTRATION_ERROR = 'registration_error';
export const NOTIFICATION_REGISTRATION_SUCCESS = 'registration_success';
export const NOTIFICATION_LOADING = 'loading';
export const NOTIFICATION_ORGANIZATIONS_CHANGED = 'organizations_changed';
export const NOTIFICATION_DASHBOARD_CHANGED = 'dashboard_changed';
export const NOTIFICATION_PERMIT_STARTED = 'permit_started';
export const NOTIFICATION_NEW_PERMIT_DATA = 'new_permit_data';
export const NOTIFICATION_LOADED_PERMITS = 'loaded_permits';
export const NOTIFICATION_NEW_STATE_ROLES = 'new_state_roles';
export const NOTIFICATION_LOADED_REGULATORS = 'loaded_regulators';
export const NOTIFICATION_NEW_USER_DATA = 'new_user_data';
export const NOTIFICATION_PASSWORD_CHANGE_ERROR = 'password_change_error';
export const NOTIFICATION_PASSWORD_CHANGE_SUCCESS = 'password_change_success';
export const NOTIFICATION_UPDATE_REGULATOR_ERROR = 'update_regulator_error';
export const NOTIFICATION_UPDATE_REGULATOR_SUCCESS = 'update_regulator_success';
export const NOTIFICATION_UPDATE_HEMP_USER_ERROR = 'update_hemp_user_error';
export const NOTIFICATION_UPDATE_HEMP_USER_SUCCESS = 'update_hemp_user_success';
export const NOTIFICATION_NEW_REGULATOR_DATA = 'new_regulator_data';
export const NOTIFICATION_INVITE_REGULATOR_ERROR = 'invite_regulator_success';
export const NOTIFICATION_INVITE_REGULATOR_SUCCESS = 'invite_regulator_data';
export const NOTIFICATION_PASSWORD_RESET_ERROR = 'password_reset_error';
export const NOTIFICATION_PASSWORD_RESET_SUCCESS = 'password_reset_success';
export const NOTIFICATION_REGISTER_PAYMENT_SUCCESS = 'register_payment_success';
export const NOTIFICATION_REGISTER_PAYMENT_ERROR = 'register_payment_error';
export const NOTIFICATION_REQUEST_RESET_SUCCESS = 'request_reset_success';
export const NOTIFICATION_REQUEST_RESET_ERROR = 'request_reset_error';
export const NOTIFICATION_VERIFICATION_ERROR = 'verification_error';
export const NOTIFICATION_VERIFICATION_SUCCESS = 'verification_success';
export const NOTIFICATION_SHOW_NOTICE = 'show_notice';

const observers = {};
let instance = null;

class NotificationService {
  constructor() {
    if (!instance) {
      instance = this;
    }
  }

  // TODO: Refactor into static function.
  // eslint-disable-next-line class-methods-use-this
  postNotification(notificationName, data) {
    const obs = observers[notificationName];
    if (obs) {
      for (let x = 0; x < obs.length; x += 1) {
        const obj = obs[x];
        if (typeof obj.callback === 'function') {
          obj.callback(data);
        } else {
          console.log(
            'invalid notification observer for',
            notificationName,
            'for',
            obj.obs_name,
            'with callback:',
            obj.callback,
          );
        }
      }
    }
  }

  // TODO: Refactor into static function.
  // eslint-disable-next-line class-methods-use-this
  removeObserver(notificationName, observer) {
    const obs = observers[notificationName];
    if (obs) {
      for (let x = 0; x < obs.length; x += 1) {
        if (observer === obs[x].observer) {
          obs.splice(x, 1);
          observers[notificationName] = obs;
          break;
        }
      }
    }
  }

  // TODO: Refactor into static function.
  // eslint-disable-next-line class-methods-use-this
  addObserver(notificationName, observer, callback, obs_name = 'UNKNOWN') {
    const obs = observers[notificationName];

    if (!obs) {
      observers[notificationName] = [];
    }

    observers[notificationName].push({
      observer,
      callback,
      obs_name,
    });
  }
}

export default NotificationService;
