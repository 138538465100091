import React from 'react';
import USStates from '../constants/USStates';
import SelectDropdownInput from './SelectDropdownInput';
import InputBase from './InputBase';

class StateSelectInput extends InputBase {
  render() {
    return (<SelectDropdownInput options={USStates} {...this.props} />);
  }
}
export default StateSelectInput;
