/**
 * Returns the type of the passed variable.
 *
 * @export
 * @param {*} obj
 * @returns variable type of obj.
 */
export function typeOf(obj) {
  return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase();
}
