import colors from '../../../configs/tailwind/colors';


/**
 * Remove Shape from Map
 *
 * @param {boolean} [shape=false]
 * @param {string} type
 * @param {boolean} [mapApi=false]
 * @returns
 */
function removeShape(shape = false, type, mapApi = false) {
  if (!shape || !mapApi) {
    return;
  }

  // remove event listeners
  mapApi.event.clearListeners(shape, 'click_handler_name');
  mapApi.event.clearListeners(shape, 'drag_handler_name');

  switch (type) {
    case 'circle':
      shape.setRadius(0);
      break;
    case 'polygon':
      shape.setPath([]);
      break;
    case 'rectangle':
    case 'marker':
    default:
      shape.setMap(null);
      break;
  }
}

/**
 * Create Drawing Manager and Attach it to Map.
 *
 * @see https://developers.google.com/maps/documentation/javascript/reference?csw=1#drawing-on-the-map
 *
 * @param {*} map
 * @param {*} mapApi
 * @returns
 */
function initDrawingManager(map, mapApi) {
  const dm = new mapApi.drawing.DrawingManager({
    drawingMode: '',
    drawingControl: true,
    drawingControlOptions: {
      position: mapApi.ControlPosition.TOP_CENTER,
      drawingModes: [],
    },
    polygonOptions: {
      editable: true,
      strokeOpacity: 0.5,
      strokeWeight: 1.25,
      strokeColor: colors.gold['500'],
      fillColor: colors.gold['500'],
      fillOpacity: 0.20,
    },
  });

  dm.setMap(map);

  return dm;
}

/**
 * Update Drawing Manager Settings
 *
 * @param {*} drawingManager
 * @param {*} mapApi
 * @param {string} [drawingModes=['polygon', 'marker']]
 * @param {*} [options={}]
 */
function updateDrawingOptions(drawingManager, mapApi, drawingModes = ['polygon', 'marker'], options = {}) {
  console.log('Updating Drawing Manager Options:', drawingModes, options);

  drawingManager.setOptions({
    drawingControlOptions: {
      position: mapApi.ControlPosition.TOP_CENTER,
      drawingModes,
    },
    ...options,
  });
}


const drawingUtils = {
  removeShape,
  initDrawingManager,
  updateDrawingOptions,
};

export default drawingUtils;
