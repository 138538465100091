/* eslint-disable max-len */
import {
  loadPermitByIdQuery,
  updatePermitStatusMutation,
} from './permit-queries';

const regulatorAgencyWrapper = (state, content) => `{regulatorAgency(state: "${state}") ${content} }`;

export const regulatorLoginQuery = (state, email, password) => regulatorAgencyWrapper(
  state,
  `{
      login(regulator:{email: "${email}", password: "${password}"}){
        firstName
        lastName
        authToken
      }
    }`,
);

export const listRegulatorsQuery = state => regulatorAgencyWrapper(
  state,
  `{
    regulators {
      id
      department {name}
      firstName
      lastName
      email
      role
    }
   }`,
);

// Sets new password, uses token to validate authentication. Token can be a
// cached verification token or the previous password
//
export const updateUserPasswordMutation = ({
  email,
  token,
  newPassword,
}) => `mutation {
  setNewPassword(
    ${email ? (`email: ${email}\n`) : ''}
    token:"${token}"
    password: "${newPassword}"
  )
}`;

export const deleteUserMutation = id => `mutation {deleteUser(id:  "${id}")}`;

export const passwordForgottenMutation = email => `mutation {
  passwordForgotten(email: "${email}")
}`;

export const updateRegulatorUserMutation = ({
  id,
  firstName,
  lastName,
  email,
}) => {
  const fields = [
    firstName ? ` firstName: "${firstName}" ` : '',
    lastName ? ` lastName: "${lastName}" ` : '',
    email ? ` email: "${email}" ` : '',
  ].join('\n');

  return `mutation {
      updateUser(
        id: "${id}"
        update: {${fields}}
      ) { id }
    }`;
};

// TODO make use of this, currently fails with an
// Internal Server Error and a message like "User is not an Admin"
export const registerRegulatorMutation = (state, { firstName, lastName, email }) => `mutation {
      registerRegulator(
        state: "VT"
        regulator: {
          firstName: "${firstName}"
          lastName: "${lastName}"
          email: "${email}"
        }
      ) {
        id
        firstName
        lastName
        email
      }
  }`;


// this doesnt work with the regular permits query because they are just differently wrapped.
export const regulatorPermitsQuery = state => regulatorAgencyWrapper(state, `{
  permits (status: null) {
      id
      read
      organization { name }
      period {
        start
        end
      }
      payment {
        isPaid
        totalPaid
        balance
      }
      status
      pdfLink
      created
    }
  }`);

export const regulatorUpdatePermitStatusMutation = (
  state,
  id,
  status,
  reason,
) => regulatorAgencyWrapper(state, updatePermitStatusMutation(id, status, reason));

export const regulatorLoadPermitById = (state, id) => regulatorAgencyWrapper(state, loadPermitByIdQuery(id));

export const newPermitForOrg = (year, id) => `mutation {
   startPermit(year: ${year}, organizationId: "${id}") {
     id
   }
 }`;

// we are unable to use the organizations query from the other context because the params are different
export const regulatorOrganizationsSummary = state => regulatorAgencyWrapper(state, `{
  organizations {
    id
    name
    infoHash
    address
    domain
    location { state }
    owner { firstName lastName email }
    roles {identifier label}
    verified
  }
}`);

export const statsQuery = `{
  statistics {
    lots {
      state
      count
    }
  }
}`;

export const regulatorStatsQuery = state => regulatorAgencyWrapper(state, statsQuery);
