import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import { typeOf } from '../../../utils/helpers';

const ButtonIcon = ({
  icon = null,
  className = '',
  iconFirst = false,
  iconMargin = '',
  iconSize = 'base',
  iconColor,
}) => {
  if (typeOf(icon) === 'string') {
    const iconAlignment = iconFirst ? `order-first mr-${iconMargin}` : `ml-${iconMargin}`;

    switch (icon) {
      case 'loader':
        return (
          <Loader type="dots" color={iconColor} className="text-xs" />
        );
      default:
        return (
          <i className={`icon-${icon} text-${iconSize} ${iconAlignment} ${className}`} />
        );
    }
  }

  return icon;
};

ButtonIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconFirst: PropTypes.bool,
  iconMargin: PropTypes.string,
  iconSize: PropTypes.oneOf(['xs', 'sm', 'base', 'md', 'lg', 'xl']),
  iconColor: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonIcon;
