import { useState, useEffect } from 'react';

import NotificationService from '../NotificationService';

const ns = new NotificationService();

const INITIAL_STATE = {
  notifications: [],
  hidden: true,
  message: '',
};

/**
 * Form Request Reducer
 *
 * @param {object} state
 * @param {object} action
 * @returns {object} Updated state object.
 */
function snackBarReducer(state, action) {
  switch (action.type) {
    case 'show':
      return {
        ...state,
        message: action.message,
        hidden: false,
      };
    case 'hide':
      return {
        ...state,
        message: '',
        hidden: true,
      };
    case 'add_listeners':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.data,
        ],
      };
    case 'reset':
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * Form Request Reusable Store
 */
export function useSnackbar() {
  const [currentState, setCurrentState] = useState(INITIAL_STATE);

  useEffect(() => {
    // Attach observer for each notification event currently in store.
    for (let i = 0; i < currentState.notifications.length; i += 1) {
      const { event, callback } = currentState.notifications[i];
      ns.addObserver(event, this, callback, 'checkVerification');
    }

    // Cleanup observers on Unmount.
    return () => {
      for (let i = 0; i < currentState.notifications.length; i += 1) {
        const { event } = currentState.notifications[i];
        ns.removeObserver(event, this);
      }
    };
  }, [currentState.status, currentState.notifications]);

  /**
   * Dispatch method for reducer.
   *
   * @param {object} action
   */
  function dispatch(action) {
    const nextState = snackBarReducer(currentState, action);
    setCurrentState(nextState);
  }

  return [
    currentState,
    dispatch,
  ];
}
